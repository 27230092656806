import * as React from "react"
import { BaseDialog } from "@/components/BaseDialog"
import { Button, DialogContentText, FormControlLabel, Switch } from "@material-ui/core"
import { DropzoneArea } from "material-ui-dropzone"

interface Props {
  title?: string
  open?: boolean
}

interface Actions {
  onUpload: (file: File, type: ItemFileType) => void
  onCancel: () => void
}

export const FileDialog: React.FC<Props & Actions> = ({ title = "Upload file", open, onUpload, onCancel }) => {
  const [file, setFile] = React.useState(null)
  const [type, setType] = React.useState<ItemFileType>("invoice")
  return (
    <BaseDialog
      title={title}
      actions={[
        <Button
          key="confirm_button"
          color="default"
          disabled={!file}
          onClick={() => {
            onUpload(file, type)
            setFile(null)
            onCancel()
          }}>
          Confrim
        </Button>,
        <Button key="cancel_button" color="primary" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
      open={open}>
      <DialogContentText>Choose file type - invoices are visible only to administrators</DialogContentText>
      <FormControlLabel
        control={
          <Switch checked={type === "invoice"} onChange={(_, checked) => setType(checked ? "invoice" : "other")} />
        }
        label={type}
      />
      <DropzoneArea
        showAlerts={false}
        showPreviewsInDropzone={false}
        useChipsForPreview
        showPreviews
        filesLimit={1}
        dropzoneText="Drag and drop a file here or click"
        onChange={([newFile]) => setFile(newFile)}
        onDelete={() => setFile(null)}
      />
    </BaseDialog>
  )
}
