import * as React from "react"
import { connect } from "react-redux"
import Linkify from "react-linkify"
import { Button, List, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Collapse } from "@material-ui/core"
import DetailsIcon from "@material-ui/icons/OpenInNew"
import RejectIcon from "@material-ui/icons/ThumbDown"
import InProgressIcon from "@material-ui/icons/Alarm"
import EditIcon from "@material-ui/icons/Edit"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import CommentIcon from "@material-ui/icons/Comment"
import DoneIcon from "@material-ui/icons/Done"
import moment = require("moment")

import * as firebaseOperations from "@/services/firebase/operations"
import { getItemRequestForPreview } from "@/store/selectors/common"
import { appActions, UIState } from "@/store/actions"
import { MapState, MapDispatch } from "@/store"
import * as router from "@/router"
import { mapItemRequestsToViews } from "@/models/itemRequest"
import { BaseDialog } from "@/components/BaseDialog"
import {
  StyledListItem,
  PositiveListItem,
  SubListItem,
  NegativeListItem,
  SecondaryListItemText,
  NeutralListItem,
} from "@/components/ListItems"
import { linkDecorator } from "@/utils/linkDecorator"

type Props = {
  asAdmin?: boolean
}

type StoreProps = {
  itemRequestViews: ItemRequestView[]
  user: User
  itemRequestsCount: number
}

interface Actions {
  close: () => void
  edit: (id: string) => void
  details: (id: string) => void
  navigateToList: () => void
  setInProgress: (req: ItemRequest) => void
  resolve: (req: ItemRequest) => void
  reject: (req: ItemRequest) => void
}

const ItemRequestPreviewComponent: React.FC<Props & StoreProps & Actions> = ({
  itemRequestViews,
  user,
  asAdmin,
  close,
  setInProgress,
  navigateToList,
  edit,
  resolve,
  reject,
  details,
}) => {
  const [collapsedViewId, setCollapsedViewId] = React.useState<string>(null)
  return (
    <BaseDialog
      open
      title={asAdmin ? "New Item Requests" : "My Item Requests"}
      actions={[
        <Button
          key="item_requests_preview_see_all"
          data-cy="item-request-preview-all-button"
          color="primary"
          onClick={() => {
            close()
            navigateToList()
          }}>
          See all
        </Button>,
        <Button key="item_requests_preview_close" onClick={close}>
          Close
        </Button>,
      ]}>
      <List data-cy="new-device-requests-list">
        {itemRequestViews.map(view => {
          const buttons = []
          const isOpen = collapsedViewId === view.id
          if (!user.isAdmin || !asAdmin) {
            view.status === "NEW"
              ? buttons.push(
                  <SubListItem
                    button
                    key={`${view.id}_edit`}
                    onClick={_ => edit(view.id)}
                    data-cy="new-device-requests-list-item">
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText primary="Edit" />
                  </SubListItem>
                )
              : buttons.push(
                  <SubListItem button key={`${view.id}_details`} onClick={_ => details(view.id)}>
                    <ListItemIcon>
                      <DetailsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Details" />
                  </SubListItem>
                )
          } else {
            buttons.push(
              <SubListItem button key={`${view.id}_edit`} onClick={_ => edit(view.id)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Edit" />
              </SubListItem>
            )

            view.status === "NEW" &&
              buttons.push(
                <NeutralListItem button key={`${view.id}_in_progress`} onClick={_ => setInProgress(view)}>
                  <ListItemIcon>
                    <InProgressIcon />
                  </ListItemIcon>
                  <ListItemText primary="Set in progress" />
                </NeutralListItem>
              )
            buttons.push(
              <PositiveListItem button key={`${view.id}_resolve`} onClick={_ => resolve(view)}>
                <ListItemIcon>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText primary="Resolve" />
              </PositiveListItem>
            )
            buttons.push(
              <NegativeListItem button key={`${view.id}_reject`} onClick={_ => reject(view)}>
                <ListItemIcon>
                  <RejectIcon />
                </ListItemIcon>
                <ListItemText primary="Reject" />
              </NegativeListItem>
            )
          }

          return (
            <React.Fragment key={view.id}>
              <StyledListItem
                button
                onClick={() => {
                  setCollapsedViewId(isOpen ? null : view.id)
                }}>
                <ListItemAvatar>
                  <Avatar src={view.author.photoURL} />
                </ListItemAvatar>
                <SecondaryListItemText
                  primary={moment(view.createDate).format("MMM DD")}
                  secondary={moment(view.createDate).format("YYYY")}
                />
                <ListItemText primary={view.label} secondary={view.subLabel} />
                <ListItemIcon>{isOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
              </StyledListItem>
              <Collapse in={isOpen}>
                <List>
                  <SubListItem>
                    {view.comments ? (
                      <>
                        <ListItemIcon>
                          <CommentIcon />
                        </ListItemIcon>
                        <ListItemText primary={<Linkify componentDecorator={linkDecorator}>{view.comments}</Linkify>} />
                      </>
                    ) : (
                      <ListItemText primary="No comments" primaryTypographyProps={{ color: "textSecondary" }} />
                    )}
                  </SubListItem>
                  {buttons}
                </List>
              </Collapse>
            </React.Fragment>
          )
        })}
      </List>
    </BaseDialog>
  )
}

const mapStateToProps: MapState<StoreProps, Props> = (state, { asAdmin }) => {
  const user = state.auth
  const { usersById } = state.data
  const itemRequestViews = mapItemRequestsToViews(getItemRequestForPreview(state, asAdmin), usersById)
  const itemRequestsCount = itemRequestViews.length
  return {
    itemRequestViews,
    itemRequestsCount: itemRequestsCount,
    user,
  }
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => ({
  close: () => dispatch(appActions.changeState(UIState.Other)),
  edit: id => router.navigate(`${router.ITEM_REQUEST}/${id}/edit`),
  details: id => router.navigate(`${router.ITEM_REQUEST}/${id}`),
  navigateToList: () => router.navigate(router.ITEM_REQUEST),
  setInProgress: (req: ItemRequest) => firebaseOperations.editItemRequest({ ...req, status: "IN PROGRESS" }),
  resolve: (req: ItemRequest) => firebaseOperations.editItemRequest({ ...req, status: "RESOLVED" }),
  reject: (req: ItemRequest) => firebaseOperations.editItemRequest({ ...req, status: "REJECTED" }),
})

export const ItemRequestPreview = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemRequestPreviewComponent)
