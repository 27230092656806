import { extend } from "@/utils"

import { DataActions } from "../actions"

export interface DataState {
  usersById: SMap<User>
  categoriesById: SMap<Category>
  itemsById: SMap<Item>
  tagsById: SMap<Tag>
  rentRequestsById: SMap<RentAction>
  filtersById: SMap<Filter>
  itemRequestsById: SMap<ItemRequest>
}

const initialState: DataState = {
  usersById: {},
  categoriesById: {},
  rentRequestsById: {},
  itemsById: {},
  tagsById: {},
  filtersById: {},
  itemRequestsById: {},
}

export const reducer: Reducer<DataState, DataActions> = (state = initialState, action: DataActions) => {
  const ext = extend(state)
  switch (action.type) {
    case "DATA/load":
      return ext({ ...action.payload })
    case "DATA/concat":
      return ext({ [action.payload.key]: { ...state[action.payload.key], ...action.payload.value } })
    case "DATA/add":
    case "DATA/update":
      return ext({ [action.payload.key]: { ...state[action.payload.key], ...action.payload.value } })
    case "DATA/remove": {
      const newState = ext({})
      delete newState[action.payload.key][action.payload.id]
      return newState
    }
    default:
      return state
  }
}
