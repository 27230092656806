import { database } from "firebase/app"
import "firebase/database"
import { convertIncomingMap, convertIncomingSingle } from "@/models/validators"
import { ValidatorMapType } from "@/models"
import { DataPayload, KeyValuePayload } from "@/store/actions"
import { keys } from "@/utils"

export type Ref = database.Reference
export type QueryFilter = (dbRef: Ref) => firebase.database.Query

export let db: database.Database
const refs: SMap<Ref> = {}

export function init(app: firebase.app.App) {
  db = app.database()
}

const getRef = (node: DBNode) => {
  const p = node as string

  if (!refs[p]) {
    refs[p] = db.ref(node)
  }
  return refs[p]
}

export type SubscribeOnInit = (values: DataPayload) => void
export type SubscribeOnEvent = (type: database.EventType, value: KeyValuePayload) => void

export interface SubscribeParams {
  onInit?: SubscribeOnInit
  onEvent?: SubscribeOnEvent
}

const eventsToObserve: database.EventType[] = ["child_added", "child_changed", "child_removed"]

export async function subscribe(key: DBNode, { onInit, onEvent }: SubscribeParams, permissionQuery?: QueryFilter) {
  let ref: Ref | firebase.database.Query = getRef(key)
  if (permissionQuery) {
    ref = permissionQuery(ref as Ref)
  }
  if (onInit) {
    const data = await ref.once("value")
    onInit(convertIncomingMap(data.key as ValidatorMapType, data.val()))
  }
  if (onEvent)
    eventsToObserve.forEach(event => {
      let tmpRef = ref
      if (event === "child_added") {
        tmpRef = ref.limitToLast(1)
      }
      tmpRef.on(event, s => {
        onEvent(event, convertIncomingSingle(key as ValidatorMapType, s.key, s.val()))
      })
    })
}

export const loadOnce = async (key: DBNode, id?: string) => {
  return new Promise<SMap<any>>(resolve => {
    let ref = getRef(key)
    if (id) {
      ref = ref.child(id)
    }
    ref.once("value", snap => {
      resolve(snap.val())
    })
  })
}

export const add = (key: DBNode, value: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const ref = getRef(key)
    const id = ref.push(value).key
    ref.child(id).update({ id }, error => {
      if (error) {
        reject(error)
      } else {
        resolve(id)
      }
    })
  })

export const set = (key: DBNode, value: any, id?: string) =>
  new Promise((resolve, reject) => {
    const ref = getRef(key)
    ref.child(id || value.id).set(value, error => {
      if (error) {
        reject(error)
      } else {
        resolve(value)
      }
    })
  })

export const update = (key: DBNode, children: Array<string>, value: any) =>
  new Promise((resolve, reject) => {
    let ref = getRef(key)
    children.forEach(child => {
      ref = ref.child(child)
    })
    ref.update(value, error => {
      if (error) {
        reject(error)
      } else {
        resolve(value)
      }
    })
  })

export const updateEachField = (key: DBNode, children: Array<string>, value: object) => {
  keys(value).forEach(valueKey => {
    update(key, children.concat(valueKey), value[valueKey])
  })
}

export const remove = (key: DBNode, value: any, id?: string) =>
  new Promise((resolve, reject) => {
    const ref = getRef(key)
    ref.child(id || value.id).remove(error => {
      if (error) {
        reject(error)
      } else {
        resolve(value)
      }
    })
  })
