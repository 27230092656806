import * as React from "react"
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@material-ui/core"

interface Props {
  title?: string
  open: boolean
  fixedHeight?: string
  actions?: React.ReactNode
}

export const BaseDialog: React.FC<Props> = ({ title = "", children, actions, open, fixedHeight }) => {
  return (
    <Dialog
      PaperProps={{ style: { position: "static", height: fixedHeight || "auto" } }}
      open={open}
      scroll="paper"
      fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  )
}
