import * as React from "react"
import { Menu, MenuItem, ButtonBase, Tooltip } from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import AssignmentIcon from "@material-ui/icons/Assignment"
import DateRangeIcon from "@material-ui/icons/DateRange"
import styled from "styled-components"
import { PopoverOrigin } from "@material-ui/core/Popover"
import { Glass } from "@/assets/Glass"
import { DateRange } from "@/store/actions"
import { ButtonBaseProps } from "@material-ui/core/ButtonBase"
import { Hash } from "@/assets/Hash"
import { DatePickerDialog } from "./DatePickerDialog"
import * as moment from "moment"
import { IconProps } from "@material-ui/core/Icon"

interface Props {
  isAdmin: boolean
  value: string
  tags: Tag[]
  dateRange: DateRange
  filters: Filter[]
  onValueChanged: (value: string) => void
  onFilterSelect: (id: string) => void
  onTagSelect: (id: string) => void
  onDateRangeChanged: (dateRange: DateRange) => void
}
interface DateRangePickerButtonProps {
  selected: boolean
}

interface SearchContainerProps {
  focused: boolean
}

const SearchContainer = styled.div<SearchContainerProps>`
  height: 60px;
  width: calc(100% - ${({ theme }) => theme.drawer.width}px);
  border-bottom: 3px solid ${({ focused }) => (focused ? "#007cf7" : "#f6f6f6")};
  display: flex;
  margin-top: 80px;
  background-color: #fff;
  margin-left: ${({ theme }) => theme.drawer.width}px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 0;
    width: 100%;
  }
`

const SearchInput = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  outline: none;
`

const StyledDateRangeIcon = styled(DateRangeIcon)<DateRangePickerButtonProps>`
  color: #fff;
  opacity: ${({ selected }) => (selected ? "1.0" : "0.6")};
` as React.FC<IconProps & DateRangePickerButtonProps>

const StyledSquareButton = styled(ButtonBase)`
  height: 57px;
  width: 50px;
  flex-shrink: 0;
  opacity: 0.4;
` as React.FC<ButtonBaseProps>

const DateRangePickerButton = styled(ButtonBase)<DateRangePickerButtonProps>`
  width: 120px;
  flex-shrink: 0;
  height: 60px;
  border-bottom: 3px solid ${({ selected }) => (selected ? "#007cf7" : "#173753")} !important;
  background-color: ${({ selected }) => (selected ? "#007cf7" : "#173753")} !important;
  :hover {
    & * {
      opacity: 0.9;
    }
  }
` as React.FC<ButtonBaseProps & DateRangePickerButtonProps>

interface SelectMenuProps {
  items: Array<{ id: string; name: string }>
  icon?: React.ReactNode
  onItemClick: (id: string) => void
  anchorOrigin?: PopoverOrigin
}

export const SelectMenu: React.FC<SelectMenuProps> = ({
  items,
  onItemClick,
  icon = <MoreVertIcon />,
  anchorOrigin = { horizontal: "right", vertical: "top" } as PopoverOrigin,
}) => {
  const [anchor, setAnchor] = React.useState<HTMLElement>(null)
  return (
    <>
      <StyledSquareButton onClick={e => setAnchor(e.currentTarget)}>{icon}</StyledSquareButton>
      <Menu
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 200,
          },
        }}>
        {items
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
          .map(item => (
            <MenuItem
              key={item.id}
              onClick={() => {
                onItemClick(item.id)
                setAnchor(null)
              }}>
              {item.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  )
}

export const Search: React.FC<Props> = ({
  isAdmin,
  value,
  onValueChanged,
  tags,
  filters,
  dateRange,
  onDateRangeChanged,
  onFilterSelect,
  onTagSelect,
}) => {
  const [datePickerOpen, setDatePickerOpen] = React.useState(false)
  const dateSelected = Boolean(dateRange.from || dateRange.to)
  const [focused, setFocused] = React.useState<boolean>(false)
  const dateTooltip = dateSelected
    ? `from ${dateRange.from ? moment(dateRange.from).format("ll") : "unspecified"} to ${
        dateRange.to ? moment(dateRange.to).format("ll") : "unspecified"
      }`
    : "Pick date range"
  return (
    <SearchContainer focused={focused}>
      <Glass />
      <SearchInput
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder="Search..."
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onValueChanged(e.target.value)}
        value={value}
      />
      <SelectMenu items={tags} onItemClick={onTagSelect} icon={<Hash />} />
      {isAdmin && <SelectMenu items={filters} onItemClick={onFilterSelect} icon={<AssignmentIcon />} />}
      <Tooltip title={dateTooltip}>
        <DateRangePickerButton selected={dateSelected} onClick={() => setDatePickerOpen(true)}>
          <StyledDateRangeIcon selected={dateSelected} />
        </DateRangePickerButton>
      </Tooltip>
      <DatePickerDialog
        open={datePickerOpen}
        onClose={() => setDatePickerOpen(false)}
        dateRange={dateRange}
        onDateRangeChange={onDateRangeChanged}
      />
    </SearchContainer>
  )
}
