import * as React from "react"
import { BaseDialog } from "@/components/BaseDialog"
import { Button, DialogContentText } from "@material-ui/core"
import { ErrorButton } from "./ErrorButton"

interface Props {
  content: string | React.ReactNode
  title?: string
  open?: boolean
  danger?: boolean
  confirmButtonName?: string
}

interface Actions {
  onConfirm: () => void
  onClose: () => void
}

export const ConfirmationDialog: React.FC<Props & Actions> = ({
  title = "Confirmation",
  open,
  content,
  confirmButtonName,
  onConfirm,
  danger,
  onClose,
}) => {
  const ConfirmButton = danger ? ErrorButton : Button
  return (
    <BaseDialog
      title={title}
      actions={[
        <ConfirmButton
          key="confirm_button"
          color="primary"
          data-cy="confirmation-dialog-action-confirm"
          onClick={() => {
            onClose()
            onConfirm()
          }}>
          {confirmButtonName || "Confrim"}
        </ConfirmButton>,
        <Button key="cancel_button" color="default" onClick={onClose}>
          Cancel
        </Button>,
      ]}
      open={open}>
      <DialogContentText>{content}</DialogContentText>
    </BaseDialog>
  )
}
