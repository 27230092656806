import * as React from "react"
import { connect } from "react-redux"
import { editItemRequest } from "@/services/firebase/operations"
import { compose, branch, renderComponent } from "recompose"
import { match } from "react-router-dom"
import { FormikBag, FormikErrors } from "formik"
import * as router from "@/router"
import { ItemRequest } from "./ItemRequest"
import { MapState } from "@/store"
import { Spinner } from "@/components/Spinner"

type Props = {
  match: match<{ id: string }>
} & StoreProps

interface StoreProps {
  itemRequest: ItemRequest
  isAdmin: boolean
}

const ItemRequestEditComponent: React.FC<Props> = ({ itemRequest, isAdmin, match: { params } }) => {
  const handleSubmit = (values: ItemRequest, formikBag: FormikBag<any, ItemRequest>): void => {
    editItemRequest({ ...itemRequest, ...values, id: params.id })
      .then(() => router.goBack())
      .catch(errors => formikBag.setErrors(errors as FormikErrors<ItemRequest>))
  }

  return (
    <ItemRequest
      handleSubmit={handleSubmit}
      itemRequest={itemRequest}
      disabled={!isAdmin && itemRequest.status !== "NEW"}
    />
  )
}

const mapStateToProps: MapState<StoreProps, Props> = (state, props) => ({
  isAdmin: (state.auth || ({} as User)).isAdmin,
  itemRequest: state.data.itemRequestsById[props.match.params.id] || ({} as ItemRequest),
})

export const ItemRequestEdit = compose(
  connect(mapStateToProps),
  branch((props: StoreProps) => !props.itemRequest, renderComponent(Spinner))
)(ItemRequestEditComponent)
