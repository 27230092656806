import { initializeApp } from "firebase/app"
import { store } from "@/store"
import { dataActions, appActions } from "@/store/actions"
import config from "./config"
import * as database from "./database"
import * as storage from "./storage"

import * as auth from "./auth"
export let app: firebase.app.App = null

export function init(initialPath?: string) {
  app = initializeApp(config)
  database.init(app)
  storage.init(app)
  auth.init(app, initialPath)
}

const dispatchOnEvent: database.SubscribeOnEvent = (eventType, data) => {
  switch (eventType) {
    case "child_added": {
      store.dispatch(dataActions.addSingleValue(data))
      break
    }
    case "child_changed": {
      store.dispatch(dataActions.updateSingleValue(data))
      break
    }
    case "child_removed": {
      store.dispatch(dataActions.removeSingleValue(data))
      break
    }
    default:
      break
  }
}

const dispatchOnInit: database.SubscribeOnInit = payload => store.dispatch(dataActions.load(payload))

export function loadData(user: User) {
  const params: database.SubscribeParams = {
    onInit: dispatchOnInit,
    onEvent: dispatchOnEvent,
  }
  try {
    database.subscribe("rentRequests", params)
    database.subscribe("tags", params)
    database.subscribe("filters", params)
    database.subscribe("users", params)

    let permissionQuery: database.QueryFilter
    if (!user.isAdmin) {
      permissionQuery = (node: database.Ref) => node.orderByChild("userId").equalTo(user.id)
    }
    database.subscribe("itemRequests", params, permissionQuery)
    database.subscribe("categories", params)
    database.subscribe("items", params)
  } catch (e) {
    store.dispatch(appActions.error(e))
  }
}
