import { extend } from "@/utils"

import { AuthActions } from "../actions"

export type AuthState = User

const initialState: AuthState = {} as AuthState

export const reducer: Reducer<AuthState, AuthActions> = (state = initialState, action: AuthActions) => {
  const ext = extend(state)
  switch (action.type) {
    case "AUTH/correct": {
      return ext({ ...action.payload })
    }
    case "AUTH/reset": {
      return {} as AuthState
    }
    default:
      return state
  }
}
