import * as React from "react"
import styled from "styled-components"
import { Chip } from "@material-ui/core"

interface Props {
  items: any[]
  onDelete: (id: string) => void
}

export const ChipsContainer = styled.div`
  display: flex;
  padding: 3px;
  margin-bottom: 1em;

  & > div {
    margin: 3px !important;
  }
  flex-wrap: wrap;
`

export const Chips: React.FC<Props> = ({ items, onDelete }) => {
  return (
    <ChipsContainer>
      {items.map(item => (
        <Chip data-cy="chips-chip" key={item.id} label={item.name} onDelete={() => onDelete(item.id)} />
      ))}
    </ChipsContainer>
  )
}
