import * as React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { googleSignIn } from "@/services/firebase/auth"
import { MapDispatch } from "@/store"
import { Button } from "@material-ui/core"
import { Logo } from "@/components/Logo"

interface Actions {
  signIn: () => void
}

const Page = styled.div`
  background: #1b277c;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.div`
  text-align: center;
  margin: 0;
`

const LoginPageComponent: React.FC<Actions> = ({ signIn }) => {
  return (
    <Page>
      <Title>
        <Logo />
      </Title>
      <Button variant="contained" color="primary" onClick={signIn}>
        Sign in with Google
      </Button>
    </Page>
  )
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    signIn: () => dispatch(googleSignIn()),
  }
}

export const LoginPage = connect(
  null,
  mapDispatchToProps
)(LoginPageComponent)
