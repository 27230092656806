import * as React from "react"
import { connect } from "react-redux"
import * as moment from "moment"
import ActionDone from "@material-ui/icons/Done"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import * as firebaseOperations from "@/services/firebase/operations"
import { appActions, UIState } from "@/store/actions"
import { MapState, MapDispatch } from "@/store"
import { mapItemsToConfirmationViews } from "@/models/rentAction"
import { BaseDialog } from "@/components/BaseDialog"
import { Button, List, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Collapse } from "@material-ui/core"
import { getCurrentUserRentConfirmationsNotifications, getCurrentUser } from "@/store/selectors/common"
import { ConfirmationDialog } from "@/components/ConfirmationDialog"
import { DATE_FORMAT } from "@/consts"
import { StyledListItem, PositiveListItem } from "@/components/ListItems"

interface StoreProps {
  confirmationViews: RentConfirmationView[]
  user: User
}

interface Actions {
  close: () => void
  updateConfirmation: (item: Item) => void
}

const ConfirmationsPreviewComponent: React.FC<StoreProps & Actions> = ({
  confirmationViews,
  updateConfirmation: updateItem,
  user,
  close,
}) => {
  const [collapsedViewId, setCollapsedViewId] = React.useState<string>(
    confirmationViews[0] && confirmationViews[0].item.id
  )
  const [acceptConfirmation, setAcceptConfirmation] = React.useState<RentConfirmationView>(null)
  return (
    <BaseDialog
      open
      title={user.isAdmin ? "Handovers" : "Terms Acceptance"}
      actions={
        <Button key="confirmations_preview_close" data-cy="confirmations-preview-action-close" onClick={close}>
          Close
        </Button>
      }>
      <List>
        {confirmationViews.map(confirmation => {
          const isOpen = collapsedViewId === confirmation.item.id
          return (
            <React.Fragment key={confirmation.item.id}>
              <StyledListItem
                button
                onClick={() => {
                  setCollapsedViewId(isOpen ? null : confirmation.item.id)
                }}>
                <ListItemAvatar>
                  <Avatar src={confirmation.author.photoURL} />
                </ListItemAvatar>
                <ListItemText
                  data-cy="confirmations-preview-text"
                  primary={confirmation.label}
                  secondary={confirmation.subLabel}
                />
                <ListItemIcon>{isOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
              </StyledListItem>
              <Collapse in={isOpen}>
                <PositiveListItem
                  button
                  data-cy="confirmations-preview-action-confirm"
                  key={confirmation.item.id + "_confirm"}
                  onClick={() => setAcceptConfirmation(confirmation)}>
                  <ListItemIcon>
                    <ActionDone />
                  </ListItemIcon>
                  <ListItemText primary={user.isAdmin ? "Done" : "Accept"} />
                </PositiveListItem>
              </Collapse>
            </React.Fragment>
          )
        })}
      </List>
      <ConfirmationDialog
        open={Boolean(acceptConfirmation)}
        onClose={() => setAcceptConfirmation(null)}
        onConfirm={() => {
          const { item, isConfirmedByUser } = acceptConfirmation
          const { confirmation } = item.borrowedBy
          if (!isConfirmedByUser) {
            confirmation.isConfirmedByUser = true
            confirmation.confirmationDate = moment().format(DATE_FORMAT)
            confirmation.confirmationDocumentId = ""
          } else {
            confirmation.isConfirmedByAdmin = true
            confirmation.handedDate = moment().format(DATE_FORMAT)
          }
          item.borrowedBy.confirmation = confirmation
          updateItem(item)
        }}
        confirmButtonName={user.isAdmin ? "Confirm" : "Accept"}
        content={
          (acceptConfirmation || ({} as RentConfirmationView)).isConfirmedByUser ? (
            `I confirm that I handed this item to ${acceptConfirmation.author.displayName}`
          ) : (
            <>
              I accept all terms and conditions that are contained in{" "}
              <a
                href="https://docs.google.com/document/d/14jR8SKG5FCv2reyDc89nrNXWKE5JbQ2r9vsj9czNu_o/edit#heading=h.9cprrwmc2jxk"
                target="_blank">
                Tooploox hardware policy
              </a>
            </>
          )
        }
      />
    </BaseDialog>
  )
}

const mapStateToProps: MapState<StoreProps> = state => {
  const { usersById } = state.data
  const itemsWithConfirmations = getCurrentUserRentConfirmationsNotifications(state)
  const confirmationViews = mapItemsToConfirmationViews(itemsWithConfirmations, usersById)
  return {
    user: getCurrentUser(state),
    confirmationViews,
  }
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    close: () => dispatch(appActions.changeState(UIState.Other)),
    updateConfirmation: item =>
      firebaseOperations.partialUpdateItem(item.borrowedBy.confirmation, [item.id, "borrowedBy", "confirmation"]),
  }
}

export const ConfirmationsPreview = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationsPreviewComponent)
