import * as React from "react"
import { connect } from "react-redux"
import { Formik, Form, FormikBag } from "formik"

import { mapObjectToArray } from "@/utils"
import { MapState } from "@/store"
import * as selectors from "@/store/selectors/common"
import * as router from "@/router"
import { statuses } from "@/models/itemRequest"
import { BaseDialog } from "@/components/BaseDialog"
import {
  Button,
  FormGroup,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
  Link,
} from "@material-ui/core"
import styled from "styled-components"
import { FormGroupProps } from "@material-ui/core/FormGroup"
import { DialogContentTextProps } from "@material-ui/core/DialogContentText"
import { InputLabelProps } from "@material-ui/core/InputLabel"

interface StoreProps {
  categories: Category[]
  dispatch?: (v: any) => void
  isAdmin: boolean
}

type Props = {
  handleSubmit: (values: ItemRequest, formikBag: FormikBag<any, ItemRequest>) => void
  itemRequest?: ItemRequest
  isNew?: boolean
  disabled?: boolean
} & StoreProps

const StyledFormGroup = styled(FormGroup)`
  & > * {
    margin: 1em 0 !important;
  }
` as React.FC<FormGroupProps>

const StyledDialogContentText = styled(DialogContentText)`
  && {
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.8rem;
    }
  }
` as React.FC<DialogContentTextProps>

const StyledInputLabel = styled(InputLabel)`
  && {
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.8rem;
    }
  }
` as React.FC<InputLabelProps>

const ItemRequestComponent: React.FC<Props> = ({ categories, handleSubmit, itemRequest, disabled, isNew, isAdmin }) => {
  return (
    <Formik
      initialValues={itemRequest || ({ categoryId: "", itemName: "", comments: "" } as ItemRequest)}
      onSubmit={handleSubmit}
      enableReinitialize
      render={({ handleChange, values, errors, submitForm }) => {
        return (
          <BaseDialog
            fixedHeight="70vh"
            title="Order request"
            open
            actions={[
              <Button
                key="submit"
                color="primary"
                disabled={disabled}
                type="submit"
                onClick={submitForm}
                data-cy="item-request-form-send-button">
                {disabled && !itemRequest ? "Sending" : "Send"}
              </Button>,
              <Button
                key="see all"
                onClick={_ => router.navigate(router.ITEM_REQUEST)}
                data-cy="item-request-form-see-all-button">
                {isAdmin ? `See all requests` : `See my requests`}
              </Button>,
              <Button key="cancel" onClick={_ => router.goBack()}>
                Cancel
              </Button>,
            ]}>
            <Form data-cy="item-request-form">
              <StyledDialogContentText>
                If you need new hardware, software, books, gadgets (such as cases or MacBook stands), office equipment
                (pens, pencils, drawing paper...) or furniture (like cabinets) - please submit it via this Form.
                <br /> Please fill in the product name. If you want a specific item, send us a link to avoid mistakes.
                <br />
                If you need software licenses and e-learning platforms, ask for it via{" "}
                <Link href={`mailto:software@tooploox.com`}>software@tooploox.com</Link>
                <br />
                Enjoy Tooploox Equipment Merchant services!
              </StyledDialogContentText>
              <StyledFormGroup>
                <TextField
                  required
                  label="Item Name"
                  name="itemName"
                  onChange={handleChange}
                  value={values.itemName}
                  fullWidth
                  inputProps={{ maxLength: 30 }}
                  data-cy="item-request-form-name-field"
                />
                <TextField
                  multiline
                  label="Comments (optional)"
                  name="comments"
                  onChange={handleChange}
                  value={values.comments}
                  fullWidth
                  data-cy="item-request-form-comment-field"
                />
                {!isNew && isAdmin && (
                  <FormControl>
                    <InputLabel htmlFor="status-select">Request status</InputLabel>
                    <Select
                      inputProps={{ name: "status", id: "status-select" }}
                      value={values.status || ""}
                      onChange={handleChange}
                      data-cy="item-request-form-status-select">
                      {statuses.map(status => (
                        <MenuItem value={status} key={status} data-cy="item-request-form-status-option">
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </StyledFormGroup>
            </Form>
          </BaseDialog>
        )
      }}
    />
  )
}

const mapStateToProps: MapState<StoreProps> = state => ({
  categories: mapObjectToArray(state.data.categoriesById),
  isAdmin: selectors.isAdmin(state),
})

export const ItemRequest = connect(mapStateToProps)(ItemRequestComponent)
