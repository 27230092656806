import * as database from "./database"
import * as storage from "./storage"
import { createFilter } from "@/models/filter"
import { createTag } from "@/models/tag"
import { createCategory, isCategoryTrusted } from "@/models/category"
import { createItem, assignItemToUser, dequeueItem, makeItemReturned } from "@/models/item"
import {
  parseUser,
  isInStore,
  isBorrowingAnything,
  updateCurrentUserHistory,
  getAdminEmails,
  dequeueUser,
  getCurrentUser,
  getUsersFromQueue,
} from "@/models/user"
import { getRequestForItem, getRequestedItem, createRentRequest, createReturnRequest } from "@/models/rentAction"
import {
  createRejectNotification,
  createAcceptNotification,
  createRequestNotification,
  createAvailabilityNotification,
  createItemRequestNotification,
  createAssignmentNotification,
} from "@/models/notification"
import { keys, toDateString, isFile, forEachInObject, isEmpty } from "@/utils"
import { createItemRequest } from "@/models/itemRequest"
import { store } from "@/store"

export const addTag = (tag: Tag) => {
  const { value, error } = createTag(tag)
  if (!error) {
    delete value.id
    database.add("tags", value)
  }
}

export const removeTag = (tag: Tag) => {
  if (tag.id) {
    database.remove("tags", tag)
  }
}

export const addCategory = (category: Partial<Category>) => {
  if (!category.items) category.items = {}
  const { value, error } = createCategory(category)
  if (!error) {
    delete value.id
    database.add("categories", value)
  }
}

export const addFilter = (filter: Filter) => {
  const { value, error } = createFilter(filter)
  if (!error) {
    database.add("filters", value)
  }
}

export const updateFilter = (filter: Filter) => {
  const { value, error } = createFilter(filter)
  if (!error) {
    database.set("filters", value)
  }
}

export const removeFilter = (filter: Filter) => {
  if (filter.id) {
    database.remove("filters", filter)
  }
}

export const addUser = async (user: Partial<User>) => {
  const parsedUser = parseUser(user)
  if (!isInStore(parsedUser)) {
    return (await database.set("users", parsedUser)) as User
  }
}

export const updateUser = (user: User) => {
  const parsedUser = parseUser(user)
  database.set("users", parsedUser)
}

export const removeUser = (user: User) => {
  if (!isBorrowingAnything(user)) {
    database.remove("users", user)
  } else {
    alert("This user has borrowed items")
  }
}

export const addAdmin = (user: User) => {
  database.update("users", [user.id], { isAdmin: true })
}

export const removeAdmin = (user: User) => {
  database.update("users", [user.id], { isAdmin: false })
}

export const addItem = async (item: Item) => {
  let files = {}
  if (item.files && !isEmpty(item.files)) {
    files = await uploadFiles(item)
  }
  const { value, error } = createItem({ ...item, files })
  if (!error) {
    const id = await database.add("items", value)
    return id as string
  }
  return null
}

export const updateItem = async (item: Item) => {
  let files = {}
  if (item.files && !isEmpty(item.files)) {
    files = await uploadFiles(item)
  }
  const { value, error } = createItem({ ...item, files })
  if (!error) {
    await database.set("items", value)
  }
}

export const partialUpdateItem = async (partial: any, children: string[]) => {
  database.update("items", children, partial)
}

export const archiveItem = (item: Item) => {
  database.update("items", [item.id], { archived: true })
}

export const unarchiveItem = (item: Item) => {
  database.update("items", [item.id], { archived: false })
}

export const removeItem = (item: Item) => {
  database.remove("items", item)
}

export function assignItemTo(userId: string, item: Item, withConfirmation: boolean, date?: Date) {
  const { available, ...rest } = assignItemToUser(item, userId, withConfirmation, date)
  database.update("items", [item.id], { available })
  database.updateEachField("items", [item.id], { ...rest })
  if (getRequestForItem(item.id)) {
    database.remove("rentRequests", null, item.id)
  }

  const updatedUser = updateCurrentUserHistory(item.id, rest.borrowedBy)
  updateUser(updatedUser)
  return { ...item, ...rest }
}

export function assignItemAsAdmin(userId: string, item: Item, withConfirmation: boolean, date?: Date) {
  const assignedItem = assignItemTo(userId, item, withConfirmation, date)
  if (assignedItem.borrowedBy) {
    const email = createAssignmentNotification(assignedItem.borrowedBy)
    database.set("notifications", email)
  }
}

export function returnItem(item: Item, date?: Date) {
  const oldBorrowedBy = item.borrowedBy
  const { available, borrowedBy, ...rest } = makeItemReturned(item, date)
  database.update("items", [item.id], { available })
  database.update("items", [item.id], { borrowedBy })
  database.updateEachField("items", [item.id], { ...rest })
  if (rest.queue) {
    const recipients = getUsersFromQueue(rest.queue)
    if (keys(recipients).length) {
      const email = createAvailabilityNotification(oldBorrowedBy, recipients)
      database.set("notifications", email)
    }
  }

  const u = getCurrentUser()
  if (!u.history) u.history = {}
  u.history[oldBorrowedBy.id] = oldBorrowedBy
  if (!u.borrowed) u.borrowed = {}
  delete u.borrowed[item.id]
  if (u.queue) delete u.queue[item.id]
  updateUser(u)
}

export function queueItem(item: Item) {
  const user = getCurrentUser()
  if (!item.queue) item.queue = {}
  if (item.queue[user.id]) return

  const entry = createRentRequest(user.id, item)
  const queue = { [user.id]: entry }
  database.update("items", [item.id, "queue"], queue)
  if (!user.queue) user.queue = {}
  user.queue[item.id] = entry
  updateUser(user)
}

export function unqueueItem(userId: string, itemId: string) {
  const queue = dequeueItem(userId, itemId)
  database.update("items", [itemId, "queue"], queue)
  const user = dequeueUser(userId, itemId)
  updateUser(user)
}

export function requestBorrowItem(userId: string, item: Item) {
  if (isCategoryTrusted(item.categoryId)) {
    assignItemTo(userId, item, false)
  } else {
    const req = createRentRequest(userId, item)
    database.set("rentRequests", req, item.id)
    const email = createRequestNotification(req, getAdminEmails())
    database.set("notifications", email)
  }
}

export function requestReturnItem(userId: string, item: Item) {
  if (isCategoryTrusted(item.categoryId)) {
    returnItem(item)
  } else {
    const req = createReturnRequest(userId, item)
    database.set("rentRequests", req, item.id)
    const email = createRequestNotification(req, getAdminEmails())
    database.set("notifications", email)
  }
}

export function cancelRequestBorrowItem(itemId: string) {
  database.remove("rentRequests", null, itemId)
}

export function acceptRequest(req: RentAction) {
  const item = getRequestedItem(req)
  if (item.borrowedBy && !(item.borrowedBy.userId === req.userId)) return
  if (req.returnDate) returnItem(item)
  else assignItemTo(req.userId, item, true)
  database.remove("rentRequests", null, req.itemId)

  const email = createAcceptNotification(req)
  database.set("notifications", email)
}

export function rejectRequest(req: RentAction) {
  database.remove("rentRequests", null, req.itemId)
  const email = createRejectNotification(req)
  database.set("notifications", email)
}

export const addNewItemRequest = async (itemRequest: ItemRequest) => {
  const userId = store.getState().auth.id
  const status = "NEW"
  const { value, error } = createItemRequest({
    ...itemRequest,
    status,
    userId,
    createDate: toDateString(new Date()),
  })

  if (!error) {
    const result = (await database.add("itemRequests", value)) as string
    const email = createItemRequestNotification(value, getAdminEmails())
    await database.set("notifications", email)
    return result
  }
  throw error
}

export const editItemRequest = async (itemRequest: ItemRequest) => {
  const userId = store.getState().auth.id
  if (itemRequest.status !== "NEW") {
    itemRequest.caretakerId = userId
  }
  const { value, error } = createItemRequest(itemRequest)
  if (!error) {
    const result = (await database.update("itemRequests", [value.id], value)) as string

    if (userId !== itemRequest.userId) {
      const email = createItemRequestNotification(value)
      await database.set("notifications", email)
    }
    return result
  }
  throw error
}

export const uploadFiles = async (item: Item) => {
  const uploads = []
  const names = {} as SMap<ItemFile>
  forEachInObject(item.files, (fileId, fileBundle) => {
    if (!fileBundle.file || !isFile(fileBundle.file)) {
      names[fileId] = fileBundle
      return
    }
    const name = `${fileBundle.type}-${item.name}-${fileId}`
    uploads.push(storage.upload(fileBundle.type, name, fileBundle.file))
    names[fileId] = { type: fileBundle.type, name }
  })
  await Promise.all(uploads)
  return names
}

export const removeFile = async (type: ItemFileType, name: string) => {
  await storage.remove(type, name)
}

export const downloadFile = async (file: ItemFile) => await storage.download(file.type, file.name)
