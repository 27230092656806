import * as React from "react"
import { connect } from "react-redux"

import * as firebaseOperations from "@/services/firebase/operations"
import { filterByKeys } from "@/store/selectors/common"

import { ConfirmationDialog } from "../components/ConfirmationDialog"
import { mapObjectToArray } from "@/utils"
import { appActions, UIState } from "@/store/actions"
import { DataState } from "@/store/reducers/data"
import { MapState, MapDispatch } from "@/store"
import { List, ListItem, ListItemText, TextField, FormGroup, Button } from "@material-ui/core"
import { Autocomplete } from "@/components/Autocomplete"
import { BaseDialog } from "@/components/BaseDialog"
import styled from "styled-components"
import { FormGroupProps } from "@material-ui/core/FormGroup"
import { Chips } from "@/components/Chips"

type StoreProps = Pick<DataState, "tagsById" | "filtersById">

interface Actions {
  addFilter: (filter: Filter) => void
  updateFilter: (filter: Filter) => void
  removeFilter: (id: Filter) => void
  close: () => void
}

interface State {
  selectedFilter: Filter
  deleteAction: any
}

const StyledFormGroup = styled(FormGroup)`
  & > * {
    margin-bottom: 2em !important;
  }
` as React.FC<FormGroupProps>

interface FilterListActions {
  onFilterClick: (filter: Filter) => void
}

const FiltersList: React.FC<StoreProps & FilterListActions> = ({ filtersById, tagsById, onFilterClick }) => {
  return (
    <List>
      {mapObjectToArray(filtersById, (id, filter) => (
        <ListItem button key={id} onClick={() => onFilterClick(filter)}>
          <ListItemText
            primary={filter.printName}
            secondary={
              <>
                {`Tags: ${filter.tags &&
                  Object.keys(filter.tags)
                    .map(tagId => tagsById[tagId] && tagsById[tagId].name)
                    .filter(Boolean)
                    .join(", ")}`}
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

interface EditFilterProps {
  tagsById: SMap<Tag>
  selectedFilter: Filter
  onFieldChange: (change: Partial<Filter>) => void
  onTagRemove: (id: string) => void
  onTagAdd: (id: string) => void
}

const EditFilter: React.FC<EditFilterProps> = ({ tagsById, selectedFilter, onFieldChange, onTagRemove, onTagAdd }) => {
  return (
    <StyledFormGroup>
      <TextField
        value={selectedFilter.name}
        label="Name"
        onChange={(e: any) => onFieldChange({ name: e.target.value })}
      />
      <TextField
        value={selectedFilter.printName}
        label="Export name"
        onChange={(e: any) => onFieldChange({ printName: e.target.value })}
      />
      <Chips
        items={mapObjectToArray(selectedFilter.tags, id => tagsById[id] || { id, name: "" })}
        onDelete={onTagRemove}
      />
      <Autocomplete
        label="Assign tag"
        suggestions={mapObjectToArray(tagsById).filter(x => !selectedFilter.tags[x.id])}
        suggestionsConfig={{
          label: "name",
          value: "id",
        }}
        onValueChanged={onTagAdd}
        resetOnValueChanged
      />
    </StyledFormGroup>
  )
}

class FiltersEditorComponent extends React.Component<StoreProps & Actions, State> {
  state = { selectedFilter: null, deleteAction: null }

  render() {
    const { selectedFilter, deleteAction } = this.state
    const { filtersById, tagsById, close } = this.props
    return (
      <BaseDialog
        open
        title={selectedFilter ? "Edit filter" : "Manage filters"}
        actions={
          selectedFilter ? (
            <>
              <Button
                disabled={Object.keys(selectedFilter.tags).length === 0}
                onClick={selectedFilter.id ? this.updateFilter : this.addFilter}>
                Save
              </Button>
              {selectedFilter.id && (
                <Button onClick={() => this.setState({ deleteAction: this.handleDelete })}>Delete</Button>
              )}

              <Button onClick={() => this.setState({ selectedFilter: null })}>Cancel</Button>
            </>
          ) : (
            <>
              <Button onClick={this.createNewFilter}>New filter</Button>
              <Button onClick={close}>Close</Button>
            </>
          )
        }>
        {selectedFilter ? (
          <EditFilter
            tagsById={tagsById}
            onTagRemove={this.removeTag}
            onTagAdd={this.addTag}
            selectedFilter={selectedFilter}
            onFieldChange={change =>
              this.setState({
                selectedFilter: { ...selectedFilter, ...change },
              })
            }
          />
        ) : (
          <FiltersList
            tagsById={tagsById}
            filtersById={filtersById}
            onFilterClick={filter => this.setState({ selectedFilter: filter })}
          />
        )}
        <ConfirmationDialog
          content={`Are you sure you want delete filter ${this.state.selectedFilter &&
            this.state.selectedFilter.name}?`}
          danger
          open={Boolean(deleteAction)}
          onConfirm={deleteAction}
          onClose={this.handleClose}
        />
      </BaseDialog>
    )
  }

  private handleDelete = () => {
    this.removeFilter()
    this.handleClose()
  }

  private handleClose = () => {
    this.setState({
      deleteAction: null,
    })
  }

  private updateFilter = () => {
    this.props.updateFilter(this.state.selectedFilter)
    this.setState({ selectedFilter: null })
  }

  private addFilter = () => {
    this.props.addFilter(this.state.selectedFilter)
    this.setState({ selectedFilter: null })
  }

  private removeFilter = () => {
    this.props.removeFilter(this.state.selectedFilter)
    this.setState({ selectedFilter: null })
  }

  private createNewFilter = () => {
    this.setState({
      selectedFilter: {
        id: "",
        name: "",
        printName: "",
        tags: {} as SMap<boolean>,
      },
    })
  }

  private removeTag = (tagId: string) => {
    const selectedFilter = this.state.selectedFilter
    delete selectedFilter.tags[tagId]
    this.setState({ selectedFilter })
  }

  private addTag = (tagId: string) => {
    const selectedFilter = this.state.selectedFilter
    selectedFilter.tags[tagId] = true
    this.setState({ selectedFilter })
  }
}

const mapStateToProps: MapState<StoreProps> = state => {
  return filterByKeys(state.data, ["filtersById", "tagsById"])
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    updateFilter: (filter: Filter) => firebaseOperations.updateFilter(filter),
    addFilter: (filter: Filter) => firebaseOperations.addFilter(filter),
    removeFilter: (filter: Filter) => firebaseOperations.removeFilter(filter),
    close: () => dispatch(appActions.changeState(UIState.Other)),
  }
}

export const FiltersEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersEditorComponent)
