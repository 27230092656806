import * as React from "react"
import styled from "styled-components"
import { List, IconButton, Hidden } from "@material-ui/core"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import { Logo } from "./Logo"
import { IconButtonProps } from "@material-ui/core/IconButton"
import { SnackbarContainerProps } from "../containers/RentConfirmationSnackbar"

interface DrawerSection {
  title: string
  renderSectionItems: React.ReactNode[]
}

interface Props {
  onLogoClick?: () => void
  sections?: DrawerSection[]
  open: boolean
  onClose?: () => void
}

const LogoSection = styled.div`
  height: ${({ theme }) => theme.appbar.heightTop}px;
  background-color: #007cf7;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`

const CloseIconButton = styled(IconButton)`
  && {
    color: #ffffff;
  }
` as React.FC<IconButtonProps>

const StyledDrawer = styled.div<Props & SnackbarContainerProps>`
  width: ${({ theme }) => theme.drawer.width}px;
  height: 100vh;
  position: absolute;
  background-color: #fafafa;
  left: 0;
  top: ${({ withSnackbar, theme }) => (withSnackbar ? theme.snackbar.height : 0)}px;
  transition: top 0.3s, left 0.3s;
  z-index: 1300;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    left: ${({ theme, open }) => (open ? 0 : -theme.drawer.width)}px;
  }
`

const ScrollableSectionsContainer = styled.div<SnackbarContainerProps>`
  overflow-y: scroll;
  height: calc(
    100vh -
      ${({ withSnackbar, theme }) =>
        withSnackbar ? theme.appbar.heightTop + theme.snackbar.height : theme.appbar.heightTop}px
  );
`

const Section = styled.div`
  width: calc(100% - 30px);
  padding: 30px 0 0 30px;
`

const SectionTitle = styled.h3`
  font-family: Roboto;
  font-size: 11px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 2px;
  color: #919dac;
  margin-bottom: 0;
`

interface SectionListItemTextProps {
  selected?: boolean
}

export const DrawerSectionListItemText = styled.div<SectionListItemTextProps>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
  line-height: 1.86;
  letter-spacing: normal;
  color: ${({ selected }) => (selected ? "#007cf7" : "#919dac")};
`

export const Drawer: React.FC<Props & SnackbarContainerProps> = ({
  sections,
  onLogoClick,
  withSnackbar,
  open,
  onClose,
}) => {
  return (
    <StyledDrawer withSnackbar={withSnackbar} open={open}>
      <LogoSection>
        <Logo onClick={onLogoClick} />
        <Hidden mdUp>
          <CloseIconButton aria-label="Close drawer">
            <ChevronLeftIcon fontSize="large" onClick={onClose} />
          </CloseIconButton>
        </Hidden>
      </LogoSection>
      <ScrollableSectionsContainer withSnackbar={withSnackbar}>
        {sections.map(section => (
          <Section key={section.title}>
            <SectionTitle>{section.title}</SectionTitle>
            <List data-cy="drawer-section-list">{section.renderSectionItems}</List>
          </Section>
        ))}
      </ScrollableSectionsContainer>
    </StyledDrawer>
  )
}
