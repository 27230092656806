import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`

body {
  background: #fff;
  font-family: 'Istok Web', sans-serif !important;
  font-size: 13px; // 1em
  min-width: 330px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 2px;  /* for vertical scrollbars */
  height: 0px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #2096f3;
}

`
