export const theme = {
  appbar: {
    heightTop: 80,
    heightBottom: 63,
    heightWhole: 143,
  },
  drawer: {
    width: 240,
  },
  snackbar: {
    height: 52,
  },
  breakpoints: {
    xs: "0",
    sm: "600px",
    md: "960px",
    lg: "1280px",
    xl: "1920px",
  },
}
