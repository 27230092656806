import { store } from "@/store"
const createNotification = (
  request: RentAction,
  type: RentNotificationStatus,
  to?: string | SMap<string>
): RentNotification => {
  const state = store.getState()
  const u = state.data.usersById[request.userId]
  const i = state.data.itemsById[request.itemId]
  const email: RentNotification = {
    ...request,
    to: to || u.email,
    userDisplayName: u.displayName,
    id: new Date().getTime().toString(),
    itemName: i.name,
    type,
  }
  return email
}

export const createItemRequestNotification = (
  request: ItemRequest,
  to?: string | SMap<string>
): ItemRequestNotification => {
  const state = store.getState()
  const u = state.data.usersById[request.userId]
  const c = state.data.usersById[request.caretakerId] || ({} as User)
  const email: ItemRequestNotification = {
    ...request,
    to: to || u.email,
    userDisplayName: u.displayName,
    caretakerDisplayName: c.displayName || "",
    id: new Date().getTime().toString(),
    type: "ITEM_REQUEST",
  } as ItemRequestNotification
  return email
}

export const createRejectNotification = (request: RentAction) => {
  const email = createNotification(request, "REQUEST_REJECTED")
  if (request.returnDate) email.returnAcceptedBy = store.getState().auth.displayName
  else email.borrowAcceptedBy = store.getState().auth.displayName
  return email
}

export const createAcceptNotification = (request: RentAction) => {
  const email = createNotification(request, "REQUEST_ACCEPTED")
  if (request.returnDate) email.returnAcceptedBy = store.getState().auth.displayName
  else email.borrowAcceptedBy = store.getState().auth.displayName
  return email
}

export const createAssignmentNotification = (request: RentAction) => {
  return createNotification(request, "ITEM_ASSIGNED")
}

export const createRequestNotification = (request: RentAction, to: SMap<string>) => {
  return createNotification(request, "BORROW_REQUESTED", to)
}

export const createAvailabilityNotification = (request: RentAction, to: SMap<string>) => {
  return createNotification(request, "ITEM_AVAILABLE", to)
}
