import * as React from "react"
import { BaseDialog } from "@/components/BaseDialog"
import { Button } from "@material-ui/core"
import { DateRange } from "@/store/actions"
import { DatePicker, DatePickerProps } from "material-ui-pickers"
import styled from "styled-components"
import { DATE_FORMAT } from "@/consts"
import * as moment from "moment"

interface Props {
  title?: string
  dateRange?: DateRange
  open?: boolean
}

interface Actions {
  onDateRangeChange: (dR: DateRange) => void
  onClose: () => void
}

const PickersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`

const StyledDatePicker = styled(DatePicker)<DatePickerProps>`
  & input {
    color: ${({ value }) => (value ? "#007cf7" : "#919dac")};
    font-weight: ${({ value }) => (value ? "bold" : "normal")};
  }
`

export const DatePickerDialog: React.FC<Props & Actions> = ({
  title = "Choose date range",
  open,
  dateRange,
  onDateRangeChange,
  onClose,
}) => {
  const [dateRangeState, setDateRangeState] = React.useState<DateRange>(dateRange)
  const updateDateRange = (date: moment.Moment, key: "to" | "from") => {
    const newDateRange = { ...dateRangeState, [key]: date ? date.format(DATE_FORMAT) : null }
    setDateRangeState(newDateRange)
  }
  return (
    <BaseDialog
      title={title}
      actions={[
        <Button
          key="confirm_button"
          color="default"
          onClick={() => {
            onClose()
            onDateRangeChange(dateRangeState)
          }}>
          OK
        </Button>,
        <Button
          key="clear_button"
          color="default"
          onClick={() => {
            onClose()
            setDateRangeState({})
            onDateRangeChange({})
          }}>
          Clear
        </Button>,
        <Button key="cancel_button" color="primary" onClick={onClose}>
          Cancel
        </Button>,
      ]}
      open={open}>
      <PickersContainer>
        <StyledDatePicker
          label="From date"
          value={dateRangeState.from || null}
          clearable={true}
          emptyLabel="Any"
          clearLabel="Clear"
          onChange={date => updateDateRange(date, "from")}
        />
        <StyledDatePicker
          label="To date"
          value={dateRangeState.to || null}
          clearable={true}
          emptyLabel="Any"
          clearLabel="Clear"
          onChange={date => updateDateRange(date, "to")}
        />
      </PickersContainer>
    </BaseDialog>
  )
}
