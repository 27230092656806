import * as Redux from "redux"
import thunk from "redux-thunk"
import { connectRouter, routerMiddleware } from "connected-react-router"
import { createBrowserHistory } from "history"
import { MapStateToProps } from "react-redux"
import { reducer as dataReducer, DataState } from "./reducers/data"
import { reducer as appReducer, AppState } from "./reducers/app"
import { reducer as authReducer, AuthState } from "./reducers/auth"

export type ThunkAction = ((dispatch: Redux.Dispatch<any> | any, getState: () => RootState) => void)

export interface RootState {
  router: any
  app: AppState
  data: DataState
  auth: AuthState
}

export type MapState<TS, TO = {}> = MapStateToProps<TS, TO, RootState>
export type MapDispatch<TA, TO = {}> = (dispatch: any, ownProps: TO) => TA // Thunk actions crash redux types

export const history = createBrowserHistory()
const rootReducer = Redux.combineReducers<RootState>({
  //@ts-ignore TODO
  router: connectRouter(history),
  app: appReducer,
  data: dataReducer,
  auth: authReducer,
})

const env: any = window || {}
function configureStore(initialState = {}): Redux.Store<RootState> {
  const middlewares = Redux.applyMiddleware(routerMiddleware(history), thunk)
  const comp = Redux.compose(
    middlewares,
    env.__REDUX_DEVTOOLS_EXTENSION__ ? env.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f
  ) as any

  return Redux.createStore(rootReducer, initialState as RootState, comp)
}

export const store = configureStore({} as RootState)
