import "firebase/storage"

export let storage: firebase.storage.Storage
type Ref = firebase.storage.Reference
export const init = (app: firebase.app.App) => {
  storage = app.storage()
}

const refs: SMap<Ref> = {}

const getRef = (node: CloudStorageNode) => {
  const p = node as string

  if (!refs[p]) {
    refs[p] = storage.ref().child(node)
  }
  return refs[p]
}

export const upload = (key: CloudStorageNode, name: string, value: any) =>
  new Promise((resolve, reject) => {
    const ref = getRef(key)
    ref
      .child(name)
      .put(value)
      .then(
        snapshot => {
          resolve(snapshot)
        },
        error => {
          reject(error)
        }
      )
  })

export const download = (key: CloudStorageNode, name: string): Promise<string> =>
  getRef(key)
    .child(name)
    .getDownloadURL()

export const remove = (key: CloudStorageNode, name: string): Promise<any> =>
  getRef(key)
    .child(name)
    .delete()
