import * as React from "react"
import { connect } from "react-redux"

import * as firebaseOperations from "@/services/firebase/operations"
import { appActions, UIState } from "@/store/actions"
import { MapDispatch } from "@/store"
import { BaseDialog } from "@/components/BaseDialog"
import { Button, TextField, Switch, FormControlLabel, DialogContentText } from "@material-ui/core"

interface Actions {
  addCategory: (category: Partial<Category>) => void
  close: () => void
}

interface State {
  value: string
  trusted?: boolean
  hidden?: boolean
}

class CategoryEditorComponent extends React.Component<Actions, State> {
  state = { value: "", trusted: false, hidden: false }

  render() {
    return (
      <BaseDialog
        title="Add new category"
        open
        actions={[
          <Button color="primary" key="add_category_confirm" disabled={!this.state.value} onClick={this.add}>
            Add Category
          </Button>,
          <Button color="default" key="add_category_cancel" onClick={this.props.close}>
            Cancel
          </Button>,
        ]}>
        <TextField fullWidth value={this.state.value} label="Name" onChange={this.handleChange} />
        <FormControlLabel
          control={
            <Switch
              checked={this.state.trusted}
              onChange={(_, checked) => this.setState({ ...this.state, trusted: checked })}
            />
          }
          label={this.state.trusted ? "Trusted" : "Untrusted"}
        />
        <DialogContentText>If category is trusted all no acceptation is needed when renting item</DialogContentText>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.hidden}
              onChange={(_, checked) => this.setState({ ...this.state, hidden: checked })}
            />
          }
          label={this.state.hidden ? "Hidden" : "Visible"}
        />
        <DialogContentText>If category is hidden only administrator can list it</DialogContentText>
      </BaseDialog>
    )
  }

  private handleChange = (event: any) => {
    this.setState({
      value: event.target.value,
    })
  }

  private add = () => {
    const { value, hidden, trusted } = this.state
    this.props.addCategory({ name: value, hidden, trusted })
    this.props.close()
  }
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    addCategory: (category: Partial<Category>) => firebaseOperations.addCategory(category),
    close: () => dispatch(appActions.changeState(UIState.Other)),
  }
}

export const CategoryDialog = connect(
  null,
  mapDispatchToProps
)(CategoryEditorComponent)
