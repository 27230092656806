import * as React from "react"
import { AppBar as MUIAppBar } from "@material-ui/core"
import styled from "styled-components"
import { AppBarProps } from "@material-ui/core/AppBar"
import { SnackbarContainerProps } from "../containers/RentConfirmationSnackbar"

interface StyledAppBarProps {
  short?: boolean
}

const StyledAppBar = styled(({ withSnackbar, short, ...props }) => <MUIAppBar {...props} />)<
  SnackbarContainerProps & StyledAppBarProps
>`
  background-color: #173753 !important;
  box-shadow: none !important;
  height: ${({ theme, short }) => (!short ? theme.appbar.heightWhole : theme.appbar.heightTop)}px;
  margin-top: ${({ theme, withSnackbar }) => (withSnackbar ? theme.snackbar.height : 0)}px;
  transition: margin-top 0.3s;
` as React.FC<AppBarProps & SnackbarContainerProps & StyledAppBarProps>

export const AppBar: React.FC<SnackbarContainerProps & StyledAppBarProps> = ({ children, withSnackbar, short }) => {
  return (
    <StyledAppBar position="fixed" color="inherit" withSnackbar={withSnackbar} short={short}>
      {children}
    </StyledAppBar>
  )
}
