import { validate } from "./validators"
import { ItemValidatorMap } from "./item"
import { keys } from "@/utils"
import { store } from "@/store"
export const CategoryValidatorMap: Casted<Category, Validator> = {
  id: "text",
  name: "text",
  hidden: "boolean",
  trusted: "boolean",
  items: {
    "*": ItemValidatorMap,
  },
}

const requiredKeys: Array<keyof Category> = ["name", "hidden", "trusted"]

export const categoryKeys: Array<keyof Category> = keys(CategoryValidatorMap) as any

export const createCategory = (valueToCheck: any, id?: string): Maybe<Category> => {
  const criticalErrors = []
  const value: Category = categoryKeys.reduce(
    (res, key) => {
      const { value: validatedValue, error } = validate<Category, typeof key>(
        CategoryValidatorMap[key],
        valueToCheck[key]
      )
      if (!error) {
        res[key] = validatedValue
      } else {
        if (requiredKeys.includes(key)) {
          criticalErrors.push(`A key ${key} is required, but validator returned error: ${error}`)
        }
      }
      return res
    },
    { id: valueToCheck[id] || id } as Category
  )
  return criticalErrors.length ? { error: criticalErrors } : { value }
}

export const isCategoryTrusted = (categoryId: string) => {
  const state = store.getState().data
  const category = state.categoriesById[categoryId]
  return category.trusted
}
