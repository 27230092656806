import { createSelector } from "reselect"
import { deburr } from "lodash"

import {
  getItemsByDate,
  getCategoriesById,
  getSelectedCategoryId,
  getAppState,
  getTagsById,
  getUsersById,
  getFiltersById,
  getCurrentUser,
} from "./common"
import { AppState } from "@/store/reducers/app"
import { forEachInObject, normalizeEmail, normalizeString } from "@/utils"
import { SortBy } from "@/store/actions"

interface SearchingPhrases {
  tags: string[]
  texts: string[]
  users: string[]
  filters: string[]
  history: string[]
}

interface DataById {
  tagsById: SMap<Tag>
  usersById: SMap<User>
  filtersById: SMap<Filter>
}

interface Match {
  match: boolean
  qualified: boolean
}

export const getFilteringString = createSelector(
  getAppState,
  (appState: AppState): string => appState.itemFilter.toLowerCase() || ""
)

export const getSortingRules = createSelector(
  getAppState,
  (appState: AppState): SortBy => appState.sortBy
)

export const getItemsForFilter = createSelector(
  getItemsByDate,
  getCategoriesById,
  getSelectedCategoryId,
  getCurrentUser,
  (items: Item[], categoriesById: SMap<Category>, selectedCategoryId, currentUser: User): Item[] => {
    let newItems = [...items]
    if (selectedCategoryId) newItems = newItems.filter(item => item.categoryId === selectedCategoryId)
    if (!currentUser.isAdmin)
      newItems = newItems.filter(
        item =>
          !categoriesById[item.categoryId].hidden || (item.borrowedBy && item.borrowedBy.userId === currentUser.id)
      )
    return newItems
  }
)

const phraseFor = (symbol: string, queryPart: string, phrases: string[]) => {
  const trimmedQueryPart = queryPart.trim()
  const [firstPart, withoutSymbol] = trimmedQueryPart.split(symbol)
  if (!firstPart.length && Boolean(withoutSymbol)) {
    return [...phrases, withoutSymbol]
  }
  return phrases
}

const textPhrase = (queryPart: string, phrases: string[]) => {
  const trimmedQueryPart = queryPart.trim()
  if (!new RegExp(/^(history:|#|@|\$)/gi).test(trimmedQueryPart)) {
    return [...phrases, trimmedQueryPart]
  }
  return phrases
}

export const findSearchingPhrases = createSelector(
  getFilteringString,
  (filteringString: string): SearchingPhrases => {
    return filteringString.split(/[ ,]+/).reduce(
      (phrases: SearchingPhrases, queryPart: string) => ({
        tags: phraseFor("#", queryPart, phrases.tags),
        texts: textPhrase(queryPart, phrases.texts),
        users: phraseFor("@", queryPart, phrases.users),
        filters: phraseFor("$", queryPart, phrases.filters),
        history: phraseFor("history:", queryPart, phrases.history),
      }),
      {
        tags: [],
        texts: [],
        users: [],
        filters: [],
        history: [],
      } as SearchingPhrases
    )
  }
)

const getItemTagNames = (item: Item, tagsById: SMap<Tag>): string[] => {
  return Object.keys(item.tags)
    .map(id => {
      return tagsById[id] && tagsById[id].name.toLowerCase()
    })
    .filter(Boolean)
}

const matchByTags = (item: Item, searchingPhrases: SearchingPhrases, { tagsById }: DataById): Match => {
  const isQualified = Boolean(searchingPhrases.tags.length)
  if (!item.tags || !isQualified) {
    return { match: false, qualified: isQualified }
  }
  const itemTagNames = getItemTagNames(item, tagsById)
  return {
    match: searchingPhrases.tags.every(tag => itemTagNames.some(name => name.indexOf(tag) !== -1)),
    qualified: isQualified,
  }
}

const matchByUsers = (item: Item, searchingPhrases: SearchingPhrases, { usersById }: DataById): Match => {
  const isQualified = Boolean(searchingPhrases.users.length)

  if (!item.borrowedBy || !isQualified || !item.borrowedBy.userId) {
    return { match: false, qualified: isQualified }
  }
  const user = usersById[item.borrowedBy.userId]
  if (!user) return { match: false, qualified: isQualified }
  return {
    match: searchingPhrases.users.some(queryUser => user.displayName.toLowerCase().indexOf(queryUser) !== -1),
    qualified: isQualified,
  }
}

const findFilterIds = (queryFilter: string[], filtersById: SMap<Filter>): string[] => {
  const filters: string[] = []
  forEachInObject(filtersById, (_, filter) => {
    if (queryFilter.includes(filter.name)) {
      filters.push(...Object.keys(filter.tags))
    }
  })
  return filters
}

const matchByFilters = (item: Item, searchingPhrases: SearchingPhrases, { filtersById }: DataById): Match => {
  const isQualified = Boolean(searchingPhrases.filters.length)
  if (!item.tags || !isQualified) {
    return { match: false, qualified: isQualified }
  }
  const filters = findFilterIds(searchingPhrases.filters, filtersById)
  return {
    match: Object.keys(item.tags).some(tagId => filters.length && filters.includes(tagId)),
    qualified: isQualified,
  }
}

const matchByTexts = (item: Item, searchingPhrases: SearchingPhrases, { tagsById, usersById }: DataById): Match => {
  const isQualified = Boolean(searchingPhrases.texts.length)
  const normalizedItemName = normalizeString(item.name.toLowerCase())
  if (!item.name || !isQualified) {
    return { match: false, qualified: isQualified }
  }
  const itemTagNames = normalizeString(getItemTagNames(item, tagsById).join(" "))

  const borrowedBy = item.borrowedBy
  const user = borrowedBy ? usersById[borrowedBy.userId] : null
  const username = user ? user.displayName : ""
  const normalizedUserName = item.borrowedBy ? normalizeString(username.toLowerCase()) : ""

  return {
    match: searchingPhrases.texts.every(text => {
      const normalizedText = normalizeString(text)
      return (
        normalizedItemName.indexOf(normalizedText) !== -1 ||
        (item.signature || "").toLowerCase().indexOf(normalizedText) !== -1 ||
        itemTagNames.indexOf(normalizedText) !== -1 ||
        normalizedUserName.indexOf(normalizedText) !== -1
      )
    }),
    qualified: isQualified,
  }
}

const matchByHistory = (item: Item, searchingPhrases: SearchingPhrases): Match => {
  const isQualified = Boolean(searchingPhrases.history.length)

  if (!item.history || !isQualified) {
    return { match: false, qualified: isQualified }
  }

  const normalizedEmail = normalizeEmail(searchingPhrases.history[0].trim())

  const matchedId = Object.keys(item.history).find((historyId: string) => {
    return item.history[historyId].userId === normalizedEmail
  })

  return {
    match: Boolean(matchedId),
    qualified: isQualified,
  }
}

const matchers = [matchByTags, matchByUsers, matchByFilters, matchByHistory, matchByTexts]

export const getFilteredItems = createSelector(
  getItemsForFilter,
  findSearchingPhrases,
  getTagsById,
  getUsersById,
  getFiltersById,
  (
    items: Item[],
    searchingPhrases: SearchingPhrases,
    tagsById: SMap<Tag>,
    usersById: SMap<User>,
    filtersById: SMap<Filter>
  ): Item[] => {
    const dataById = {
      tagsById,
      usersById,
      filtersById,
    }

    return items.reduce(
      (accumulator: Item[], item: Item) => {
        const isItemQualified = matchers
          .map(matcher => matcher(item, searchingPhrases, dataById))
          .filter(result => result.qualified)
          .every(result => result.match)

        return isItemQualified ? [...accumulator, item] : accumulator
      },
      [] as Item[]
    )
  }
)

export const isFilterHistory = createSelector(
  [getFilteringString],
  (filterString: string): boolean => {
    const isHistoryRegExp = /^(history:)/gi
    return isHistoryRegExp.test(filterString)
  }
)

const prepareToCompare = (text: string) => {
  return deburr(text)
    .trim()
    .toUpperCase()
}

const compareForSort = (text1: string, text2: string) => {
  return prepareToCompare(text1) > prepareToCompare(text2)
}

export const getSortedItems = createSelector(
  getSortingRules,
  (_, items: Item[]): Item[] => items,
  (sortingRule: SortBy, items: Item[]): Item[] => {
    switch (sortingRule.toString()) {
      case SortBy.NameDESC.toString():
        return items.sort((item1, item2) => (compareForSort(item1.name, item2.name) ? -1 : 1))
      case SortBy.Available.toString():
        return items.sort((item1, item2) => (item1.available !== false && item2.available === false ? -1 : 1))
      case SortBy.DateASC.toString():
        return items.sort((item1, item2) => {
          if (!item1.buyDate) return 1
          if (!item2.buyDate) return -1
          return new Date(item1.buyDate).getTime() > new Date(item2.buyDate).getTime() ? -1 : 1
        })
      case SortBy.DateDESC.toString():
        return items.sort((item1, item2) => {
          if (!item1.buyDate) return 1
          if (!item2.buyDate) return -1
          return new Date(item1.buyDate).getTime() < new Date(item2.buyDate).getTime() ? -1 : 1
        })
      case SortBy.NameASC.toString():
      default:
        return items.sort((item1, item2) => (compareForSort(item1.name, item2.name) ? 1 : -1))
    }
  }
)
