import * as firebase from "firebase/app"
import "firebase/auth"
import { ThunkAction } from "@/store"
import { authActions } from "@/store/actions"
import * as router from "@/router"
import { store } from "@/store"
import * as database from "./database"
import * as dataService from "./index"
import { updateUser } from "./operations"
import { isEmpty, normalizeEmail } from "@/utils"
import { ORGANIZATION } from "@/consts"
let auth: firebase.auth.Auth
let redirectTo: string

const isUserLoggedIn = (user: Partial<User>): boolean => {
  return Boolean(user && user.email.match(ORGANIZATION))
}

const handleAuth = async (user: Partial<User>) => {
  if (isUserLoggedIn(user)) {
    user.id = normalizeEmail(user.email)
    const currentUserData = await database.loadOnce("users", user.id)

    login({
      ...currentUserData,
      ...user,
    } as any)
  } else {
    store.dispatch(logout() as any)
  }
}

export function init(app: firebase.app.App, redirect?: string) {
  if (auth) return
  if (redirect && !redirect.includes("login")) {
    redirectTo = redirect
  }
  auth = app.auth()
  auth.onAuthStateChanged(handleAuth)
}

function login(user: firebase.UserInfo & User) {
  store.dispatch(authActions.authenticated(user))
  if (user) {
    updateUser(user)
    navigateToApp()
    dataService.loadData(user)
  }
}

function navigateToApp() {
  router.replaceTo(redirectTo || router.APP)
}

export function logout(): ThunkAction {
  return dispatch => {
    auth.signOut()
    dispatch(authActions.disauthenticated())
    router.replaceTo(router.LOGIN)
  }
}

export function googleSignIn(): ThunkAction {
  return (_, getState) => {
    if (isEmpty(getState().auth)) {
      const prov = new firebase.auth.GoogleAuthProvider()
      prov.setCustomParameters({ hd: ["tooploox.com", "quizzpy.com"] })
      auth.signInWithPopup(prov)
    } else navigateToApp()
  }
}
