import * as React from "react"
import styled from "styled-components"

const IconContainer = styled.svg`
  width: 20px;
  height: 20px;
`
export const Hash = () => (
  <IconContainer viewBox="0 0 18 18">
    <path
      fill="#000"
      d="M4.713 10.903l.789-3.58H2.746a1.109 1.109 0 1 1 0-2.217H5.99l.855-3.88a1.109 1.109 0 0 1 2.166.476l-.75 3.404h3.526l.855-3.88a1.109 1.109 0 1 1 2.166.476l-.75 3.404h2.272a1.109 1.109 0 1 1 0 2.218h-2.76l-.79 3.58h2.39a1.109 1.109 0 0 1 0 2.217h-2.878l-.747 3.394a1.109 1.109 0 0 1-2.166-.477l.643-2.917H6.495l-.747 3.394a1.109 1.109 0 0 1-2.166-.477l.643-2.917H1.586a1.109 1.109 0 0 1 0-2.218h3.127zm2.27 0h3.527l.789-3.58H7.772l-.788 3.58z"
    />
  </IconContainer>
)
