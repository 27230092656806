import "babel-polyfill"
import * as React from "react"
import { Provider } from "react-redux"
import { render } from "react-dom"

import { MuiPickersUtilsProvider } from "material-ui-pickers"
import { theme } from "./utils/theme"
import MomentUtils from "@date-io/moment"

import { store } from "@/store/index"
import { getRoutes, getInitialPath } from "@/router"

import * as firebaseService from "./services/firebase"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core"
import { GlobalStyles } from "./globalStyles"
import { ThemeProvider } from "styled-components"

export const mainColor = "#2096f3"
export const errorColor = "#D62067"
export const accentColor = "#173753"

const muiTheme = createMuiTheme({
    palette: {
      primary: {
        main: mainColor,
      },
      secondary: {
        main: accentColor,
      },
      error: {
        main: errorColor,
      },
    },
    typography: {
      useNextVariants: true,
    },
  })

  // tslint:disable-next-line
;(window as any).store = store

firebaseService.init(getInitialPath())

render(
  <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <GlobalStyles />
        <Provider store={store}>{getRoutes()}</Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </MuiThemeProvider>,
  document.getElementById("app")
)
