import * as React from "react"
import { addNewItemRequest } from "@/services/firebase/operations"
import { ItemRequest } from "./ItemRequest"
import { FormikBag, FormikErrors } from "formik"

import * as router from "@/router"

export const ItemRequestNew: React.FC = () => {
  const [isSending, setSending] = React.useState<boolean>(false)
  const handleSubmit = (values: ItemRequest, formikBag: FormikBag<any, ItemRequest>): void => {
    setSending(true)
    addNewItemRequest(values)
      .then(() => {
        router.goBack()
        setSending(false)
      })
      .catch(errors => formikBag.setErrors(errors as FormikErrors<ItemRequest>))
  }

  return <ItemRequest isNew handleSubmit={handleSubmit} disabled={isSending} />
}
