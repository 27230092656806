import * as PapaParse from "papaparse"
import { getDateFromString, toDateString } from "@/utils"
import * as moment from "moment"
import { ThunkAction } from "@/store"
import { DATE_FORMAT } from "@/consts"

export function exportToCSV(items: Item[]): ThunkAction {
  return (_, getState) => {
    const state = getState().data
    const exports = items.map(i => ({
      "Nazwa sprzętu / Model": i.name,
      "Nr seryjny": i.signature,
      "Opis dodatkowy": i.description || "",
      "Wypożyczone przez:":
        i.borrowedBy && state.usersById[i.borrowedBy.userId] ? state.usersById[i.borrowedBy.userId].displayName : "",
      Lokalizacja: getLocationName(i),
      "Data zakupu": i.buyDate ? toDateString(getDateFromString(i.buyDate)) : "",
      "Cena zakupu": i.price,
    }))
    save(exports, `storage-export-${moment().format(DATE_FORMAT + "--hh-mm")}`)
  }
}

const locations = {
  "-KemwgOv2YhgjUV979Nt": "Wroclaw",
  "-KemwfHkaNzk8h71YV7J": "Warszawa",
  "-L2yC5F2G5OtsW9L6v50": "Gdansk",
  "-L1vDNrPdrhoO4tLeDBS": "Remote",
}
function getLocationName(item: Item) {
  for (const id in item.tags) {
    const loc = locations[id]
    if (loc) return loc
  }
  return ""
}

declare var unescape: any
export function save<T>(data: T[], filename: string) {
  const csv = PapaParse.unparse(data, {
    quotes: true,
    delimiter: ",",
    newline: "\r\n",
  })
  const exportLink = document.createElement("a")
  exportLink.setAttribute("href", getUri(csv))
  exportLink.setAttribute("download", filename)
  exportLink.click()
}

function getUri(text: string, type = "data:text/csv") {
  text = encodeURIComponent(text)
  if (window.btoa) return type + ";base64," + window.btoa(unescape(text))
  return type + "," + text
}
