import * as moment from "moment"
import { ValidatorMapType, ValidatorMaps, getConstructorFromValidatorMap } from "@/models"
import { keys, getFirstKeyOf } from "@/utils"
import { isString } from "util"
import { DataPayload, KeyValuePayload } from "@/store/actions"
import { DataState } from "@/store/reducers/data"

type primitiveType = "string" | "boolean" | "number" | "object" | "function" | "symbol" | "undefined"

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const priceRegex = /^\d*,?\d+$/
const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

/**
 *  Performs a check on property that can be described with a some keyname, example: text, email, url
 * @param validator The keyname that the test will depend on
 * @param value Value to be tested
 */
export function validateSimpleProperty(validator: Validator, value: any): boolean {
  switch (validator) {
    case "date":
      return moment(value).isValid()
    case "url":
      return urlRegex.test(value)
    case "email":
      return emailRegex.test(value)
    case "price":
      return priceRegex.test(value)
    case "boolean":
      return validatePrimitive("boolean", value)
    case "number":
      return validatePrimitive("number", value)
    case "text":
      return validatePrimitive("string", value)
    default:
      return false
  }
}

/**
 * Performs a check on property, that is either a simple property or an object
 * @param validateBy Either a model describing the value or a validator keyname
 * @param property Value to be tested
 */
export function validate<T, K extends keyof T>(
  validateBy: Casted<T, Validator> | Validator,
  property: T[K]
): Maybe<T[K]> {
  if (property === undefined || property === null) {
    return { error: `Property is undefined` }
  }
  if (isString(validateBy)) {
    if (!validateSimpleProperty(validateBy, property)) {
      return { error: `Property has incorrect value type` }
    }
    return { value: property }
  }

  return validateObject<T, K>(validateBy, property)
}

/**
 * Performs a check on object. If the value has a model, will search for model and convert values according to the model.
 * If the value is a map, will perform a standard check on every property of the map.
 *
 * @param model Either a model or a map object, that will indicate what actions should be performed
 * @param valueToCheck Value to be tested
 */
export function validateObject<T, K extends keyof T>(
  model: Casted<T, Validator> | MapValidator,
  valueToCheck: T[K]
): Maybe<T[K]> {
  if (model["*"]) {
    const valueMap = {} as T[K]
    // SMap - so every object value is validated with same model/validator
    keys(valueToCheck).forEach(key => {
      const { value, error } = validate(model["*"], valueToCheck[key])
      // when we encounter error in SMaps, we just omit the value
      if (!error) {
        valueMap[key] = value
      }
    })
    // So at the worst scenario an empty object is returned
    return { value: valueMap }
  }
  // model
  const convertModel = getConstructorFromValidatorMap(model as Casted<T, Validator>)
  return convertModel(valueToCheck)
}

/**
 * Endpoint for validation. Maps reference keys to models, and passes values to validating functions
 * @param referenceKey firebase data key, gotten from DataSnapshot.key
 * @param data firebase data object, gotten from DataSnapshot.val()
 */
export function convertIncomingMap(referenceKey: ValidatorMapType, data: object): DataPayload {
  const model = ValidatorMaps[referenceKey]
  const convert = getConstructorFromValidatorMap(model)
  const ById = {}
  keys(data).forEach((key: string) => {
    const { value, error } = convert(data[key], key)
    if (!error) {
      ById[key] = value
    }
  })
  return { [`${referenceKey}ById`]: ById }
}

export function convertIncomingSingle(referenceKey: ValidatorMapType, key: string, val: object): KeyValuePayload {
  const model = ValidatorMaps[referenceKey]
  const convert = getConstructorFromValidatorMap(model)
  const { value, error } = convert(val, key)
  if (error) return { key: `${referenceKey}ById` as keyof DataState }

  if (value && !value.id) {
    value.id = key
  }

  return { key: `${referenceKey}ById` as keyof DataState, value: { [key]: value }, id: key }
}

export const validateAndConvert = <T>(
  modelKeys: Array<keyof T>,
  requiredKeys: Array<keyof T>,
  validator: Casted<T, Validator>,
  value: any
) => {
  const criticalErrors = []

  const result: T = modelKeys.reduce(
    (res, key) => {
      const { value: validatedValue, error } = validate<T, typeof key>(validator, value)
      if (!error) {
        res[key] = validatedValue
      } else {
        if (requiredKeys.includes(key)) {
          criticalErrors.push(`A key ${key} is required, but validator returned error: ${error}`)
        }
      }
      return res
    },
    {} as T
  )
  return criticalErrors.length ? { error: criticalErrors } : { value: result }
}

/**
 * Applies typeof check and accepts only primitives
 * @param type primitive value
 */
function validatePrimitive(type: primitiveType, valueToCheck: any) {
  return typeof valueToCheck === type
}
