import styled from "styled-components"
import { ListItem, ListItemText } from "@material-ui/core"
import { ListItemProps } from "@material-ui/core/ListItem"
import { ListItemTextProps } from "@material-ui/core/ListItemText"

export const StyledListItem = styled(ListItem)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    && {
      padding-left: 0;
      padding-right: 0;
      & * {
        font-size: 0.8125rem;
      }
    }
  }
` as React.FC<ListItemProps>

export const SubListItem = styled(ListItem)`
  && {
    padding-left: 60px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-left: 10px;
      & * {
        font-size: 0.91rem;
      }
    }
  }
` as React.FC<ListItemProps>

export const NeutralListItem = styled(SubListItem)`
  && {
    background-color: transparent;
    transition: background-color 0.4s;
    & * {
      color: #2096f3 !important;
    }
    :hover {
      background-color: #f0f7ff;
    }
  }
` as React.FC<ListItemProps>

export const PositiveListItem = styled(SubListItem)`
  && {
    background-color: transparent;
    transition: background-color 0.4s;
    & * {
      color: #008a5f !important;
    }
    :hover {
      background-color: #e6f4f1;
    }
  }
` as React.FC<ListItemProps>

export const NegativeListItem = styled(SubListItem)`
  && {
    background-color: transparent;
    transition: background-color 0.4s;
    & * {
      color: #d62067 !important;
    }
    :hover {
      background-color: #fddde9;
    }
  }
` as React.FC<ListItemProps>

export const SecondaryListItemText = styled(ListItemText)`
  && {
    flex-grow: 0;
  }
` as React.FC<ListItemTextProps>
