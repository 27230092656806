import { UserValidatorMap, createUser } from "./user"
import { FilterValidatorMap, createFilter } from "./filter"
import { TagValidatorMap, createTag } from "./tag"
import {
  RentActionValidatorMap,
  createRentAction,
  RentConfirmationValidatorMap,
  createRentConfirmation,
} from "./rentAction"
import { CategoryValidatorMap, createCategory } from "./category"
import { ItemValidatorMap, createItem, ItemFileValidatorMap, createItemFile } from "./item"
import { ItemRequestValidatorMap, createItemRequest } from "./itemRequest"

export const ValidatorMaps = {
  filters: FilterValidatorMap,
  rentRequests: RentActionValidatorMap,
  tags: TagValidatorMap,
  users: UserValidatorMap,
  categories: CategoryValidatorMap,
  items: ItemValidatorMap,
  itemRequests: ItemRequestValidatorMap,
}

export type ValidatorMapType = keyof typeof ValidatorMaps

export function getConstructorFromValidatorMap(model: Casted<any, Validator>): (v: any, key?: string) => Maybe<any> {
  switch (model) {
    case UserValidatorMap:
      return createUser
    case RentActionValidatorMap:
      return createRentAction
    case RentConfirmationValidatorMap:
      return createRentConfirmation
    case FilterValidatorMap:
      return createFilter
    case TagValidatorMap:
      return createTag
    case ItemValidatorMap:
      return createItem
    case ItemFileValidatorMap:
      return createItemFile
    case CategoryValidatorMap:
      return createCategory
    case ItemRequestValidatorMap:
      return createItemRequest
    default:
      throw "No converter for this model"
  }
}
