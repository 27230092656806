import * as React from "react"
import { connect } from "react-redux"
import { match } from "react-router-dom"
import * as router from "@/router"

import * as selectors from "@/store/selectors/common"
import { MapState, MapDispatch } from "@/store"
import { Spinner } from "@/components/Spinner"
import { BaseDialog } from "@/components/BaseDialog"
import { Chip, Avatar, Button, DialogContentText, FormGroup } from "@material-ui/core"
import styled from "styled-components"
import { FormGroupProps } from "@material-ui/core/FormGroup"

type Props = {
  match: match<{ id: string }>
} & StateFromProps &
  DispatchFromProps

interface StateFromProps {
  itemRequest: ItemRequest
  author: User
  isAdmin: boolean
  currentUser: User
  caretaker?: User
}

interface DispatchFromProps {
  goToEdit: (id: string) => void
  goBack: () => void
  openInNewWindow: (link: string) => void
}

const StyledFormGroup = styled(FormGroup)`
  & > * {
    margin: 1em 0 !important;
  }
` as React.FC<FormGroupProps>

const StyledFormRow = styled(FormGroup)`
  & > * {
    margin-right: 1em !important;
  }
` as React.FC<FormGroupProps>

const ItemRequestDetailsComponent: React.FC<Props> = ({
  itemRequest,
  author,
  goToEdit,
  goBack,
  openInNewWindow,
  isAdmin,
  currentUser,
  caretaker,
}) => {
  const dataFetched = itemRequest && author && currentUser
  const editButtonAllowed = dataFetched && ((itemRequest.status === "NEW" && currentUser.id === author.id) || isAdmin)
  const actions = (
    <>
      {editButtonAllowed && <Button onClick={goToEdit.bind(null, itemRequest.id)}>Edit</Button>}
      {dataFetched && itemRequest.link && <Button onClick={openInNewWindow.bind(null, itemRequest.link)}>Link</Button>}
      <Button onClick={goBack}>Cancel</Button>
    </>
  )
  return (
    <BaseDialog fixedHeight="50vh" open title="Item request details" actions={actions}>
      {!dataFetched ? (
        <Spinner centered />
      ) : (
        <StyledFormGroup>
          <DialogContentText>Item name: {itemRequest.itemName}</DialogContentText>
          {itemRequest.comments && <DialogContentText>Comments: {itemRequest.comments}</DialogContentText>}
          <StyledFormRow row>
            <DialogContentText>Requested by:</DialogContentText>
            <Chip avatar={<Avatar src={author.photoURL} />} label={author.displayName} />
          </StyledFormRow>
          <StyledFormRow row>
            <DialogContentText>Caretaker: </DialogContentText>
            {caretaker ? (
              <Chip avatar={<Avatar src={caretaker.photoURL} />} label={caretaker.displayName} />
            ) : (
              <Chip label="None yet" />
            )}
          </StyledFormRow>
          <StyledFormRow row>
            <DialogContentText>Status: </DialogContentText>
            <Chip label={itemRequest.status} />
          </StyledFormRow>
        </StyledFormGroup>
      )}
    </BaseDialog>
  )
}

const mapStateToProps: MapState<StateFromProps, Props> = (state, props) => ({
  itemRequest: selectors.itemRequestById(state, props.match.params.id),
  author: selectors.getItemRequestAuthor(state, props.match.params.id),
  caretaker: selectors.getItemRequestCaretaker(state, props.match.params.id),
  isAdmin: selectors.isAdmin(state),
  currentUser: selectors.getCurrentUser(state),
})

const mapDispatchToProps: MapDispatch<DispatchFromProps> = () => ({
  goToEdit: id => router.navigate(`${router.ITEM_REQUEST}/${id}/edit`),
  goBack: () => router.goBack(),
  openInNewWindow: link => {
    window.open(link)
  },
})

export const ItemRequestDetails = connect<StateFromProps, DispatchFromProps>(
  mapStateToProps,
  mapDispatchToProps
)(ItemRequestDetailsComponent)
