import * as React from "react"
import { Avatar, Badge, Popover, IconButton, Hidden } from "@material-ui/core"
import styled from "styled-components"
import { IconButtonProps } from "@material-ui/core/IconButton"
import { SelfClosingMenuItem } from "../components/SelfClosingMenuItem"
import { MapState, MapDispatch } from "@/store"
import { getNewItemRequests, getCurrentUserRentConfirmationsNotifications } from "@/store/selectors/common"
import { keys } from "@/utils"
import { appActions, UIState } from "@/store/actions"
import { logout } from "@/services/firebase/auth"
import { connect } from "react-redux"
import { BadgeProps } from "@material-ui/core/Badge"

interface PropsFromStore {
  user: User
  itemRequestsLength: number
  rentRequestsLength: number
  rentConfirmationsLength: number
}

interface Actions {
  onLogoutClick: () => void
  onMyItemsClick: (filter: string) => void
  onReviewRequestsClick: () => void
  onReviewConfirmations: () => void
  onReviewItemRequestsClick: (asAdmin: boolean) => void
}

const StyledBadge = styled(Badge)`
  & > span {
    background-color: #9823ff;
    color: #fff;
  }
` as React.FC<BadgeProps>

const ImportantSelfClosingMenuItem = styled(SelfClosingMenuItem)`
  color: #9823ff !important;
`

const AvatarButton = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
    padding: 0;
    margin-left: 10px;
    margin-right: 0px;
  }
` as React.FC<IconButtonProps>

export const AvatarMenuComponent: React.FC<PropsFromStore & Actions> = ({
  user,
  itemRequestsLength,
  rentRequestsLength,
  rentConfirmationsLength,
  onLogoutClick,
  onMyItemsClick,
  onReviewRequestsClick,
  onReviewConfirmations,
  onReviewItemRequestsClick,
}) => {
  const [anchor, setAnchor] = React.useState<HTMLElement>(null)
  const close = () => setAnchor(null)
  const notifications =
    (user.isAdmin && (rentRequestsLength || itemRequestsLength || rentConfirmationsLength)) || rentConfirmationsLength
  const notificationsNumber = user.isAdmin
    ? rentRequestsLength + itemRequestsLength + rentConfirmationsLength
    : rentConfirmationsLength
  return (
    <>
      <StyledBadge badgeContent={notificationsNumber} invisible={!notifications}>
        <AvatarButton data-cy="avatar-menu-action-open" onClick={e => setAnchor(e.currentTarget)}>
          <Avatar src={user.photoURL} />
        </AvatarButton>
      </StyledBadge>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        {user.isAdmin && Boolean(rentRequestsLength) && (
          <ImportantSelfClosingMenuItem onClick={onReviewRequestsClick} closeFunction={close}>
            Borrow/Return Requests
          </ImportantSelfClosingMenuItem>
        )}
        {user.isAdmin && Boolean(itemRequestsLength) && (
          <ImportantSelfClosingMenuItem
            data-cy="new-item-request-menu-option"
            onClick={() => onReviewItemRequestsClick(true)}
            closeFunction={close}>
            New item requests
          </ImportantSelfClosingMenuItem>
        )}
        {Boolean(rentConfirmationsLength) && (
          <ImportantSelfClosingMenuItem
            data-cy="avatar-menu-action-confirmations"
            onClick={onReviewConfirmations}
            closeFunction={close}>
            Handovers
          </ImportantSelfClosingMenuItem>
        )}
        <Hidden smUp>
          <SelfClosingMenuItem onClick={() => onReviewItemRequestsClick(false)} closeFunction={close}>
            My Item Requests
          </SelfClosingMenuItem>
          <SelfClosingMenuItem onClick={() => onMyItemsClick("@" + user.displayName)} closeFunction={close}>
            My items
          </SelfClosingMenuItem>
        </Hidden>
        <SelfClosingMenuItem onClick={() => onLogoutClick()} closeFunction={close}>
          Sign out
        </SelfClosingMenuItem>
      </Popover>
    </>
  )
}

const mapStateToProps: MapState<PropsFromStore> = state => {
  const itemRequestsLength = getNewItemRequests(state).length
  const rentConfirmationsLength = getCurrentUserRentConfirmationsNotifications(state).length
  const rentRequestsLength = keys(state.data.rentRequestsById).length
  return {
    user: state.auth,
    itemRequestsLength,
    rentConfirmationsLength,
    rentRequestsLength,
  }
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    onLogoutClick: () => dispatch(logout()),
    onMyItemsClick: (v: string) => dispatch(appActions.filter(v)),
    onReviewRequestsClick: () => dispatch(appActions.changeState(UIState.ReviewingRequests)),
    onReviewConfirmations: () => dispatch(appActions.changeState(UIState.ReviewingConfirmations)),
    onReviewItemRequestsClick: (asAdmin: boolean) =>
      dispatch(appActions.changeState(asAdmin ? UIState.ReviewingItemRequestsAdmin : UIState.ReviewingItemRequests)),
  }
}

export const AvatarMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(AvatarMenuComponent)
