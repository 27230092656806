import * as React from "react"
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem"

export const SelfClosingMenuItem: React.FC<MenuItemProps & { closeFunction: () => void }> = ({
  onClick,
  closeFunction,
  ...props
}) => {
  return (
    <MenuItem
      onClick={() => {
        closeFunction()
        onClick(null)
      }}
      {...props}
    />
  )
}
