import { validate } from "./validators"
import { keys } from "@/utils"

export const ItemRequestValidatorMap: Casted<ItemRequest, Validator> = {
  id: "text",
  userId: "text",
  categoryId: "text",
  itemName: "text",
  comments: "text",
  link: "url",
  status: "text",
  rejectReason: "text",
  caretakerId: "text",
  createDate: "date",
}

const requiredKeys: Array<keyof ItemRequest> = ["userId", "itemName"]

export const itemRequestKeys: Array<keyof ItemRequest> = keys(ItemRequestValidatorMap) as any

export const createItemRequest = (valueToCheck: any, id?: string): Maybe<ItemRequest> => {
  const criticalErrors = {}
  const value: ItemRequest = itemRequestKeys.reduce(
    (res, key) => {
      const result = validate<ItemRequest, typeof key>(ItemRequestValidatorMap[key], valueToCheck[key])
      if (requiredKeys.includes(key) && !result.value) {
        result.error = "required"
      }
      if (!result.error) {
        res[key] = result.value
      } else {
        if (requiredKeys.includes(key)) {
          criticalErrors[key] = "required"
        }
      }
      return res
    },
    {} as ItemRequest
  )
  return keys(criticalErrors).length ? { error: criticalErrors } : { value }
}

const sortingOrder: Array<ItemRequestStatus> = ["NEW", "IN PROGRESS", "REJECTED", "RESOLVED"]

export const mapItemRequestsToViews = (itemRequests: ItemRequest[], usersById: SMap<User>): ItemRequestView[] => {
  return itemRequests
    .sort((a, b) => (sortingOrder.indexOf(a.status) > sortingOrder.indexOf(b.status) ? 1 : -1))
    .reduce((current: ItemRequestView[], request: ItemRequest) => {
      const author = usersById[request.userId]
      const caretaker = usersById[request.caretakerId]
      if (author) {
        const label = `[${request.status}] ${author.displayName} wants ${request.itemName}`
        let subLabel = ""
        if (caretaker) {
          subLabel = `Request handled by by ${caretaker.displayName}`
        }
        return [
          ...current,
          {
            ...request,
            author,
            caretaker,
            label,
            subLabel,
          },
        ]
      }
      return current
    }, [])
}

export const statuses: Array<ItemRequest["status"]> = ["NEW", "IN PROGRESS", "REJECTED", "RESOLVED"]
