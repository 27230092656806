import { RentActionValidatorMap } from "./rentAction"
import { validate } from "./validators"
import { keys, forEachInObject } from "@/utils"
import { store } from "@/store"
import { ORGANIZATION } from "@/consts"
export const UserValidatorMap: Casted<User, Validator> = {
  id: "text",
  uid: "text",
  history: {
    "*": RentActionValidatorMap, // this represents actual state in database
  },
  photoURL: "url",
  photoUrl: "url",
  displayName: "text",
  isAdmin: "boolean",
  email: "email",
  queue: {
    "*": RentActionValidatorMap,
  },
  borrowed: {
    "*": RentActionValidatorMap,
  },
}

const requiredKeys: Array<keyof User> = ["displayName", "email"]

export const userKeys: Array<keyof User> = keys(UserValidatorMap) as any

export const createUser = (valueToCheck: any): Maybe<User> => {
  const criticalErrors = []

  const value: User = userKeys.reduce(
    (res, key) => {
      const { value: validatedValue, error } = validate<User, typeof key>(UserValidatorMap[key], valueToCheck[key])
      if (!error) {
        res[key] = validatedValue
      } else {
        if (requiredKeys.includes(key)) {
          criticalErrors.push(`A key ${key} is required, but validator returned error: ${error}`)
        }
      }
      return res
    },
    {} as User
  )
  return criticalErrors.length ? { error: criticalErrors } : { value }
}

export function parseUser(user: Partial<User>): User {
  if (!user.displayName) {
    const emailSplit = user.email.replace(ORGANIZATION, "").split(".")
    let displayName = emailSplit[0].charAt(0).toUpperCase() + emailSplit[0].slice(1).toLowerCase()
    if (emailSplit.length > 1)
      displayName = `${displayName} ${emailSplit[1].charAt(0).toUpperCase()}${emailSplit[1].slice(1).toLowerCase()}`
    user.displayName = displayName
  }
  const parsedUser: User = {
    uid: user.uid || "",
    displayName: user.displayName || "",
    email: user.email || "",
    photoURL: user.photoURL || "",
    history: user.history || {},
    borrowed: user.borrowed || {},
    queue: user.queue || {},
    id: user.email.toLowerCase().replace(/\./g, "=="),
    isAdmin: user.isAdmin || false,
  }
  if (user.photoUrl) {
    parsedUser.photoUrl = user.photoUrl
  }
  return parsedUser
}

export const isInStore = (user: User) => {
  return !!store.getState().data.usersById[user.id]
}

export const isBorrowingAnything = (user: User) => {
  const { itemsById } = store.getState().data
  return Object.keys(itemsById).find(key => {
    const i = itemsById[key]
    return i.borrowedBy && i.borrowedBy.userId === user.id
  })
}

export function getAdminEmails() {
  const to: SMap<string> = {}
  forEachInObject(store.getState().data.usersById, (id, user) => {
    if (user.isAdmin) {
      to[id] = user.email
    }
  })
  return to
}

export function getUsersFromQueue(queue: SMap<RentAction>) {
  const to: SMap<string> = {}
  const { usersById } = store.getState().data

  forEachInObject(queue, id => {
    const user = usersById[id]
    if (user) {
      to[id] = user.email
    }
  })
  return to
}

export const getCurrentUser = () => {
  return store.getState().auth
}

export const updateCurrentUserHistory = (itemId: string, borrowed: RentAction) => {
  const u = store.getState().auth
  if (!u.history) u.history = {}
  u.history[borrowed.id] = borrowed
  if (!u.borrowed) u.borrowed = {}
  u.borrowed[itemId] = borrowed
  if (u.queue && u.queue[itemId]) delete u.queue[itemId]
  return u
}

export const dequeueUser = (userId: string, itemId: string) => {
  const state = store.getState()
  const user = state.data.usersById[userId]
  const item = state.data.itemsById[itemId]

  if (!user.queue || !user.queue[item.id]) return
  delete user.queue[item.id]
  return user
}
