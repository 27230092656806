import * as React from "react"
import { CircularProgress } from "@material-ui/core"

interface Props {
  centered: boolean
}

export const Spinner: React.FC<Props> = ({ centered }) => {
  return (
    <CircularProgress
      size={60}
      thickness={7}
      style={centered && { marginTop: "70px", position: "absolute", left: "calc(50% - 20px)" }}
    />
  )
}
