import { extend } from "@/utils"

import { AppActions, UIState, SortBy, FilterItemRequests, DateRange } from "../actions"

export interface AppState {
  version: string
  error: Error
  userIdFilter: string
  state: UIState
  selectedCategoryId: string
  selectedItem: Item
  selectedTags: string[]
  itemFilter: string
  sortBy: SortBy
  selectedRequestId: string
  snackbarOpen: boolean
  dateRange: DateRange
  filterItemRequests: FilterItemRequests
}

const initialState: AppState = {
  version: "0.8.0",
  error: null,
  userIdFilter: "",
  selectedCategoryId: null,
  selectedItem: null,
  itemFilter: "",
  state: UIState.Other,
  selectedTags: [],
  sortBy: SortBy.Available,
  snackbarOpen: true,
  selectedRequestId: null,
  dateRange: {},
  filterItemRequests: {
    statuses: ["NEW"],
    date: {},
  },
}

export const reducer: Reducer<AppState, AppActions> = (state = initialState, action: AppActions) => {
  const ext = extend(state)
  switch (action.type) {
    case "APP/error":
      return ext({ error: action.payload })
    case "APP/selectCategory":
      return ext({ selectedCategoryId: action.payload })
    case "APP/selectItem":
      return ext({ selectedItem: action.payload })
    case "APP/selectRequest":
      return ext({ selectedRequestId: action.payload })
    case "APP/selectTags":
      return ext({ selectedTags: action.payload })
    case "APP/filter":
      return ext({ itemFilter: action.payload })
    case "APP/snackbar":
      return ext({ snackbarOpen: action.payload })
    case "APP/filterByUserId":
      return ext({ userIdFilter: action.payload })
    case "APP/sortBy":
      return ext({ sortBy: action.payload })
    case "APP/changeState":
      return ext({ state: action.payload })
    case "APP/filterItemRequests":
      return ext({ filterItemRequests: action.payload })
    case "APP/dateRange":
      return ext({ dateRange: action.payload })
    default:
      return state
  }
}
