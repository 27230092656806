import * as React from "react"
import { connect } from "react-redux"
import DetailsIcon from "@material-ui/icons/OpenInNew"
import CreateIcon from "@material-ui/icons/AddBox"
import EditIcon from "@material-ui/icons/Edit"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import * as moment from "moment"

import { getFilteredItemRequestsByUser, getFilteredItemRequests, getItemRequestFilters } from "@/store/selectors/common"
import { MapState, MapDispatch } from "@/store"
import * as router from "@/router"
import { keys, mapObjectIntoQueryString } from "@/utils"
import { mapItemRequestsToViews, statuses } from "@/models/itemRequest"
import { appActions, FilterItemRequests } from "@/store/actions"
import { AppBar } from "@/components/AppBar"
import { Button, Avatar, ListItem, List, ListItemIcon, ListItemText, ListItemAvatar, Collapse } from "@material-ui/core"
import { Spinner } from "@/components/Spinner"
import { Drawer, DrawerSectionListItemText } from "@/components/Drawer"
import styled from "styled-components"
import { Stroke } from "@/assets/Stroke"
import { DatePicker, DatePickerProps } from "material-ui-pickers"
import { DATE_FORMAT } from "@/consts"
import { AppBarContainerRight } from "@/components/ActionsPanel"
import { SubListItem, NeutralListItem, SecondaryListItemText } from "@/components/ListItems"
import { AppBarTitle } from "@/components/AppBarTitle"

interface StoreProps {
  itemRequestViews: ItemRequestView[]
  itemRequestsCount: number
  user: User
  renderLoader: boolean
  itemRequestFilters: FilterItemRequests
}

interface Actions {
  navigateHome: () => void
  addNew: () => void
  goToItemCreation: (params: object) => void
  edit: (id: string) => void
  details: (id: string) => void
  filterItemRequests: typeof appActions.filterItemRequests
}

const ItemRequestsContainer = styled.div`
  overflow: scroll;
  position: relative;
  background-color: #f6f6f6;
  height: calc(100vh - 80px);
  margin-left: ${({ theme }) => theme.drawer.width}px;
  margin-top: 80px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 0;
  }
`

const StyledDatePicker = styled(DatePicker)<DatePickerProps>`
  & input {
    color: ${({ value }) => (value ? "#007cf7" : "#919dac")};
    font-weight: ${({ value }) => (value ? "bold" : "normal")};
  }
`

const ItemRequestPageComponent: React.FC<StoreProps & Actions> = ({
  itemRequestViews,
  user,
  navigateHome,
  goToItemCreation,
  addNew,
  edit,
  details,
  renderLoader,
  filterItemRequests,
  itemRequestFilters,
}) => {
  const [collapsedViewId, setCollapsedViewId] = React.useState<string>(null)
  const title = user.isAdmin ? "Order Requests" : "My Order Requests"
  const { statuses: selectedStatuses, date: dateRange } = itemRequestFilters

  const updateDateRange = (date: moment.Moment, key: "to" | "from") => {
    const newDateRange = { ...dateRange, [key]: date ? date.format(DATE_FORMAT) : null }
    filterItemRequests({ statuses: selectedStatuses, date: newDateRange })
  }
  return (
    <div>
      <AppBar short>
        <AppBarTitle>{title}</AppBarTitle>
        <AppBarContainerRight>
          <Button onClick={addNew} color="primary" variant="contained">
            Request an item
          </Button>
        </AppBarContainerRight>
      </AppBar>
      <Drawer
        onLogoClick={navigateHome}
        open={false}
        sections={[
          {
            title: "STATUS",
            renderSectionItems: statuses.map(status => {
              const isSelected = selectedStatuses.includes(status)
              return (
                <ListItem
                  button
                  key={status}
                  onClick={() => {
                    const newSelectedStatuses = isSelected
                      ? selectedStatuses.filter(s => s !== status)
                      : [...selectedStatuses, status]
                    filterItemRequests({
                      statuses: newSelectedStatuses,
                      date: dateRange,
                    })
                  }}>
                  <DrawerSectionListItemText selected={isSelected}>{status}</DrawerSectionListItemText>
                  {isSelected && <Stroke />}
                </ListItem>
              )
            }),
          },
          {
            title: "DATE RANGE",
            renderSectionItems: [
              <ListItem key="from">
                <StyledDatePicker
                  label="From date"
                  value={dateRange.from || null}
                  emptyLabel="Unspecified"
                  clearable={true}
                  clearLabel="Clear"
                  onChange={date => updateDateRange(date, "from")}
                />
              </ListItem>,
              <ListItem key="to">
                <StyledDatePicker
                  label="To date"
                  value={dateRange.to || null}
                  emptyLabel="Unspecified"
                  clearable={true}
                  clearLabel="Clear"
                  onChange={date => updateDateRange(date, "to")}
                />
              </ListItem>,
            ],
          },
        ]}
      />
      <ItemRequestsContainer data-cy="new-device-requests-list">
        {renderLoader && <Spinner centered />}
        <List>
          {itemRequestViews.map(view => {
            const buttons = []
            const isOpen = collapsedViewId === view.id
            if (!user.isAdmin) {
              view.status === "NEW"
                ? buttons.push(
                    <SubListItem button key={`${view.id}_edit`} onClick={_ => edit(view.id)}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText primary="Edit" />
                    </SubListItem>
                  )
                : buttons.push(
                    <SubListItem button key={`${view.id}_details`} onClick={_ => details(view.id)}>
                      <ListItemIcon>
                        <DetailsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Details" />
                    </SubListItem>
                  )
            } else {
              buttons.push(
                <SubListItem
                  button
                  key={`${view.id}_edit`}
                  onClick={_ => edit(view.id)}
                  data-cy="request-item-list-edit-button">
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText primary="Edit" />
                </SubListItem>
              )

              buttons.push(
                <NeutralListItem
                  button
                  key={`${view.id}_create`}
                  onClick={_ =>
                    goToItemCreation({
                      itemName: view.itemName,
                      categoryId: view.categoryId,
                    })
                  }
                  data-cy="request-item-list-create-button">
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add as a new item" />
                </NeutralListItem>
              )
            }

            return (
              <React.Fragment key={view.id}>
                <ListItem
                  button
                  data-cy="request-item-list-collapse-button"
                  onClick={() => {
                    setCollapsedViewId(isOpen ? null : view.id)
                  }}>
                  <ListItemAvatar>
                    <Avatar src={view.author.photoURL} />
                  </ListItemAvatar>
                  <SecondaryListItemText
                    primary={moment(view.createDate).format("MMM DD")}
                    secondary={moment(view.createDate).format("YYYY")}
                  />
                  <ListItemText primary={view.label} secondary={view.subLabel} />
                  <ListItemIcon>{isOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
                </ListItem>
                <Collapse in={isOpen}>
                  <List>{buttons}</List>
                </Collapse>
              </React.Fragment>
            )
          })}
        </List>
      </ItemRequestsContainer>
    </div>
  )
}

const mapStateToProps: MapState<StoreProps> = state => {
  const user = state.auth || ({} as User)
  const { usersById } = state.data
  const itemRequestViews: ItemRequestView[] = mapItemRequestsToViews(
    user.isAdmin ? getFilteredItemRequests(state) : getFilteredItemRequestsByUser(state),
    usersById
  )
  const itemRequestsCount = itemRequestViews.length
  return {
    itemRequestViews,
    itemRequestsCount,
    user,
    renderLoader: keys(usersById).length === 0, // users load after itemRequests
    itemRequestFilters: getItemRequestFilters(state),
  }
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    navigateHome: () => router.navigate(router.APP),
    addNew: () => router.navigate(router.NEW_ITEM_REQUEST),
    goToItemCreation: params => router.navigate(`${router.APP}${mapObjectIntoQueryString(params)}`),
    edit: id => router.navigate(`${router.ITEM_REQUEST}/${id}/edit`),
    details: id => router.navigate(`${router.ITEM_REQUEST}/${id}`),
    filterItemRequests: values => dispatch(appActions.filterItemRequests(values)),
  }
}

export const ItemRequestPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemRequestPageComponent)
