import * as React from "react"
import { Popover, Button, IconButton, Hidden } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import SettingsIcon from "@material-ui/icons/Settings"
import AddIcon from "@material-ui/icons/Add"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import AssignmentIcon from "@material-ui/icons/Assignment"
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks"
import styled, { css } from "styled-components"
import { ButtonProps } from "@material-ui/core/Button"
import { IconButtonProps } from "@material-ui/core/IconButton"
import { SelfClosingMenuItem } from "./SelfClosingMenuItem"

interface Props {
  isAdmin: boolean
  onAddCategory: () => void
  onAddItem: () => void
  onManageTags: () => void
  onNewRequestClick: () => void
  onManageFilters: () => void
  onManageUsers: () => void
  onOpenArchivedItems: () => void
  onMyItemRequests: () => void
  onMyItems: () => void
  onItemRequests: () => void
  onToggleDrawerOpen: () => void
}

interface ActionButtonProps {
  cta?: boolean
  toLeft?: boolean
}

export const AppBarContainerLeft = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.drawer.width + 16}px;
  display: flex;
  height: 80px;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    left: 0;
  }
`

export const AppBarContainerRight = styled.div`
  position: absolute;
  right: 20px !important;
  display: flex;
  height: 80px;
  align-items: center;
`

const DrawerIconButton = styled(IconButton)`
  && {
    color: #ffffff;
  }
` as React.FC<IconButtonProps>

const ActionButton = styled(Button)`
  && {
    margin: 0 3px;
    background-color: #1e405d;
    box-shadow: none !important;
    text-transform: none;
    & > span > svg {
      margin-right: 5px;
    }
    &.admin {
      background-color: #315472;
      :hover {
        color: #fff;
        background-color: #007cf7;
      }
    }
  }
` as React.FC<ButtonProps & ActionButtonProps>

const CTAButton = styled(Button)`
  && {
    background-color: #ffffff;
    color: #060606;
    text-transform: none;
    transition: color 0.4s, background-color 0.4s;
    :hover {
      color: #fff;
      background-color: #007cf7;
    }
  }
` as React.FC<ButtonProps>

export const ActionsPanel: React.FC<Props> = ({
  children,
  isAdmin,
  onAddCategory,
  onAddItem,
  onManageTags,
  onNewRequestClick,
  onManageFilters,
  onManageUsers,
  onOpenArchivedItems,
  onMyItemRequests,
  onMyItems,
  onItemRequests,
  onToggleDrawerOpen,
}) => {
  const [anchor, setAnchor] = React.useState<HTMLElement>(null)
  const close = () => setAnchor(null)
  return (
    <>
      <AppBarContainerLeft>
        <Hidden mdUp>
          <DrawerIconButton aria-label="Open drawer" onClick={onToggleDrawerOpen}>
            <MenuIcon />
          </DrawerIconButton>
        </Hidden>
        <CTAButton onClick={onNewRequestClick} color="primary" variant="contained" data-cy="request-an-item-button">
          Order request
        </CTAButton>
      </AppBarContainerLeft>
      <AppBarContainerRight>
        <Hidden xsDown>
          <ActionButton
            data-cy="actions-panel-my-item-requests"
            color="secondary"
            variant="contained"
            onClick={onMyItemRequests}>
            <AccessTimeIcon fontSize="small" /> My Item Requests
          </ActionButton>
          <ActionButton data-cy="actions-panel-my-items" color="secondary" variant="contained" onClick={onMyItems}>
            <AssignmentIcon fontSize="small" /> My items
          </ActionButton>
        </Hidden>
        {isAdmin && (
          <>
            <Hidden smDown>
              <ActionButton
                data-cy="actions-panel-add-item"
                color="secondary"
                variant="contained"
                className="admin"
                onClick={onAddItem}>
                <AddIcon /> <Hidden mdDown>Add item</Hidden>
              </ActionButton>
              <ActionButton
                data-cy="actions-panel-item-requests"
                color="secondary"
                variant="contained"
                className="admin"
                onClick={onItemRequests}>
                <LibraryBooksIcon fontSize="small" /> <Hidden mdDown>Item requests</Hidden>
              </ActionButton>
            </Hidden>
            <ActionButton
              data-cy="actions-panel-manage"
              color="secondary"
              variant="contained"
              className="admin"
              onClick={e => setAnchor(e.currentTarget)}>
              <SettingsIcon fontSize="small" /> <Hidden mdDown>Manage</Hidden>
            </ActionButton>
            <Popover
              open={Boolean(anchor)}
              anchorEl={anchor}
              onClose={() => setAnchor(null)}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
              <SelfClosingMenuItem key="add_category" onClick={onAddCategory} closeFunction={close}>
                Add category
              </SelfClosingMenuItem>
              <SelfClosingMenuItem
                data-cy="actions-panel-manage-add-item"
                key="add_item"
                onClick={onAddItem}
                closeFunction={close}>
                Add item
              </SelfClosingMenuItem>
              <SelfClosingMenuItem key="manage_tags" onClick={onManageTags} closeFunction={close}>
                Manage tags
              </SelfClosingMenuItem>
              <SelfClosingMenuItem key="manage_filters" onClick={onManageFilters} closeFunction={close}>
                Manage filters
              </SelfClosingMenuItem>
              <SelfClosingMenuItem key="manage_users" onClick={onManageUsers} closeFunction={close}>
                Manage users
              </SelfClosingMenuItem>
              <SelfClosingMenuItem key="archived_items" onClick={onOpenArchivedItems} closeFunction={close}>
                Open archived items
              </SelfClosingMenuItem>
            </Popover>
          </>
        )}
        {children}
      </AppBarContainerRight>
    </>
  )
}
