import * as React from "react"
import Downshift, { Actions as DownshiftActions } from "downshift"
import styled from "styled-components"
import { TextField, Paper, MenuItem } from "@material-ui/core"
import { PaperProps } from "@material-ui/core/Paper"

interface Config {
  value: string
  label: string
}

interface Props {
  suggestions: object[]
  suggestionsConfig: Config
  label: string
  value?: string
  limit?: number
  fullWidth?: boolean
  unique?: boolean
  controlled?: boolean
  required?: boolean
  error?: string
  resetOnValueChanged?: boolean
  allowSelfTyped?: boolean
  onValueChanged: (value: string, label: string) => void
}

const StyledPaperContainer = styled.div`
  position: relative;
`

const StyledPaper = styled(Paper)`
  position: absolute;
  z-index: 200;
` as React.FC<PaperProps>

const extract = (config: Config) => (item: object) => {
  return { label: item[config.label], value: item[config.value] }
}

const uniqueSuggestions = (suggestions: object[], ext: ReturnType<typeof extract>) => {
  return [...new Set(suggestions.map(item => ext(item).label))].map(item =>
    suggestions.find(suggestion => ext(suggestion).label === item)
  )
}

const filterSuggestions = (
  value: string,
  suggestions: any[],
  ext: ReturnType<typeof extract>,
  limit: number = 5
): any[] => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length
  let count = 0

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < limit &&
          ext(suggestion)
            .label.toLowerCase()
            .includes(inputValue)
        if (keep) {
          count += 1
        }
        return keep
      })
}

export const Autocomplete: React.FC<Props> = ({
  allowSelfTyped,
  suggestions,
  suggestionsConfig: config,
  label,
  value,
  required,
  limit,
  fullWidth,
  unique,
  controlled,
  error,
  onValueChanged,
  resetOnValueChanged,
  ...props
}) => {
  const ext = extract(config)

  const onInputValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    select: DownshiftActions<any>["selectItem"],
    clear: DownshiftActions<any>["clearSelection"]
  ) => {
    if (allowSelfTyped) {
      const val = event.target.value
      onValueChanged(null, val)
      select({ id: val, name: val })
    } else {
      clear()
    }
  }
  return (
    <Downshift itemToString={item => (item ? ext(item).label : "")} inputValue={value || (controlled ? "" : undefined)}>
      {({ getInputProps, getItemProps, getMenuProps, inputValue, isOpen, reset, selectItem, clearSelection }) => {
        return (
          <div>
            <TextField
              {...props}
              required={required}
              fullWidth={fullWidth}
              error={Boolean(error)}
              label={error || label}
              InputProps={getInputProps({
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  onInputValueChange(event, selectItem, clearSelection),
              })}
            />
            <StyledPaperContainer {...getMenuProps()}>
              {isOpen ? (
                <StyledPaper square>
                  {filterSuggestions(
                    inputValue,
                    unique ? uniqueSuggestions(suggestions, ext) : suggestions,
                    ext,
                    limit
                  ).map(suggestion => (
                    <MenuItem
                      {...getItemProps({ item: ext(suggestion).label })}
                      key={ext(suggestion).value}
                      onClick={() => {
                        onValueChanged(ext(suggestion).value, ext(suggestion).label)
                        resetOnValueChanged ? reset() : selectItem(suggestion)
                      }}>
                      {ext(suggestion).label}
                    </MenuItem>
                  ))}
                </StyledPaper>
              ) : null}
            </StyledPaperContainer>
          </div>
        )
      }}
    </Downshift>
  )
}
