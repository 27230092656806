import * as React from "react"
import { connect } from "react-redux"
import { ListItem } from "@material-ui/core"
import { Redirect } from "react-router"

import * as router from "@/router"
import { Stroke } from "@/assets/Stroke"
import { Search } from "@/components/Search"
import { AppBar } from "@/components/AppBar"
import { AppBarTitle } from "@/components/AppBarTitle"
import { MapState, MapDispatch } from "@/store"
import { AppState } from "@/store/reducers/app"
import { AvatarMenu } from "@/containers/AvatarMenu"
import { ActionsPanel } from "@/components/ActionsPanel"
import { mapObjectToArray, keys, isEmpty } from "@/utils"
import { Drawer, DrawerSectionListItemText } from "@/components/Drawer"
import { appActions, SortBy, UIState, DateRange, sortingKeys } from "@/store/actions"
import {
  getFilteredCategories,
  getDateRange,
  getSnackbarOpen,
  getArchivedPagePathnameStatus,
} from "@/store/selectors/common"

import { ItemList } from "./ItemList"
import { TagsEditor } from "./TagsEditor"
import { ItemEditor } from "./ItemEditor"
import { UserEditor } from "./UserEditor"
import { ItemPreview } from "./ItemPreview"
import { FiltersEditor } from "./FiltersEditor"
import { CategoryDialog } from "./CategoryDialog"
import { RequestsEditor } from "./RentRequestsPreview"
import { ItemRequestPreview } from "./ItemRequestsPreview"
import { ConfirmationsPreview } from "./ConfirmationsPreview"
import { RentConfirmationSnackbar } from "@/containers/RentConfirmationSnackbar"

interface Actions {
  navigateHome: () => void
  navigateToLogin: () => void
  navigateToNewItemRequest: () => void
  navigateToArchivedItems: () => void
  clearUrl: () => void
  filterItems: (v: string) => void
  sortItems: (s: SortBy) => void
  onTagsChanged: (tids: string[]) => void
  selectCategory: (id: string) => void
  changeUIState: (s: UIState) => void
  dateRangeChanged: (dateRange: DateRange) => void
}
type StoreProps = {
  showSnackbar: boolean
  tagsById: SMap<Tag>
  filtersById: SMap<Filter>
  tags: Tag[]
  filters: Filter[]
  user: User
  categories: Category[]
  dateRange: DateRange
  archivedPage: boolean
} & AppState

type RouterProps = {
  location: any
}

interface UrlParams {
  item?: Partial<Item>
}

interface State {
  selectedCategoryId: string
  params: UrlParams
  drawerOpen: boolean
}

export class AppPageComponent extends React.Component<Actions & StoreProps & RouterProps, State> {
  state = {
    selectedCategoryId: null,
    params: {} as UrlParams,
    drawerOpen: false,
  }

  constructor(props: Actions & StoreProps & RouterProps) {
    super(props)
    const { location, changeUIState } = this.props
    const params = new URLSearchParams(location.search)
    const name = params.get("itemName")
    const categoryId = params.get("categoryId")
    if (name || categoryId) {
      this.state = { ...this.state, params: { item: { name, categoryId } } }
      changeUIState(UIState.AddingItem)
      this.props.clearUrl()
    }
  }

  componentDidUpdate() {
    if (this.props.state === UIState.Other && !isEmpty(this.state.params)) {
      this.setState({ params: {} as UrlParams })
    }
  }

  render() {
    const { selectedCategoryId, drawerOpen } = this.state
    const { user, showSnackbar, archivedPage, navigateHome } = this.props
    if (!user) return null
    if (archivedPage && !user.isAdmin) return <Redirect to={router.APP} />
    return (
      <>
        {this.renderDialog()}
        <AppBar withSnackbar={showSnackbar}>
          <RentConfirmationSnackbar />
          {archivedPage && <AppBarTitle>Archived Items</AppBarTitle>}
          {!archivedPage && (
            <ActionsPanel
              isAdmin={user.isAdmin}
              onAddCategory={() => this.menuAction(UIState.AddingCategory)}
              onAddItem={this.addNewItem}
              onManageTags={this.manageTags}
              onManageFilters={this.manageFilters}
              onManageUsers={this.editUser}
              onNewRequestClick={this.props.navigateToNewItemRequest}
              onOpenArchivedItems={this.props.navigateToArchivedItems}
              onMyItemRequests={this.getUserItemRequests}
              onMyItems={this.getUserItems}
              onItemRequests={this.getItemRequests}
              onToggleDrawerOpen={this.toggleDrawerOpen}>
              <AvatarMenu />
            </ActionsPanel>
          )}
          <Search
            isAdmin={user.isAdmin}
            value={this.props.itemFilter}
            tags={this.props.tags}
            filters={this.props.filters}
            dateRange={this.props.dateRange}
            onFilterSelect={this.filterItemsByFilter}
            onTagSelect={this.filterItemsByTag}
            onValueChanged={this.props.filterItems}
            onDateRangeChanged={this.props.dateRangeChanged}
          />
        </AppBar>
        <Drawer
          onLogoClick={navigateHome}
          withSnackbar={showSnackbar}
          open={drawerOpen}
          onClose={this.toggleDrawerOpen}
          sections={[
            {
              title: "CATEGORIES",
              renderSectionItems: [
                <ListItem key="category_all" button onClick={_ => this.delaySelectCategory(null)}>
                  <DrawerSectionListItemText selected={selectedCategoryId === null}>All</DrawerSectionListItemText>
                </ListItem>,
                ...this.props.categories.map((category, index) => (
                  <ListItem button key={`category_${index}`} onClick={() => this.delaySelectCategory(category.id)}>
                    <DrawerSectionListItemText selected={selectedCategoryId === category.id}>
                      {category.name}
                    </DrawerSectionListItemText>
                  </ListItem>
                )),
              ],
            },
            {
              title: "ORDER BY",
              renderSectionItems: keys(sortingKeys).map(key => (
                <ListItem button key={key} onClick={() => this.props.sortItems(key)}>
                  <DrawerSectionListItemText selected={this.props.sortBy.toString() === key.toString()}>
                    {sortingKeys[key]}
                  </DrawerSectionListItemText>
                  {this.props.sortBy.toString() === key.toString() && <Stroke />}
                </ListItem>
              )),
            },
          ]}
        />
        <ItemList useArchivedItems={archivedPage} />
      </>
    )
  }

  private renderDialog = () => {
    switch (this.props.state) {
      case UIState.AddingCategory:
        return <CategoryDialog />
      case UIState.AddingItem:
        return <ItemEditor selectedItem={this.props.selectedItem || this.state.params.item} />
      case UIState.ManageTags:
        return <TagsEditor />
      case UIState.ManageFilters:
        return <FiltersEditor />
      case UIState.EditingUser:
        return <UserEditor />
      case UIState.PreviewingItem:
        return <ItemPreview archivedItem={this.props.archivedPage} />
      case UIState.ReviewingRequests:
        return <RequestsEditor />
      case UIState.ReviewingItemRequests:
        return <ItemRequestPreview />
      case UIState.ReviewingConfirmations:
        return <ConfirmationsPreview />
      case UIState.ReviewingItemRequestsAdmin:
        return <ItemRequestPreview asAdmin={true} />
      default:
        return null
    }
  }

  private delaySelectCategory = (id: string | null) => {
    this.setState({ selectedCategoryId: id }, () => {
      setTimeout(() => {
        this.props.selectCategory(id)
      }, 200)
    })
  }

  private toggleDrawerOpen = () => {
    this.setState(state => ({ drawerOpen: !state.drawerOpen }))
  }

  private addNewItem = () => {
    this.menuAction(UIState.AddingItem)
  }

  private manageTags = () => {
    this.menuAction(UIState.ManageTags)
  }

  private manageFilters = () => {
    this.menuAction(UIState.ManageFilters)
  }

  private editUser = () => {
    this.menuAction(UIState.EditingUser)
  }

  private menuAction = (s: UIState) => {
    this.props.changeUIState(s)
  }

  private getUserItems = () => {
    this.props.filterItems("@" + this.props.user.displayName)
  }

  private getUserItemRequests = () => {
    this.menuAction(UIState.ReviewingItemRequests)
  }

  private getItemRequests = () => {
    router.navigate(router.ITEM_REQUEST)
  }

  private filterItemsByFilter = (id: string) => {
    const filterName = this.props.filtersById[id].name
    const filterText = this.props.itemFilter
    const newFilterText = filterText ? `${filterText}, $${filterName}` : `$${filterName}`
    this.props.filterItems(newFilterText)
  }

  private filterItemsByTag = (id: string) => {
    const filterName = this.props.tagsById[id].name
    const filterText = this.props.itemFilter
    const newFilterText = filterText ? `${filterText}, #${filterName}` : `#${filterName}`
    this.props.filterItems(newFilterText)
  }
}

const mapStateToProps: MapState<StoreProps, RouterProps> = (state, ownProps) => {
  return {
    showSnackbar: getSnackbarOpen(state),
    user: state.auth,
    tagsById: state.data.tagsById,
    tags: mapObjectToArray(state.data.tagsById),
    filtersById: state.data.filtersById,
    filters: mapObjectToArray(state.data.filtersById),
    dateRange: getDateRange(state),
    categories: getFilteredCategories(state),
    archivedPage: getArchivedPagePathnameStatus(ownProps.location),
    ...state.app,
  }
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    navigateHome: () => router.navigate(router.APP),
    navigateToLogin: () => router.replaceTo(router.LOGIN),
    navigateToNewItemRequest: () => router.navigate(router.NEW_ITEM_REQUEST),
    navigateToArchivedItems: () => router.navigate(router.ARCHIVED_ITEMS),
    clearUrl: () => router.navigate(router.APP),
    onTagsChanged: (tids: string[]) => dispatch(appActions.selectTags(tids)),
    filterItems: (v: string) => dispatch(appActions.filter(v)),
    sortItems: (s: SortBy) => dispatch(appActions.sortBy(s)),
    selectCategory: (id: string) => dispatch(appActions.selectCategory(id)),
    changeUIState: (s: UIState) => dispatch(appActions.changeState(s)),
    dateRangeChanged: (dateRange: DateRange) => dispatch(appActions.dateRangeChanged(dateRange)),
  }
}

export const AppPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPageComponent)
