import "babel-polyfill"
import * as React from "react"
import { Switch, Route } from "react-router-dom"
import { createLocation } from "history"
import { history } from "@/store"
import { ConnectedRouter } from "connected-react-router"
import { mapObjectIntoQueryString } from "@/utils"
import { AppPage } from "./containers/AppPage"
import { LoginPage } from "./containers/LoginPage"
// import { RequestPrintPreview } from "./containers/RequestPrintPreview"
import { ItemRequestNew } from "./containers/ItemRequestNew"
import { ItemRequestEdit } from "./containers/ItemRequestEdit"
import { ItemRequestDetails } from "./containers/ItemRequestDetails"
import { ItemRequestPage } from "./containers/ItemRequestsPage"
import { ArchivedItemsPage } from "./containers/ArchivedItemsPage"

export const APP = "/"
export const LOGIN = "/login"
export const PRINT = "/print"
export const ITEM_REQUEST = "/itemrequests"
export const NEW_ITEM_REQUEST = `${ITEM_REQUEST}/new`
export const ARCHIVED_ITEMS = "/archived-items"

export function getRoutes() {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={LOGIN} component={LoginPage as any} />
        {/* <Route path={PRINT} component={RequestPrintPreview as any} /> */}

        <Route path={ITEM_REQUEST} component={ItemRequestPage} exact />
        <Route path={NEW_ITEM_REQUEST} component={ItemRequestNew} />
        <Route path={`${ITEM_REQUEST}/:id/edit`} component={ItemRequestEdit} />
        <Route path={`${ITEM_REQUEST}/:id`} component={ItemRequestDetails} exact />
        <Route path={ARCHIVED_ITEMS} component={ArchivedItemsPage} />
        <Route path={APP} component={AppPage} />
      </Switch>
    </ConnectedRouter>
  )
}

export function replaceTo(path: string) {
  history.replace(path)
}

export function navigate(path: string) {
  history.push(path)
}

export function goBack() {
  history.goBack()
}

export function openTab(path?: string) {
  return (window as any).open(path || "", "_blank")
}

export function moveToNewTab(pathname: string = "", state: object) {
  const newLocation = history.createHref(
    createLocation(
      {
        pathname,
        search: mapObjectIntoQueryString(state),
      },
      null,
      null,
      history.location
    )
  )
  openTab(newLocation)
}

export function isProtocolPrintPreview() {
  return history.location.pathname === PRINT
}

export function getInitialPath() {
  return history.location.pathname
}

// tslint:disable-next-line
(window as any).nav = navigate;
