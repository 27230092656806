import { validate } from "./validators"
import { RentActionValidatorMap } from "./rentAction"
import { keys, toDateString } from "@/utils"
import { store } from "@/store"
import { getCurrentUser } from "./user"

export const ItemFileValidatorMap: Casted<ItemFile, Validator> = {
  type: "text",
  file: "null",
  name: "text",
}

export const ItemValidatorMap: Casted<Item, Validator> = {
  id: "text",
  name: "text",
  archived: "boolean",
  available: "boolean",
  history: {
    "*": RentActionValidatorMap,
  },
  borrowedBy: RentActionValidatorMap,
  queue: {
    "*": RentActionValidatorMap,
  },
  files: {
    "*": ItemFileValidatorMap,
  },
  description: "text",
  author: "text",
  notes: "text",
  signature: "text",
  buyDate: "text",
  price: "price",
  categoryId: "text",
  tags: {
    "*": "boolean",
  },
}

const requiredKeys: Array<keyof Item> = ["name", "categoryId", "tags"]

export const itemKeys: Array<keyof Item> = keys(ItemValidatorMap) as any

export const createItem = (valueToCheck: any): Maybe<Item> => {
  const criticalErrors = []

  const value: Item = itemKeys.reduce(
    (res, key) => {
      const { value: validatedValue, error } = validate<Item, typeof key>(ItemValidatorMap[key], valueToCheck[key])
      if (!error) {
        res[key] = validatedValue
      } else {
        if (requiredKeys.includes(key)) {
          criticalErrors.push(`A key ${key} is required, but validator returned error: ${error}`)
        }
      }
      return res
    },
    {} as Item
  )
  return criticalErrors.length ? { error: criticalErrors } : { value }
}

export const createItemFile = (valueToCheck: any): Maybe<ItemFile> => {
  const value: ItemFile = keys(ItemFileValidatorMap).reduce(
    (res, key) => {
      const { value: validatedValue, error } = validate<ItemFile, typeof key>(
        ItemFileValidatorMap[key],
        valueToCheck[key]
      )
      if (!error) {
        res[key] = validatedValue
      }
      return res
    },
    {} as ItemFile
  )
  return { value }
}

export const assignItemToUser = (item: Item, userId: string, withConfirmation: boolean = true, date?: Date) => {
  const confirmation: RentConfirmation = {
    isConfirmedByUser: false,
    isConfirmedByAdmin: false,
    confirmationDocumentId: "",
  }
  const updateObject = {} as Partial<Item>
  updateObject.available = false
  updateObject.borrowedBy = {
    userId,
    itemId: item.id,
    borrowAcceptedBy: getCurrentUser().displayName,
    categoryId: item.categoryId,
    rentDate: toDateString(date || new Date()),
    returnDate: null as string,
    id: new Date().getTime().toString(),
  }
  if (withConfirmation) {
    updateObject.borrowedBy.confirmation = confirmation
  }
  if (item.queue) {
    item.queue[userId] = {} as RentAction
  }
  updateObject.queue = item.queue || {}
  updateObject.history = {}

  updateObject.history[updateObject.borrowedBy.id] = updateObject.borrowedBy
  return updateObject
}

export const dequeueItem = (userId: string, itemId: string) => {
  const state = store.getState()
  const user = state.data.usersById[userId]
  const item = state.data.itemsById[itemId]

  if (!item.queue || !item.queue[userId]) {
    return
  }
  const queue = { [user.id]: {} }
  return queue
}

export const makeItemReturned = (item: Item, date?: Date) => {
  const userId = item.borrowedBy.userId
  const updateObject = {} as Partial<Item>
  updateObject.available = true
  updateObject.history = {}
  updateObject.borrowedBy = item.borrowedBy
  updateObject.borrowedBy.returnDate = toDateString(date || new Date())
  updateObject.borrowedBy.returnAcceptedBy = getCurrentUser().displayName
  updateObject.history[item.borrowedBy.id] = item.borrowedBy
  updateObject.borrowedBy = {} as RentAction

  if (item.queue && item.queue[userId]) {
    updateObject.queue = item.queue
    updateObject.queue[userId] = {} as RentAction
  }
  return updateObject
}
