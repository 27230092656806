import { validate } from "./validators"
import { keys } from "@/utils"

export const TagValidatorMap: Casted<Tag, Validator> = {
  id: "text",
  name: "text",
}

const requiredKeys: Array<keyof Category> = ["name"]

export const tagKeys: Array<keyof Tag> = keys(TagValidatorMap) as any

export const createTag = (valueToCheck: any, id?: string): Maybe<Tag> => {
  const criticalErrors = []

  const value: Tag = tagKeys.reduce(
    (res, key) => {
      const { value: validatedValue, error } = validate<Tag, typeof key>(TagValidatorMap[key], valueToCheck[key])
      if (!error) {
        res[key] = validatedValue
      } else {
        if (requiredKeys.includes(key)) {
          criticalErrors.push(`A key ${key} is required, but validator returned error: ${error}`)
        }
      }
      return res
    },
    { id: valueToCheck[id] || id } as Tag
  )
  return criticalErrors.length ? { error: criticalErrors } : { value }
}
