import * as React from "react"
import { connect } from "react-redux"
import ActionDelete from "@material-ui/icons/Delete"
import ActionDone from "@material-ui/icons/Done"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import * as firebaseOperations from "@/services/firebase/operations"
import { mapObjectToArray, keys } from "@/utils"
import { appActions, UIState } from "@/store/actions"
import { MapState, MapDispatch } from "@/store"
import { mapRentRequestsToViews } from "@/models/rentAction"
import { BaseDialog } from "@/components/BaseDialog"
import { Button, ListItem, List, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Collapse } from "@material-ui/core"
import { StyledListItem, PositiveListItem, NegativeListItem } from "@/components/ListItems"

interface StoreProps {
  rentRequestViews: RentActionView[]
  reqLength: number
}

interface Actions {
  close: () => void
  accept: (req: RentAction) => void
  reject: (req: RentAction) => void
}

const RequestsEditorComponent: React.FC<StoreProps & Actions> = ({ rentRequestViews, accept, reject, close }) => {
  const [collapsedViewId, setCollapsedViewId] = React.useState<string>(null)

  return (
    <BaseDialog
      open
      title="Borrow & Return Requests"
      actions={
        <Button key="rent_requests_preview_close" onClick={close}>
          Close
        </Button>
      }>
      <List>
        {rentRequestViews.map(request => {
          const items = []
          const isOpen = collapsedViewId === request.id

          if (!request.item.borrowedBy || request.item.borrowedBy.userId === request.userId) {
            items.push(
              <PositiveListItem button key={request.id + "_accept"} onClick={() => accept(request)}>
                <ListItemIcon>
                  <ActionDone />
                </ListItemIcon>
                <ListItemText primary="Accept" />
              </PositiveListItem>
            )
          }

          items.push(
            <NegativeListItem button key={request.id + "_reject"} onClick={() => reject(request)}>
              <ListItemIcon>
                <ActionDelete />
              </ListItemIcon>
              <ListItemText primary="Reject" />
            </NegativeListItem>
          )
          return (
            <React.Fragment key={request.id}>
              <StyledListItem
                button
                onClick={() => {
                  setCollapsedViewId(isOpen ? null : request.id)
                }}>
                <ListItemAvatar>
                  <Avatar src={request.author.photoURL} />
                </ListItemAvatar>
                <ListItemText primary={request.label} secondary={request.subLabel} />
                <ListItemIcon>{isOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
              </StyledListItem>
              <Collapse in={isOpen}>
                <List>{items}</List>
              </Collapse>
            </React.Fragment>
          )
        })}
      </List>
    </BaseDialog>
  )
}

const mapStateToProps: MapState<StoreProps> = state => {
  const { usersById, rentRequestsById, itemsById } = state.data
  const rentRequestViews = mapRentRequestsToViews(mapObjectToArray(rentRequestsById), usersById, itemsById)
  const reqLength: number = keys(state.data.rentRequestsById).length
  return {
    rentRequestViews,
    reqLength,
  }
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    close: () => dispatch(appActions.changeState(UIState.Other)),
    accept: (req: RentAction) => firebaseOperations.acceptRequest(req),
    reject: (req: RentAction) => firebaseOperations.rejectRequest(req),
  }
}

export const RequestsEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsEditorComponent)
