import * as React from "react"
import { connect } from "react-redux"

import * as firebaseOperations from "@/services/firebase/operations"
import { mapObjectToArray, keys } from "@/utils"
import { appActions, UIState } from "@/store/actions"
import { MapState, MapDispatch } from "@/store"
import { DataState } from "@/store/reducers/data"
import { BaseDialog } from "@/components/BaseDialog"
import { Chips } from "@/components/Chips"
import { Button, TextField } from "@material-ui/core"

interface StoreProps extends Pick<DataState, "tagsById"> {
  tagsNumber: number
}

interface Actions {
  addTag: (tag: Tag) => void
  removeTag: (tag: Tag) => void
  close: () => void
}

const TagsEditorComponent: React.FC<StoreProps & Actions> = ({ tagsById, addTag, removeTag, close }) => {
  const [name, setName] = React.useState<string>("")
  return (
    <BaseDialog
      open
      title="Manage tags"
      actions={
        <>
          <Button
            disabled={!name}
            onClick={() => {
              addTag({ name } as Tag)
              setName("")
            }}>
            Add
          </Button>
          <Button onClick={close}>Close</Button>
        </>
      }>
      <Chips items={mapObjectToArray(tagsById)} onDelete={id => removeTag(tagsById[id])} />
      <TextField
        fullWidth={true}
        value={name}
        label="Name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
      />
    </BaseDialog>
  )
}

const mapStateToProps: MapState<StoreProps> = state => ({
  tagsById: state.data.tagsById,
  tagsNumber: keys(state.data.tagsById).length,
})

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    addTag: (tag: Tag) => firebaseOperations.addTag(tag),
    removeTag: (tag: Tag) => firebaseOperations.removeTag(tag),
    close: () => dispatch(appActions.changeState(UIState.Other)),
  }
}

export const TagsEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsEditorComponent)
