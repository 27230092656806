import * as React from "react"
import styled from "styled-components"

const IconContainer = styled.svg`
  width: 35px;
  padding-left: 17px;
`

export const Glass = () => (
  <IconContainer viewBox="0 0 21 21">
    <path
      d="M19.048 19.048l-3.334-3.334M9 1a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zM5 9a5.031 5.031 0 0 1 4-4"
      fill="none"
      fillRule="evenodd"
      stroke="#007CF7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </IconContainer>
)
