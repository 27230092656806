import * as React from "react"
import { connect } from "react-redux"
import styled, { css } from "styled-components"
import { AutoSizer, List } from "react-virtualized"
import { appActions, UIState, SortBy } from "@/store/actions"
import { MapState, MapDispatch } from "@/store"
import { Spinner } from "@/components/Spinner"
import { ListItem, ListItemText, Fab } from "@material-ui/core"
import { ListItemTextProps } from "@material-ui/core/ListItemText"
import SaveIcon from "@material-ui/icons/SaveAlt"
import { FabProps } from "@material-ui/core/Fab"
import { exportToCSV } from "@/services/files"
import { getFilteredItems, getSortedItems, isFilterHistory } from "@/store/selectors/filters"
import { SnackbarContainerProps } from "@/containers/RentConfirmationSnackbar"
import { getSnackbarOpen, areItemsLoaded } from "@/store/selectors/common"
import { SadGlass } from "@/components/SadGlass"
import { normalizeEmail } from "@/utils"

interface Props {
  useArchivedItems?: boolean
}

interface StoreProps {
  isLoaded: boolean
  items: Item[]
  user: User
  usersById: SMap<User>
  sortBy: SortBy
  requestsById: SMap<RentAction>
  query: string
  selectedTagIds: string[]
  snackbarOpen: boolean
  tagsById: SMap<Tag>
  selectedItemId: string
  isFilterHistory: boolean
}

interface Actions {
  selectItem: (item: Item) => void
  changeState: (s: UIState) => void
  exportItems: (items: Item[]) => void
}

interface StyledListItemTextProps {
  borrowed: boolean
  requested: boolean
}

const StyledListItemText = styled(({ requested, borrowed, ...rest }) => <ListItemText {...rest} />)`
  & > span,
  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${({ borrowed }) =>
    borrowed &&
    css`
      & > span {
        color: rgba(132, 132, 132, 0.5) !important;
      }
    `}
  ${({ requested }) =>
    requested &&
    css`
      & > span {
        color: #9823ff !important;
      }
    `}
` as React.FC<ListItemTextProps & StyledListItemTextProps>

const ItemsContainer = styled.div<SnackbarContainerProps>`
  overflow: scroll;
  position: relative;
  background-color: #f6f6f6;
  height: calc(
    100vh -
      ${({ withSnackbar, theme }) =>
        withSnackbar ? theme.appbar.heightWhole + theme.snackbar.height : theme.appbar.heightWhole}px
  );
  margin-left: ${({ theme }) => theme.drawer.width}px;
  transition: margin-top 0.3s;
  margin-top: ${({ withSnackbar, theme }) =>
    withSnackbar ? theme.appbar.heightWhole + theme.snackbar.height : theme.appbar.heightWhole}px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 0;
  }
`

const FloatingAction = styled(Fab)`
  position: absolute !important;
  bottom: 20px;
  right: 20px;
` as React.FC<FabProps>

class ItemListComponent extends React.Component<Props & StoreProps & Actions, any> {
  render() {
    const { items, exportItems, user, snackbarOpen, isLoaded } = this.props
    return (
      <ItemsContainer withSnackbar={snackbarOpen}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              height={height}
              noRowsRenderer={() => (isLoaded ? <SadGlass /> : <Spinner centered />)}
              rowCount={items.length}
              rowHeight={60}
              rowRenderer={this.renderItem}
              width={width}
            />
          )}
        </AutoSizer>
        {user.isAdmin && (
          <FloatingAction color="primary" onClick={() => exportItems(items)}>
            <SaveIcon />
          </FloatingAction>
        )}
      </ItemsContainer>
    )
  }

  private getHistoryByItem = (item: Item): RentAction => {
    const { isFilterHistory: isHistory, query } = this.props

    if (isHistory && item.history) {
      const userEmail = normalizeEmail(query.replace("history:", "").trim())
      const historyId = Object.keys(item.history).find(key => item.history[key].userId === userEmail)
      if (historyId) {
        return item.history[historyId]
      }
    }
  }

  private renderItem = ({ index, style }) => {
    const item = this.props.items[index]
    const ltags = this.getTags(item)
    const rsig = this.getLabel(item.signature)
    const isHistory = this.props.isFilterHistory
    const itemHistoryByUser = this.getHistoryByItem(item)

    const desc =
      rsig || ltags ? (
        <>
          {rsig} {ltags}
        </>
      ) : null
    return (
      <ListItem data-cy="item-list-list-item" key={item.id} button style={style} onClick={_ => this.selectItem(item)}>
        <>
          <StyledListItemText
            borrowed={Boolean(item.borrowedBy)}
            requested={Boolean(this.props.requestsById[item.id])}
            primary={this.getLabel(item.name)}
            secondary={desc}
          />
          {isHistory && itemHistoryByUser && (
            <div>
              {itemHistoryByUser.rentDate} - {itemHistoryByUser.returnDate ? itemHistoryByUser.returnDate : "now"}
            </div>
          )}
        </>
      </ListItem>
    )
  }

  private getTags = (c: Item) => {
    if (!c.tags) return []
    const tags = []
    for (const k in c.tags) {
      if (c.tags.hasOwnProperty(k) && this.props.tagsById[k]) {
        tags.push(this.getLabel(this.props.tagsById[k].name, "#"))
      }
    }
    return tags
  }

  private getLabel = (t: string, prefix = "", postfix = " ") => {
    if (!t) return null
    const q = (this.props.query || "").toLowerCase().replace(prefix, "")
    const start = (t || "").toLowerCase().indexOf(q)
    if (!q || !q.length || start === -1) {
      return <span key={t}>{prefix + t + postfix}</span>
    }
    const end = start + q.length,
      highlight = t.substring(start, end),
      before = t.substring(0, start),
      after = t.substring(end)
    return (
      <span key={t}>
        {prefix + before}
        <span key="1" className="highlight">
          {highlight}
        </span>
        {after + postfix}
      </span>
    )
  }

  private selectItem = (item: Item) => {
    this.props.selectItem(item)
    this.props.changeState(UIState.PreviewingItem)
  }
}

export function filterByArray<T>(items: T[], op: string, q: string, pred: (t: T, q: string) => boolean): T[] {
  const qs = q.split(op).map(s => s.trim())
  return qs.reduce((accumulator, currentValue, _, _2) => accumulator.filter(i => pred(i, currentValue)), items)
}

const mapStateToProps: MapState<StoreProps, Props> = (state, { useArchivedItems }) => {
  const user = state.auth
  const sortBy = state.app.sortBy
  const filterdItems = getFilteredItems(state)
  const sortedItems = getSortedItems(state, filterdItems)
  const items = sortedItems.filter(item => !!item.archived === !!useArchivedItems)
  const isLoaded = areItemsLoaded(state)
  return {
    isLoaded,
    items,
    user,
    sortBy, // workaround to ensure rerender of component - connect does shallow comparison on results of mapStateToProps
    snackbarOpen: getSnackbarOpen(state),
    selectedTagIds: state.app.selectedTags,
    query: state.app.itemFilter,
    requestsById: state.data.rentRequestsById,
    usersById: state.data.usersById,
    tagsById: state.data.tagsById,
    selectedItemId: state.app.selectedCategoryId,
    isFilterHistory: isFilterHistory(state),
  }
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    selectItem: item => dispatch(appActions.selectItem(item)),
    exportItems: items => dispatch(exportToCSV(items)),
    changeState: (s: UIState) => dispatch(appActions.changeState(s)),
  }
}

export const ItemList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemListComponent)
