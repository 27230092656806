import * as React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { MapState, MapDispatch } from "@/store"
import { getCurrentUserRentConfirmationsNotifications, getCurrentUser } from "@/store/selectors/common"
import { appActions, UIState } from "@/store/actions"
import { Button, IconButton } from "@material-ui/core"
import { ButtonProps } from "@material-ui/core/Button"
import ActionDelete from "@material-ui/icons/Clear"
import { IconProps } from "@material-ui/core/Icon"

interface SnackbarProps {
  open: boolean
}

interface PropsFromStore extends SnackbarProps {
  currentUser: User
  pendingConfirmationsNumber: number
}

interface ActionsFromStore {
  onOpen: () => void
  onClose: () => void
}

const SnackbarContainer = styled.div<SnackbarProps>`
  height: ${({ open, theme }) => (open ? theme.snackbar.height : 0)}px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #9823ff;
  display: flex;
  padding: 0 20px;
  align-items: center;
  transition: height 0.3s;
`

const SnackbarSubContainer = styled.div`
  height: ${({ theme }) => theme.snackbar.height}px;
  position: absolute;
  right: 20px;
  width: 220px;
  display: flex;
  align-items: center;
`

const SnackbarText = styled.h3`
  color: #fff;
`

const SnackbarButton = styled(Button)`
  && {
    background-color: #fff;
    color: #9823ff;
    margin: 0 10px;
    transition: color 0.3s, background-color 0.3s;
    :hover {
      color: #fff;
    }
  }
` as React.FC<ButtonProps>

const SnackbarX = styled(ActionDelete)`
  color: #fff !important;
` as React.FC<IconProps>

export interface SnackbarContainerProps {
  withSnackbar?: boolean
}

export const RentConfirmationSnackbarComponent: React.FC<PropsFromStore & ActionsFromStore> = ({
  open,
  currentUser,
  onOpen,
  onClose,
  pendingConfirmationsNumber,
}) => {
  const isOpen = open && pendingConfirmationsNumber > 0 && !currentUser.isAdmin
  return (
    <SnackbarContainer open={isOpen}>
      {isOpen && (
        <>
          <SnackbarText>{`You have ${pendingConfirmationsNumber} item${
            pendingConfirmationsNumber > 1 ? "s" : ""
          } that require${
            pendingConfirmationsNumber > 1 ? "" : "s"
          } your confirmation & acceptance to borrow`}</SnackbarText>
          <SnackbarSubContainer>
            <SnackbarButton onClick={onOpen}>Click to open</SnackbarButton>
            <IconButton onClick={onClose}>
              <SnackbarX color="disabled" />
            </IconButton>
          </SnackbarSubContainer>
        </>
      )}
    </SnackbarContainer>
  )
}

const mapStateToProps: MapState<PropsFromStore> = state => ({
  currentUser: getCurrentUser(state),
  open: state.app.snackbarOpen,
  pendingConfirmationsNumber: getCurrentUserRentConfirmationsNotifications(state).length,
})

const mapDispatchToProps: MapDispatch<ActionsFromStore> = dispatch => ({
  onOpen: () => dispatch(appActions.changeState(UIState.ReviewingConfirmations)),
  onClose: () => dispatch(appActions.openSnackbar(false)),
})

export const RentConfirmationSnackbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(RentConfirmationSnackbarComponent)
