import * as React from "react"
import styled from "styled-components"

const glass = require("../assets/sad-glass.png")

const Container = styled.div`
  position: absolute;
  left: calc(50% - 200px);
  top: calc(40% - 150px);
  max-width: 400px;
  opacity: 0.25;
`

const Img = styled.img`
  width: 400px;
`

const MainText = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 0;
  color: #173753;
`

const SubText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  text-align: center;
  margin-top: 6px;
  color: #173753;
`

export const SadGlass: React.FC<{}> = () => {
  return (
    <Container>
      <Img src={glass} alt="No results" />
      <MainText>
        We’re sorry, but there’s no items <br />
        for this parameters
      </MainText>
      <SubText>Please try to check your query or change category</SubText>
    </Container>
  )
}
