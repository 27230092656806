import * as React from "react"
import { connect } from "react-redux"
import * as firebaseOperations from "../services/firebase/operations"
import { DatePicker } from "material-ui-pickers"

import { mapObjectToArray, isEmpty } from "@/utils"

import { appActions, UIState } from "@/store/actions"
import { MapState, MapDispatch } from "@/store"
import { BaseDialog } from "@/components/BaseDialog"
import {
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  DialogContentText,
  Chip,
  Button,
  FormGroup,
  List,
} from "@material-ui/core"
import styled from "styled-components"
import { ConfirmationDialog } from "@/components/ConfirmationDialog"
import { Autocomplete } from "@/components/Autocomplete"
import moment = require("moment")
import { FormGroupProps } from "@material-ui/core/FormGroup"
import { ChipsContainer } from "@/components/Chips"
import { DialogContentTextProps } from "@material-ui/core/DialogContentText"
import { openTab } from "@/router"
import { ErrorButton } from "@/components/ErrorButton"

interface Props {
  archivedItem?: boolean
}

interface StoreProps {
  item: Item
  users: User[]
  usersById: SMap<User>
  categoriesById: SMap<Category>
  requestsById: SMap<RentAction>
  user: User
}

interface Actions {
  close: () => void
  archiveItem: (item: Item) => void
  unarchiveItem: (item: Item) => void
  requestBorrow: (userId: string, item: Item) => void
  cancelRequestBorrow: (item: Item) => void
  requestReturn: (userId: string, item: Item) => void
  editItem: () => void
  getItemFilePath: (file: ItemFile) => Promise<string>
  duplicate: (item: Item) => void
  queueMe: (item: Item) => void
  unqueue: (userId: string, item: Item) => void
  adminReturnItem: (item: Item, date?: Date) => void
  adminBorrowItem: (userId: string, item: Item, date?: Date) => void
}

interface State {
  userId: string
  date: Date
  isHistoryOpen: boolean
  isArchiveDialogOpen: boolean
}

const StyledFormGroup = styled(FormGroup)`
  width: 50%;
  & > * {
    margin-top: 2em !important;
  }
` as React.FC<FormGroupProps>

const StyledDialogText = styled(DialogContentText)`
  margin-left: 1em !important;
  margin-bottom: -1em !important;
` as React.FC<DialogContentTextProps>

class ItemPreviewComponent extends React.Component<Props & StoreProps & Actions, State> {
  state: State = {
    userId: null as string,
    date: new Date(),
    isHistoryOpen: false,
    isArchiveDialogOpen: false,
  }

  render() {
    const { archivedItem = false, item } = this.props
    if (!item) return null
    let user: User = null
    let label = ""
    const req = this.props.requestsById[item.id]
    if (item.borrowedBy) {
      user = this.props.usersById[item.borrowedBy.userId]
      label = `Borrowed this on ${item.borrowedBy.rentDate}`
    } else if (req) {
      user = this.props.usersById[req.userId]
      label = `Requested this on ${req.rentDate}`
    }

    return (
      <BaseDialog open title={item.name} actions={this.getUserButtons(archivedItem)}>
        {item.description && <DialogContentText>{`Description: ${item.description}`}</DialogContentText>}
        {item.signature && <DialogContentText>{`Serial number: ${item.signature}`}</DialogContentText>}
        {user && (
          <ListItem>
            <ListItemAvatar>
              <Avatar src={user.photoUrl || user.photoURL} />
            </ListItemAvatar>
            <ListItemText primary={user.displayName} secondary={label} />
          </ListItem>
        )}
        {this.renderQueue()}
        {this.renderAdminButtons()}
      </BaseDialog>
    )
  }

  private renderQueue = () => {
    const queuedRequests = mapObjectToArray(this.props.item.queue)
    if (!queuedRequests.length) return null
    return (
      <>
        <DialogContentText>Queue</DialogContentText>
        <ChipsContainer>
          {queuedRequests.map(q => {
            const user = this.props.usersById[q.userId]
            if (!user) return null
            return (
              <Chip
                key={user.id}
                label={user.displayName}
                avatar={<Avatar src={user.photoUrl || user.photoURL} />}
                onDelete={
                  this.props.user.isAdmin || user.id === this.props.user.id
                    ? () => this.props.unqueue(user.id, this.props.item)
                    : null
                }
              />
            )
          })}
        </ChipsContainer>
      </>
    )
  }

  private closeHistory = () => {
    this.setState({ ...this.state, isHistoryOpen: false })
  }

  private openHistory = () => {
    this.setState({ ...this.state, isHistoryOpen: true })
  }

  private renderAdminButtons = () => {
    if (!this.props.user.isAdmin) return null
    const item = this.props.item
    const borrower = item.borrowedBy && this.props.usersById[item.borrowedBy.userId]
    return (
      <div>
        {this.renderHistoryDialog()}
        {item.history && (
          <Button color="primary" onClick={this.openHistory}>
            Show history
          </Button>
        )}
        <Button color="primary" data-cy="item-preview-action-edit-item" onClick={this.props.editItem}>
          Edit item
        </Button>
        {!this.props.archivedItem && (
          <Button color="primary" onClick={() => this.props.duplicate(item)}>
            Duplicate
          </Button>
        )}
        {!this.props.archivedItem && !item.borrowedBy && (
          <ErrorButton color="secondary" onClick={this.openArchiveDialog}>
            Archive item
          </ErrorButton>
        )}
        {this.props.archivedItem && !item.borrowedBy && (
          <ErrorButton color="secondary" onClick={this.openArchiveDialog}>
            Unarchive item
          </ErrorButton>
        )}
        {this.state.isArchiveDialogOpen && (
          <ConfirmationDialog
            danger
            open
            title={this.props.archivedItem ? "Unarchive Item?" : "Archive Item?"}
            content={`Are you sure that you want to ${this.props.archivedItem ? "unarchive" : "archive"} ${item.name}`}
            onConfirm={() => {
              if (this.props.archivedItem) this.props.unarchiveItem(item)
              else this.props.archiveItem(item)
              this.props.close()
            }}
            onClose={this.closeArchiveDialog}
          />
        )}
        {!this.props.archivedItem && (
          <FormGroup row>
            {!borrower || item.available ? (
              <StyledFormGroup>
                <Autocomplete
                  label="Assign user"
                  data-cy="item-preview-assign-user"
                  suggestions={this.props.users}
                  suggestionsConfig={{
                    label: "displayName",
                    value: "id",
                  }}
                  limit={3}
                  onValueChanged={userId => {
                    this.setState({ userId })
                  }}
                />
                <DatePicker
                  label="Assignment date"
                  value={this.state.date}
                  onChange={(date: moment.Moment) => {
                    this.setState({
                      date: new Date(date.toString()),
                    })
                  }}
                />
                <Button
                  color="primary"
                  data-cy="item-preview-action-assign"
                  disabled={this.state.userId === null || this.state.date === null}
                  onClick={() => this.props.adminBorrowItem(this.state.userId, this.props.item, this.state.date)}>
                  Assign as borrower
                </Button>
              </StyledFormGroup>
            ) : (
              <StyledFormGroup>
                <DatePicker
                  label="Return date"
                  value={this.state.date}
                  onChange={(date: moment.Moment) => {
                    this.setState({
                      date: new Date(date.toString()),
                    })
                  }}
                />
                <Button
                  color="primary"
                  disabled={this.state.date === null}
                  onClick={_ => this.props.adminReturnItem(item, this.state.date)}>
                  {borrower ? borrower.displayName + " returned this" : "item was returned"}
                </Button>
              </StyledFormGroup>
            )}
            {item.files && !isEmpty(item.files) && (
              <StyledFormGroup>
                <StyledDialogText>Files attached to this item: </StyledDialogText>
                <ChipsContainer>
                  {mapObjectToArray(item.files)
                    .filter(file => this.props.user.isAdmin || file.type === "other")
                    .map(file => (
                      <Chip
                        key={file.name}
                        label={file.name}
                        onClick={async () => {
                          const tab = openTab()
                          const path = await this.props.getItemFilePath(file)
                          tab.location.href = path
                        }}
                      />
                    ))}
                </ChipsContainer>
              </StyledFormGroup>
            )}
          </FormGroup>
        )}
      </div>
    )
  }

  private renderHistoryDialog = () => {
    return (
      <BaseDialog
        open={this.state.isHistoryOpen}
        title={`${this.props.item.name} rent history`}
        actions={<Button onClick={this.closeHistory}>Close</Button>}>
        <List>
          {mapObjectToArray(this.props.item.history || {}, (_, history) => {
            const user = this.props.usersById[history.userId] || ({} as User)
            return (
              <ListItem key={history.id}>
                <ListItemAvatar>
                  <Avatar src={user.photoUrl || user.photoURL} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.displayName || history.userId}:`}
                  secondary={`${history.rentDate || "Unknown"} (accepted by ${history.borrowAcceptedBy ||
                    "unknown"}) → ${history.returnDate || "Unknown"} (returned to ${history.returnAcceptedBy ||
                    "unknown"})`}
                />
              </ListItem>
            )
          }).reverse()}
        </List>
      </BaseDialog>
    )
  }

  private getUserButtons = (archivedItem: boolean) => {
    let buttons = []
    const trusted = this.props.categoriesById[this.props.item.categoryId].trusted
    if (archivedItem) null
    else if (trusted) buttons = this.getTrustedCategoryButtons()
    else buttons = this.getUntrustedCategoryButtons()
    return buttons.concat([
      <Button key="button_close" data-cy="item-preview-action-close" onClick={this.props.close}>
        Close
      </Button>,
    ])
  }

  private openArchiveDialog = () => this.setState({ ...this.state, isArchiveDialogOpen: true })

  private closeArchiveDialog = () => this.setState({ ...this.state, isArchiveDialogOpen: false })

  private getTrustedCategoryButtons = () => {
    const { item } = this.props
    const req = this.props.requestsById[item.id]
    if (item.borrowedBy && item.borrowedBy.userId === this.props.user.id)
      return [
        <Button key="button_return" onClick={() => this.props.adminReturnItem(item)}>
          Return
        </Button>,
      ]
    if (item.available !== false && !req)
      return [
        <Button key="button_borrow" color="primary" onClick={() => this.props.requestBorrow(this.props.user.id, item)}>
          Borrow me
        </Button>,
      ]
    if (req && req.userId === this.props.user.id) {
      return [
        <Button key="button_cancel" color="primary" onClick={() => this.props.cancelRequestBorrow(item)}>
          Cancel request
        </Button>,
      ]
    }
    return this.getQueueButtons()
  }

  private getUntrustedCategoryButtons = () => {
    const { item } = this.props
    const req = this.props.requestsById[item.id]

    if (item.available !== false && !req)
      return [
        <Button
          key="button_borrow"
          color="primary"
          onClick={() => {
            this.props.requestBorrow(this.props.user.id, item)
            this.props.close()
          }}>
          Request borrow
        </Button>,
      ]
    if (req && req.userId === this.props.user.id) {
      return [
        <Button key="button_cancel" color="primary" onClick={() => this.props.cancelRequestBorrow(item)}>
          Cancel request
        </Button>,
      ]
    }
    if (item.borrowedBy && item.borrowedBy.userId === this.props.user.id)
      return [
        <Button
          key="button_return"
          color="primary"
          onClick={() => {
            this.props.requestReturn(this.props.user.id, item)
            this.props.close()
          }}>
          Request return
        </Button>,
      ]
    return this.getQueueButtons()
  }

  private getQueueButtons = () => {
    const { item } = this.props
    if (!item.queue || !item.queue[this.props.user.id])
      return [
        <Button key="button_queue" color="primary" onClick={() => this.props.queueMe(item)}>
          Queue
        </Button>,
      ]
    return []
  }
}

const mapStateToProps: MapState<StoreProps> = state => {
  const item = state.data.itemsById[state.app.selectedItem.id]
  return {
    item,
    user: state.auth,
    users: mapObjectToArray(state.data.usersById),
    usersById: state.data.usersById,
    categoriesById: state.data.categoriesById,
    requestsById: state.data.rentRequestsById,
  }
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    archiveItem: item => firebaseOperations.archiveItem(item),
    unarchiveItem: item => firebaseOperations.unarchiveItem(item),
    close: () => {
      dispatch(appActions.selectItem(null))
      dispatch(appActions.changeState(UIState.Other))
    },
    getItemFilePath: async file => {
      return await firebaseOperations.downloadFile(file)
    },
    editItem: () => dispatch(appActions.changeState(UIState.AddingItem)),
    duplicate: item => {
      dispatch(
        appActions.selectItem({
          ...item,
          id: null,
          borrowedBy: null,
          history: null,
          queue: null,
          available: true,
          buyDate: new Date().toString(),
        })
      )
      dispatch(appActions.changeState(UIState.AddingItem))
    },
    requestBorrow: (userId, item: Item) => firebaseOperations.requestBorrowItem(userId, item),
    cancelRequestBorrow: (item: Item) => firebaseOperations.cancelRequestBorrowItem(item.id),
    requestReturn: (userId, item: Item) => firebaseOperations.requestReturnItem(userId, item),
    queueMe: (item: Item) => firebaseOperations.queueItem(item),
    unqueue: (userId, item: Item) => firebaseOperations.unqueueItem(userId, item.id),
    adminReturnItem: (item: Item, date) => firebaseOperations.returnItem(item, date),
    adminBorrowItem: (userId, item: Item, date) => firebaseOperations.assignItemAsAdmin(userId, item, true, date),
  }
}

export const ItemPreview = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemPreviewComponent)
