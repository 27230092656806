import * as React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import * as moment from "moment"
import { DatePicker } from "material-ui-pickers"
import { Button, FormGroup, TextField, Select, MenuItem, InputLabel, FormControl, Chip } from "@material-ui/core"

import { mapObjectToArray, isEmpty } from "@/utils"
import { appActions, UIState } from "@/store/actions"
import * as firebaseOperations from "@/services/firebase/operations"
import { MapState, MapDispatch } from "@/store"
import { BaseDialog } from "@/components/BaseDialog"
import { FormGroupProps } from "@material-ui/core/FormGroup"
import { Autocomplete } from "@/components/Autocomplete"
import { Chips, ChipsContainer } from "@/components/Chips"
import { FileDialog } from "@/components/FileDialog"
import { TextFieldProps } from "@material-ui/core/TextField"

interface Props {
  selectedItem: Partial<Item>
}

interface StoreProps {
  tags: Tag[]
  tagsById: SMap<Tag>
  users: User[]
  categories: Category[]
}

interface Actions {
  addItem: (item: Item) => Promise<string>
  updateItem: (item: Item, filesToRemove?: ItemFile[]) => void
  close: () => void
  adminBorrowItem: (userId: string, item: Item) => void
}

interface State {
  userId: string
  uploading: boolean
  isFileDialogOpen: boolean
  filesToRemove: ItemFile[]
  editedItem: Item
}

const StyledFormGroup = styled(FormGroup)`
  & > * {
    margin-bottom: 2em !important;
  }
` as React.FC<FormGroupProps>

const StyledTextField = styled(TextField)`
  && {
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      label {
        font-size: 0.75rem;
      }
    }
  }
` as React.FC<TextFieldProps>

const priceRegex = /^\d*,?\d+$/

class ItemEditorComponent extends React.Component<Props & StoreProps & Actions, State> {
  state: State = {
    userId: "",
    uploading: false,
    isFileDialogOpen: false,
    filesToRemove: [],
    editedItem: { ...this.props.selectedItem } as Item,
  }
  render() {
    const item = this.state.editedItem
    const priceValidated = priceRegex.test(item.price)
    const dateValidated = item.buyDate
    const { uploading: itemIsUploading } = this.state
    return (
      <BaseDialog
        open
        title={item.id ? `Edit item ${item.name}` : "Add new Item"}
        actions={
          <>
            <Button
              data-cy="item-editor-action-add"
              disabled={
                !item.categoryId || !item.name || ((!priceValidated || !dateValidated) && !item.id) || itemIsUploading
              }
              color="primary"
              onClick={item.id ? this.update : this.add}>
              {item.id ? "Update" : "Add"}
            </Button>
            {item.id == null && (
              <Button
                disabled={
                  !item.categoryId || !item.name || ((!priceValidated || !dateValidated) && !item.id) || itemIsUploading
                }
                color="secondary"
                onClick={this.addAndNew}>
                Add and copy
              </Button>
            )}
            <Button disabled={itemIsUploading} onClick={this.props.close}>
              Cancel
            </Button>
          </>
        }>
        <StyledFormGroup>
          <TextField
            fullWidth
            label="Name"
            data-cy="item-editor-name"
            value={item.name || ""}
            error={!item.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.editItem({
                name: e.target.value,
              })
            }
          />
          <FormControl>
            <InputLabel error={!item.categoryId} htmlFor="category-select">
              Category
            </InputLabel>
            <Select
              value={item.categoryId || ""}
              inputProps={{ name: "any", id: "category-select" }}
              error={!item.categoryId}
              fullWidth
              data-cy="item-editor-category"
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                this.editItem({
                  categoryId: event.target.value,
                })
              }>
              {this.props.categories.map(c => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Chips
            items={mapObjectToArray(item.tags, id => this.props.tagsById[id] || { id, name: "" })}
            onDelete={id => {
              const ntags = { ...item.tags }
              delete ntags[id]
              this.editItem({
                tags: ntags,
              })
            }}
          />
          <Autocomplete
            label="Assign tag"
            data-cy="item-editor-tag"
            error={(!item.tags || isEmpty(item.tags)) && "Add tag"}
            suggestions={this.props.tags.filter(tag => {
              if (item.tags) {
                return !item.tags[tag.id]
              }
              return true
            })}
            suggestionsConfig={{
              label: "name",
              value: "id",
            }}
            limit={5}
            resetOnValueChanged
            onValueChanged={id => {
              this.editItem({
                tags: {
                  ...item.tags,
                  [id]: true,
                },
              })
            }}
          />
          <Button onClick={() => this.setState({ isFileDialogOpen: true })}>Upload file</Button>
          {item.files && !isEmpty(item.files) && (
            <FormGroup row>
              <ChipsContainer>
                {mapObjectToArray(item.files, (id, { type, file, name }) => {
                  return (
                    <Chip
                      key={id}
                      label={`${type}: ${name || file.name}`}
                      onDelete={() => {
                        const newFiles = { ...item.files }
                        if (name) {
                          // That means its already saved in cloud storage
                          this.setState({
                            filesToRemove: [...this.state.filesToRemove, { type, name }],
                          })
                        }
                        delete newFiles[id]
                        this.editItem({
                          files: newFiles,
                        })
                      }}
                    />
                  )
                })}
              </ChipsContainer>
            </FormGroup>
          )}
          <TextField
            fullWidth
            label="Description / Notes"
            data-cy="item-editor-description"
            value={item.description || ""}
            multiline
            rows={2}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.editItem({
                description: e.target.value,
              })
            }
          />
          <Autocomplete
            label="Assigned user"
            fullWidth
            suggestions={this.props.users}
            suggestionsConfig={{
              label: "displayName",
              value: "id",
            }}
            limit={3}
            onValueChanged={userId => {
              this.setState({ userId })
            }}
          />
          <TextField
            fullWidth
            label="Signature / Serial number"
            data-cy="item-editor-signature"
            value={item.signature || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.editItem({
                signature: e.target.value,
              })
            }
          />
          <DatePicker
            margin="normal"
            fullWidth
            data-cy="item-editor-buy-date"
            label={`Purchase date ${!dateValidated && !item.id ? "(required)" : ""}`}
            error={!dateValidated && !item.id}
            value={item.buyDate}
            onChange={(date: moment.Moment) => {
              this.editItem({
                buyDate: date.toString(),
              })
            }}
          />
          <StyledTextField
            fullWidth
            data-cy="item-editor-price"
            error={!priceValidated && !item.id}
            label={`Gross purchase price in PLN ${!priceValidated && !item.id ? "(required - eg. 123,45)" : ""}`}
            value={item.price || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.editItem({
                price: e.target.value,
              })
            }
          />
        </StyledFormGroup>
        <FileDialog
          open={this.state.isFileDialogOpen}
          onCancel={() => this.setState({ isFileDialogOpen: false })}
          onUpload={(file, type) =>
            this.editItem({
              files: { ...item.files, [new Date().getTime()]: { type, file } },
            })
          }
        />
      </BaseDialog>
    )
  }

  private editItem = (edit: Partial<Item>) => {
    this.setState({
      editedItem: {
        ...this.state.editedItem,
        ...edit,
      },
    })
  }

  private addItem = async () => {
    const { editedItem, userId } = this.state
    const { addItem, adminBorrowItem } = this.props
    const id = await addItem(editedItem)
    if (Boolean(userId)) {
      await adminBorrowItem(userId, { ...editedItem, id })
    }
  }

  private add = () => {
    this.addItem()
    this.props.close()
  }

  private update = () => {
    const { editedItem, userId } = this.state
    const { adminBorrowItem, updateItem } = this.props
    updateItem(this.state.editedItem, this.state.filesToRemove)
    if (Boolean(userId)) {
      adminBorrowItem(userId, { ...editedItem })
    }

    this.props.close()
  }

  private addAndNew = () => {
    this.setState({ uploading: true }, async () => {
      await this.addItem()
      const { editedItem } = this.state
      this.setState({
        userId: "",
        uploading: false,
        editedItem: { ...editedItem, signature: "" },
      })
    })
  }
}

const mapStateToProps: MapState<StoreProps> = state => ({
  tagsById: state.data.tagsById,
  tags: mapObjectToArray(state.data.tagsById),
  users: mapObjectToArray(state.data.usersById),
  categories: mapObjectToArray(state.data.categoriesById),
})

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    addItem: async item => await firebaseOperations.addItem(item),
    updateItem: async (item, filesToRemove) => {
      await firebaseOperations.updateItem(item)
      filesToRemove.forEach(({ type, name }) => {
        if (!name) return
        firebaseOperations.removeFile(type, name)
      })
    },
    close: () => {
      dispatch(appActions.selectItem(null))
      dispatch(appActions.changeState(UIState.Other))
    },
    adminBorrowItem: (userId, item) => firebaseOperations.assignItemAsAdmin(userId, item, true),
  }
}

export const ItemEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemEditorComponent)
