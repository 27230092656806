import * as React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { ConfirmationDialog } from "@/components/ConfirmationDialog"
import { appActions, UIState, dataActions } from "@/store/actions"
import * as firebaseOperations from "@/services/firebase/operations"
import { forEachInObject, mapObjectToArray, searchableString } from "@/utils"
import { MapState, MapDispatch } from "@/store"
import { ORGANIZATION } from "@/consts"
import { BaseDialog } from "@/components/BaseDialog"
import { Tabs, Tab, TextField, List, Avatar, ListItemAvatar, ListItemText, Button } from "@material-ui/core"
import { SelectMenu } from "@/components/Search"
import { TextFieldProps } from "@material-ui/core/TextField"
import { ListItemTextProps } from "@material-ui/core/ListItemText"
import { Autocomplete } from "@/components/Autocomplete"
import { StyledListItem } from "@/components/ListItems"

interface StoreProps {
  admins: User[]
  nonAdmins: User[]
  users: User[]
  usersById: SMap<User>
}

interface Actions {
  addAdmin: (user: User) => void
  removeAdmin: (user: User) => void
  addUser: (email: string, displayName: string) => Promise<User>
  addUserAction: (user: User) => void
  deleteUser: (user: User) => void
  close: () => void
}

interface State {
  tabState: number
  newEmail: string
  newDisplayName: string
  newAdmin: User
  newAdminValue: string
  error: string
  userFilterString: string
  adminFilterString: string
  deleteAction: any
  deleteTitle: string
}

const StyledTextField = styled(TextField)`
  margin: 0.5em 1em !important;
  width: calc(100% - 2em) !important;
` as React.FC<TextFieldProps>

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: rgba(150, 150, 150, 0.1);
  margin-top: 0.5em;
  padding: 0.5em 1em;
  flex-wrap: wrap;

  * {
    margin: 0 0.5em;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    & > div,
    & > button {
      padding: 10px 0;
    }
    & > div > * {
      margin: 0;
    }
  }
`

const StyledListItemText = styled(ListItemText)`
  & > span,
  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
` as React.FC<ListItemTextProps>

const UserItem = ({ user, deleteUser }) => {
  return (
    <StyledListItem key={user.id}>
      <ListItemAvatar>
        <Avatar src={user.photoURL} />
      </ListItemAvatar>
      <StyledListItemText primary={(user.isAdmin ? "[ADMIN] " : "") + user.displayName} secondary={user.email} />
      <SelectMenu
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        items={[{ id: "delete_user", name: "Delete user" }]}
        onItemClick={() => deleteUser(user)}
      />
    </StyledListItem>
  )
}

const AdminItem = ({ user, removeAdmin }) => {
  return (
    <StyledListItem key={user.id}>
      <ListItemAvatar>
        <Avatar src={user.photoURL} />
      </ListItemAvatar>
      <StyledListItemText primary={user.displayName} secondary={user.email} />
      <SelectMenu
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        items={[{ id: "delete_admin", name: "Remove permissions" }]}
        onItemClick={() => removeAdmin(user)}
      />
    </StyledListItem>
  )
}

class UserEditorComponent extends React.Component<StoreProps & Actions, State> {
  state = {
    tabState: 0,
    newEmail: "",
    newDisplayName: "",
    newAdmin: null,
    newAdminValue: "",
    error: "",
    userFilterString: "",
    adminFilterString: "",
    deleteAction: null,
    deleteTitle: null,
  }
  handleChange = (event: any) => {
    const newEmail = event.target.value
    this.setState({
      newEmail: newEmail.toLowerCase(),
      error: newEmail.match(ORGANIZATION) ? "" : "Invalid email",
    })
  }
  handleChangeNewDisplayName = (event: any) => {
    const newDisplayName = event.target.value
    this.setState({
      newDisplayName: newDisplayName,
    })
  }
  render() {
    return (
      <BaseDialog
        open
        fixedHeight="60vh"
        title="Manage users"
        actions={<Button onClick={this.props.close}>Close</Button>}>
        <Tabs
          variant="fullWidth"
          value={this.state.tabState}
          onChange={(_, value: number) => this.setState({ tabState: value })}>
          <Tab label="Users" />
          <Tab label="Admins" />
        </Tabs>
        {this.state.tabState === 0 && (
          <>
            <InputRow>
              <TextField
                label="New email address"
                value={this.state.newEmail}
                error={Boolean(this.state.error)}
                onChange={this.handleChange}
              />
              <TextField label="Name" value={this.state.newDisplayName} onChange={this.handleChangeNewDisplayName} />
              <Button
                color="primary"
                variant="contained"
                onClick={this.addUser}
                disabled={!!this.state.error || !this.state.newEmail}>
                Add new user
              </Button>
            </InputRow>
            <StyledTextField
              label="Search users"
              onChange={(e: any) => this.setState({ userFilterString: e.target.value })}
            />
            <List>
              {this.getFilteredUsers().map(user => (
                <UserItem key={user.id} user={user} deleteUser={this.deleteUser} />
              ))}
            </List>
          </>
        )}
        {this.state.tabState === 1 && (
          <>
            <InputRow>
              <Autocomplete
                label="Pick user"
                suggestions={this.props.nonAdmins}
                suggestionsConfig={{
                  label: "displayName",
                  value: "id",
                }}
                limit={3}
                controlled
                value={this.state.newAdminValue}
                allowSelfTyped
                onValueChanged={(id, value) => {
                  this.setState({ newAdmin: id && this.props.usersById[id], newAdminValue: value })
                }}
              />
              <Button variant="contained" color="primary" onClick={this.addAdmin} disabled={!this.state.newAdmin}>
                Add admin
              </Button>
            </InputRow>
            <StyledTextField
              label="Search admins"
              onChange={(e: any) => this.setState({ adminFilterString: e.target.value })}
            />
            <List>
              {this.getFilteredAdmins().map(admin => (
                <AdminItem key={admin.id} user={admin} removeAdmin={this.removeAdmin} />
              ))}
            </List>
          </>
        )}
        <ConfirmationDialog
          danger
          open={Boolean(this.state.deleteAction)}
          content={this.state.deleteTitle}
          onConfirm={this.state.deleteAction}
          onClose={this.closeDialog}
        />
      </BaseDialog>
    )
  }

  private getFilteredUsers = () => {
    return this.props.users.filter(
      x => searchableString(x.displayName).indexOf(searchableString(this.state.userFilterString)) !== -1
    )
  }

  private getFilteredAdmins = () => {
    return this.props.admins.filter(
      x => searchableString(x.displayName).indexOf(searchableString(this.state.adminFilterString)) !== -1
    )
  }

  private addUser = async () => {
    if (this.props.usersById[this.state.newEmail.replace(/\./g, "==")]) this.setState({ error: "User already exists" })
    else {
      const user = await this.props.addUser(this.state.newEmail, this.state.newDisplayName)
      this.props.addUserAction(user)
      this.setState({
        newEmail: "",
        newDisplayName: "",
      })
    }
  }

  private addAdmin = () => {
    this.props.addAdmin(this.state.newAdmin)
    this.setState({
      newAdmin: null,
      newAdminValue: "",
    })
  }

  private deleteUser = (user: User) => {
    this.setState({
      deleteTitle: `Are you sure you want delete user ${user.displayName} (${user.email})?`,
      deleteAction: () => this.props.deleteUser(user),
    })
  }

  private removeAdmin = (user: User) => {
    this.setState({
      deleteTitle: `Are you sure you want remove admin access for ${user.displayName}?`,
      deleteAction: () => this.props.removeAdmin(user),
    })
  }

  private closeDialog = () => {
    this.setState({
      deleteAction: null,
    })
  }
}

const mapStateToProps: MapState<StoreProps> = state => {
  const admins = []
  const nonAdmins = []
  const { usersById } = state.data
  forEachInObject(usersById, (_, user) => {
    if (user.isAdmin) {
      admins.push(user)
    } else {
      nonAdmins.push(user)
    }
  })
  return {
    admins,
    nonAdmins,
    users: mapObjectToArray(usersById),
    usersById,
  }
}

const mapDispatchToProps: MapDispatch<Actions> = dispatch => {
  return {
    addAdmin: (user: User) => firebaseOperations.addAdmin(user),
    removeAdmin: (user: User) => firebaseOperations.removeAdmin(user),
    addUser: (email: string, displayName: string) => firebaseOperations.addUser({ email, displayName }),
    addUserAction: (user: User) =>
      dispatch(dataActions.addSingleValue({ key: "usersById", value: { [user.id]: user }, id: user.id })),
    deleteUser: (user: User) => firebaseOperations.removeUser(user),
    close: () => dispatch(appActions.changeState(UIState.Other)),
  }
}

export const UserEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEditorComponent)
