import * as React from "react"
import styled from "styled-components"

const IconContainer = styled.svg`
  position: absolute;
  right: 30px;
`

export const Stroke = () => (
  <IconContainer width={13} height={10}>
    <path
      fill="#007CF7"
      fillRule="evenodd"
      d="M0 4.667l1.3-1.334 2.6 2S6.76 1.667 12.35 0L13 1.333S6.89 4.267 3.9 10L0 4.667z"
    />
  </IconContainer>
)
