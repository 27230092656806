import * as moment from "moment"

import { validate } from "./validators"
import { keys } from "@/utils"
import { store } from "@/store"
import { DATE_FORMAT } from "@/consts"

export const RentConfirmationValidatorMap: Casted<RentConfirmation, Validator> = {
  isConfirmedByUser: "boolean",
  isConfirmedByAdmin: "boolean",
  confirmationDate: "date",
  handedDate: "date",
  confirmationDocumentId: "text",
}

export const RentActionValidatorMap: Casted<RentAction, Validator> = {
  id: "text",
  itemId: "text",
  userId: "text",
  categoryId: "text",
  borrowAcceptedBy: "text",
  returnAcceptedBy: "text",
  rentDate: "date",
  expectedReturnDate: "date",
  returnDate: "date",
  confirmation: RentConfirmationValidatorMap,
}

const requiredKeys: Array<keyof RentAction> = ["itemId", "userId", "categoryId"]

export const rentActionKeys: Array<keyof RentAction> = keys(RentActionValidatorMap) as any

export const createRentAction = (valueToCheck: any): Maybe<RentAction> => {
  const criticalErrors = []
  const value: RentAction = rentActionKeys.reduce(
    (res, key) => {
      const { value: validatedValue, error } = validate<RentAction, typeof key>(
        RentActionValidatorMap[key],
        valueToCheck[key]
      )
      if (!error) {
        res[key] = validatedValue
      } else {
        if (requiredKeys.includes(key)) {
          criticalErrors.push(`A key ${key} is required, but validator returned error: ${error}`)
        }
      }
      return res
    },
    {} as RentAction
  )
  return criticalErrors.length ? { error: criticalErrors } : { value }
}

export const createRentConfirmation = (valueToCheck: any): Maybe<RentConfirmation> => {
  const value: RentConfirmation = keys(RentConfirmationValidatorMap).reduce(
    (res, key) => {
      const { value: validatedValue, error } = validate<RentConfirmation, typeof key>(
        RentConfirmationValidatorMap[key],
        valueToCheck[key]
      )
      if (!error) {
        res[key] = validatedValue
      }
      return res
    },
    {} as RentConfirmation
  )
  return { value }
}

const createRequest = (itemId: string, userId: string, categoryId: string, isReturn: boolean) => {
  const today = moment().format(DATE_FORMAT)
  const req: RentAction = {
    userId,
    itemId,
    categoryId,
    id: itemId, // legacy
  }
  if (isReturn) {
    req.returnDate = today
  } else {
    req.rentDate = today
  }
  return req
}

export const createRentRequest = (userId: string, item: Item) => createRequest(item.id, userId, item.categoryId, false)
export const createReturnRequest = (userId: string, item: Item) => createRequest(item.id, userId, item.categoryId, true)

export const getRequestForItem = (itemId: string) => {
  return store.getState().data.rentRequestsById[itemId]
}

export const getRequestedItem = (request: RentAction) => {
  return store.getState().data.itemsById[request.itemId]
}

export const mapRentRequestsToViews = (
  rentActions: RentAction[],
  usersById: SMap<User>,
  itemsById: SMap<Item>
): RentActionView[] => {
  return rentActions.reduce((current: RentActionView[], request: RentAction) => {
    const author = usersById[request.userId]
    if (author) {
      const item = itemsById[request.itemId]
      let label = `${author.displayName} wants to borrow ${item.name}`
      if (request.returnDate) label = `${author.displayName} wants to return ${item.name}`

      const subLabel = item.borrowedBy ? `Item is borrowed by ${usersById[item.borrowedBy.userId].displayName}` : ""
      return [
        ...current,
        {
          ...request,
          item,
          author,
          label,
          subLabel,
        },
      ]
    }
    return current
  }, [])
}

export const mapItemsToConfirmationViews = (items: Item[], usersById: SMap<User>): RentConfirmationView[] => {
  return items.reduce((current: RentConfirmationView[], item: Item) => {
    const assignment = item.borrowedBy
    const confirmation = assignment.confirmation
    const userItemHasBeenAssignedTo = usersById[assignment.userId]
    let label = ""
    let subLabel = ""
    if (!confirmation.isConfirmedByUser) {
      label = `${assignment.borrowAcceptedBy} assigned you ${item.name}`
      subLabel = "We need you to accept our terms before you can access that item"
    } else {
      label = `${userItemHasBeenAssignedTo.displayName} has accepted ${item.name}`
      subLabel = "When you hand it to the user, please confirm it here"
    }
    return [
      ...current,
      {
        ...confirmation,
        author: userItemHasBeenAssignedTo,
        item,
        label,
        subLabel,
      },
    ]
  }, [])
}
