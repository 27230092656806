import { RootState } from "@/store"
import { DateRange } from "@/store/actions"

export function isEmpty<T>(obj: T): boolean {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const keys = <T>(t: T): Array<keyof T> => Object.keys(t || {}) as any

export type Ext<TState = RootState> = (delta: Partial<TState>) => TState

export const extend = <TState>(state: TState): Ext<TState> => delta =>
  (({ ...(state as any), ...(delta as any) } as any) as TState)

export function toDateString(date: Date) {
  let m = (date.getMonth() + 1).toString()
  if (m.length === 1) m = "0" + m
  let d = date.getDate().toString()
  if (d.length === 1) d = "0" + d
  return date.getFullYear() + "-" + m + "-" + d
}

export function copyFrom(template: any, src: any) {
  const dest = { ...template }
  for (const key in template) {
    if (!template.hasOwnProperty(key)) continue
    if (src.hasOwnProperty(key)) dest[key] = src[key]
  }
  return dest
}

export function compareString(l: string, r: string) {
  if (l < r) return -1
  if (l > r) return 1
  return 0
}

export function mapObjectIntoQueryString(object: object) {
  const queryString = new URLSearchParams("")
  Object.keys(object).map(key => {
    const value = object[key]
    // we don't allow non-primitives
    if (value !== Object(value)) {
      queryString.append(key, value)
    }
  })

  return "?" + queryString
}

export function getDateFromString(stringDate: string): Date {
  return stringDate ? new Date(Date.parse(stringDate)) : null
}

export const normalizeEmail = (email: string): string => email.toLowerCase().replace(/\./g, "==")
export const denormalizeEmail = (normalizedEmail: string): string => normalizedEmail.replace(/\==/g, ".")

/**
 * Remove language accents and special letters
 */
export const normalizeString = (text: string): string => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ł/g, "l")
    .replace(/Ł/g, "L")
}

export const searchableString = (text: string) => {
  return normalizeString(text).toLowerCase()
}

export function forEachInObject<T>(object: SMap<T>, callback: (key: keyof SMap<T>, value: T) => any) {
  if (isObject(object)) {
    keys(object).forEach(key => {
      callback(key as string, object[key])
    })
  }
}

export function getFirstKeyOf(object: object): string | number {
  return keys(object)[0]
}

export function mapObjectToArray<T>(
  object: SMap<T>,
  callback: (key: keyof SMap<T>, value: T) => any = (_, value) => value as T
): any[] {
  if (isObject(object)) {
    return keys(object).map(key => callback(key as string, object[key]))
  }
  return [] // silent exception
}

export const dateInRange = (date: string, range: DateRange) => {
  const minDate = range.from ? new Date(range.from).getTime() : false
  const maxDate = range.to ? new Date(range.to).getTime() : false
  const givenDate = new Date(date).getTime()

  const isAfterMinDate = minDate ? givenDate >= minDate : true
  const isBeforeMinDate = maxDate ? givenDate <= maxDate : true
  return isAfterMinDate && isBeforeMinDate
}

export const mapField = <T>(name: keyof T, value: string | {}) => ({ target: { name, value } })
// guards
export const isObject = (value: any): value is object => typeof value === "object"
export const isString = (value: any): value is string => typeof value === "string"
export const isFile = (value: any): value is File => value instanceof File
export const isBlob = (value: any): value is Blob => value instanceof Blob
