import * as React from "react"
import styled from "styled-components"

interface Props {
  onClick?: () => void
}

const Image = styled.img`
  height: 40px;
  padding: 20px;
  cursor: pointer;
`

const logo = require("../assets/logo.png")

export const Logo: React.FC<Props> = ({ onClick = () => null }) => {
  return <Image onClick={onClick} src={logo} alt="ToolpooxStorage" />
}
