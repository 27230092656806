import styled from "styled-components"

export const AppBarTitle = styled.h1`
  && {
    position: absolute;
    left: 256px;
    top: 8px;
    opacity: 0.8;
    color: #f6f6f6;
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      left: 15px;
    }
  }
`
