import { createAction } from "@/utils/typedActions"
import { DataState } from "./reducers/data"

export enum UIState {
  AddingItem,
  AddingCategory,
  ManageTags,
  ManageFilters,
  PreviewingItem,
  ReviewingRequests,
  ReviewingItemRequests,
  ReviewingItemRequestsAdmin,
  ReviewingConfirmations,
  PreviewingExport,
  EditingUser,
  Other,
}

export enum SortBy {
  Available,
  NameASC,
  NameDESC,
  DateASC,
  DateDESC,
  Unavailable,
}

export const sortingKeys = {
  [SortBy.Available]: "Available first",
  [SortBy.NameASC]: "A-Z",
  [SortBy.NameDESC]: "Z-A",
  [SortBy.DateASC]: "Newest",
  [SortBy.DateDESC]: "Oldest",
}

export interface DateRange {
  from?: string
  to?: string
}

export interface FilterItemRequests {
  statuses?: string[]
  date: DateRange
}

export type DataPayload = { [key in keyof DataState]?: DataState[key] }
export type KeyValuePayload = {
  key: keyof DataState
  value?: DataState[keyof DataState]
  id?: string
}

export const appActions = {
  error: (payload: Error) => createAction("APP/error", payload),
  selectCategory: (payload: string) => createAction("APP/selectCategory", payload),
  selectItem: (payload: Item) => createAction("APP/selectItem", payload),
  selectTags: (payload: string[]) => createAction("APP/selectTags", payload),
  selectRequest: (payload: string) => createAction("APP/selectRequest", payload),
  sortBy: (payload: SortBy) => createAction("APP/sortBy", payload),
  filter: (payload: string) => createAction("APP/filter", payload),
  openSnackbar: (payload: boolean) => createAction("APP/snackbar", payload),
  filterByUserId: (payload: string) => createAction("APP/filterByUserId", payload),
  filterItemRequests: (payload: FilterItemRequests) => createAction("APP/filterItemRequests", payload),
  changeState: (payload: UIState) => createAction("APP/changeState", payload),
  dateRangeChanged: (payload: DateRange) => createAction("APP/dateRange", payload),
}

export type AppActions = ReturnType<typeof appActions[keyof typeof appActions]>

export const dataActions = {
  load: (payload: DataPayload) => createAction("DATA/load", payload),
  concat: (payload: { key: keyof DataState; value: DataState[keyof DataState] }) =>
    createAction("DATA/concat", payload),
  addSingleValue: (payload: KeyValuePayload) => createAction("DATA/add", payload),
  updateSingleValue: (payload: KeyValuePayload) => createAction("DATA/update", payload),
  removeSingleValue: (payload: KeyValuePayload) => createAction("DATA/remove", payload),
}

export type DataActions = ReturnType<typeof dataActions[keyof typeof dataActions]>

export const authActions = {
  authenticated: (payload: User) => createAction("AUTH/correct", payload),
  disauthenticated: () => createAction("AUTH/reset"),
}

export type AuthActions = ReturnType<typeof authActions[keyof typeof authActions]>
