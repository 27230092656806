import { createSelector } from "reselect"
import { Location } from "history"
import { DataState } from "../reducers/data"
import { AppState } from "../reducers/app"
import { RootState } from "../"

import { FilterItemRequests, SortBy, DateRange } from "@/store/actions"
import { mapObjectToArray, dateInRange, forEachInObject } from "@/utils"
import { userFixture } from "@/utils/fixtures"

export const filterByKeys = <T extends DataState | AppState>(state: T, keys: Array<keyof T>): Pick<T, keyof T> => {
  const filteredState = {} as Pick<T, keyof T>
  keys.forEach(key => (filteredState[key as string] = state[key]))
  return filteredState
}

export const itemRequestById = (state: RootState, id: string): ItemRequest => {
  return state.data.itemRequestsById[id]
}

export const getDataState = (state: RootState): DataState => {
  return state.data
}

export const getAppState = (state: RootState): AppState => {
  return state.app
}

export const getCurrentUser = (state: RootState): User => {
  return state.auth
}

export const isAdmin = createSelector(
  getCurrentUser,
  (currentUser: User): boolean => (currentUser ? currentUser.isAdmin : false)
)

export const getItemRequestAuthor = createSelector(
  itemRequestById,
  getDataState,
  (itemRequest: ItemRequest, state: DataState): User => {
    if (itemRequest) {
      return state.usersById[itemRequest.userId]
    }
  }
)

export const getItemRequestCaretaker = createSelector(
  itemRequestById,
  getDataState,
  (itemRequest: ItemRequest, state: DataState): User => {
    if (itemRequest) {
      return state.usersById[itemRequest.caretakerId]
    }
  }
)

export const getItemRequests = (state: RootState): ItemRequest[] => {
  return mapObjectToArray(state.data.itemRequestsById)
}

export const getCategories = (state: RootState): Category[] => {
  return mapObjectToArray(state.data.categoriesById)
}

const filterArchivedItems = (items: Item[]) => items.filter(({ archived }) => !archived)

export const getNewItemRequests = createSelector(
  getItemRequests,
  (itemRequests: ItemRequest[]): ItemRequest[] =>
    itemRequests.filter((itemRequest: ItemRequest) => itemRequest.status === "NEW")
)

export const getItemRequestsByUser = createSelector(
  getItemRequests,
  getCurrentUser,
  (itemRequests: ItemRequest[], currentUser: User): ItemRequest[] =>
    itemRequests.filter((itemRequest: ItemRequest) => itemRequest.userId === currentUser.id)
)

export const getFilteredCategories = createSelector(
  getCategories,
  getCurrentUser,
  (categories: Category[], currentUser: User): Category[] => {
    if (!currentUser.isAdmin) {
      categories = categories.filter(category => !category.hidden)
    }
    return categories
  }
)

export const getItemRequestFilters = createSelector(
  getAppState,
  (appState): FilterItemRequests => appState.filterItemRequests
)

export const getFilteredItemRequests = createSelector(
  getItemRequestFilters,
  getItemRequests,
  (itemRequestFilters: FilterItemRequests, itemRequests: ItemRequest[]): ItemRequest[] => {
    const { statuses, date: range } = itemRequestFilters
    return itemRequests.filter(itemRequest => {
      const isWithinStatuses = statuses.includes(itemRequest.status)
      const isWithinDateRange = range.from || range.to ? dateInRange(itemRequest.createDate, range) : true

      return isWithinStatuses && isWithinDateRange
    })
  }
)

export const getFilteredItemRequestsByUser = createSelector(
  getItemRequestFilters,
  getItemRequestsByUser,
  (itemRequestFilters: FilterItemRequests, itemRequests: ItemRequest[]): ItemRequest[] => {
    const { statuses, date: range } = itemRequestFilters
    return itemRequests.filter(itemRequest => {
      const isWithinStatuses = statuses.includes(itemRequest.status)
      const isWithinDateRange = range.from || range.to ? dateInRange(itemRequest.createDate, range) : true

      return isWithinStatuses && isWithinDateRange
    })
  }
)

export const getItemRequestForPreview = createSelector(
  getCurrentUser,
  getNewItemRequests,
  getItemRequestsByUser,
  (_, asAdmin) => asAdmin,
  (currentUser: User, newItemRequests: ItemRequest[], itemRequests: ItemRequest[], asAdmin: boolean): ItemRequest[] =>
    asAdmin && currentUser.isAdmin ? newItemRequests : itemRequests
)

export const getItems = createSelector(
  getDataState,
  (dataState): Item[] => mapObjectToArray(dataState.itemsById)
)

export const getCurrentItems = createSelector(
  getDataState,
  (dataState): Item[] => {
    const items = mapObjectToArray(dataState.itemsById)
    const filteredItems = filterArchivedItems(items)

    return filteredItems
  }
)

export const getItemNames = createSelector(
  getCurrentItems,
  items => items.map(({ name, id }) => ({ name, id }))
)

export const areItemsLoaded = createSelector(
  getItems,
  items => Boolean(items.length)
)

export const getCategoriesById = createSelector(
  getDataState,
  (dataState: DataState): SMap<Category> => dataState.categoriesById
)

export const getTagsById = createSelector(
  getDataState,
  (dataState: DataState): SMap<Tag> => dataState.tagsById
)

export const getUsersById = createSelector(
  getDataState,
  (dataState: DataState): SMap<User> => dataState.usersById
)

export const getRentRequestsById = createSelector(
  getDataState,
  (dataState: DataState): SMap<RentAction> => dataState.rentRequestsById
)

export const getBorrowedItemsWithRentConfirmations = createSelector(
  getItems,
  (items): Item[] => items.filter(item => item.borrowedBy && item.borrowedBy.confirmation)
)

export const getCurrentUserBorrowedItemsWithRentConfirmations = createSelector(
  getBorrowedItemsWithRentConfirmations,
  getCurrentUser,
  (items, currentUser): Item[] => items.filter(item => item.borrowedBy.userId === currentUser.id)
)

export const getCurrentUserRentConfirmationsNotifications = createSelector(
  getBorrowedItemsWithRentConfirmations,
  getCurrentUser,
  (items, currentUser): Item[] => {
    let itemsWithConfirmationNotifications: Item[] = []
    if (currentUser.isAdmin) {
      itemsWithConfirmationNotifications = items.filter(
        item => item.borrowedBy.confirmation.isConfirmedByUser && !item.borrowedBy.confirmation.isConfirmedByAdmin
      )
    }
    return itemsWithConfirmationNotifications.concat(
      items.filter(
        item => item.borrowedBy.userId === currentUser.id && item.borrowedBy.confirmation.isConfirmedByUser === false
      )
    )
  }
)

export const getFiltersById = createSelector(
  getDataState,
  (dataState: DataState): SMap<Filter> => dataState.filtersById
)

export const getSelectedCategoryId = createSelector(
  getAppState,
  (appState: AppState): string => appState.selectedCategoryId
)

export const getDateRange = createSelector(
  getAppState,
  (appState: AppState): DateRange => appState.dateRange
)

export const getItemsByDate = createSelector(
  getItems,
  getDateRange,
  (items: Item[], dateRange: DateRange): Item[] =>
    items.filter((item: Item) => (dateRange.from || dateRange.to ? dateInRange(item.buyDate, dateRange) : true))
)

export const getSnackbarOpen = createSelector(
  getCurrentUserRentConfirmationsNotifications,
  getCurrentUser,
  getAppState,
  (notifications, currentUser, appState) =>
    Boolean(appState.snackbarOpen && !currentUser.isAdmin && notifications.length)
)

export const getUrlParams = (state: RootState) => {
  return state.router.location.pathname.split("/")[2]
}

export const getArchivedPagePathnameStatus = (location: Location) => location.pathname === "/archived-items"
