import { validate } from "./validators"
import { keys } from "@/utils"

export const FilterValidatorMap: Casted<Filter, Validator> = {
  id: "text",
  name: "text",
  printName: "text",
  tags: {
    "*": "boolean",
  },
}

const requiredKeys: Array<keyof Filter> = ["name", "printName", "tags"]

export const filterKeys: Array<keyof Filter> = keys(FilterValidatorMap) as any

export const createFilter = (valueToCheck: any): Maybe<Filter> => {
  const criticalErrors = []

  const value: Filter = filterKeys.reduce(
    (res, key) => {
      const { value: validatedValue, error } = validate<Filter, typeof key>(FilterValidatorMap[key], valueToCheck[key])
      if (!error) {
        res[key] = validatedValue
      } else {
        if (requiredKeys.includes(key)) {
          criticalErrors.push(`A key ${key} is required, but validator returned error: ${error}`)
        }
      }
      return res
    },
    {} as Filter
  )
  return criticalErrors.length ? { error: criticalErrors } : { value }
}
